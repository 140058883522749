import { Avatar, Box, Group, MediaQuery, Menu, Text, UnstyledButton } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { IconChevronDown, IconLogout, IconTools } from '@tabler/icons-react';
import { forwardRef } from 'react';
import { useLogout } from '../../../../api/sessions.queries';
import { Link } from 'react-router-dom';

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  image: string;
  userId?: string;
  name: string;
  email?: string;
  isGuest?: boolean;
}

const ProfileButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, userId, isGuest, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      data-flood-test-id="profileBtn"
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        color: theme.black,
      })}
      {...others}
    >
      <Group position="right">
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <Avatar src={image} radius="lg" size={30} mt={-3} />
        </MediaQuery>
        <Box>
          <Text size="md" weight={700} ml={-10} mb={0}>
            {name}
          </Text>
        </Box>
        <IconChevronDown size={16} />
      </Group>
    </UnstyledButton>
  )
);

ProfileButton.displayName = 'ProfileButton';

export function UserProfile({ image, userId, name, email, isGuest }: UserButtonProps) {
  const logout = useLogout();

  const handleLogout = async () => {
    const { redirect } = await logout.mutateAsync();
    Sentry.setUser({ ip_address: '{{auto}}' });
    (window as any).location = redirect ?? '/';
  };

  return (
    <Group position="right">
      <Menu width={200} shadow="md" position="bottom-end" withinPortal>
        <Menu.Target>
          <ProfileButton
            image={image}
            userId={userId}
            name={name}
            email={email}
            isGuest={isGuest}
          />
        </Menu.Target>
        {
          <Menu.Dropdown>
            <Link to="/account-management" style={{ textDecoration: 'none' }}>
              <Menu.Item icon={<IconTools size="1.1rem" stroke={1.5} />}>Manage Account</Menu.Item>
            </Link>
            <Menu.Divider />
            <Menu.Item
              onClick={handleLogout}
              data-flood-test-id="logoutBtn"
              icon={<IconLogout size="1.1rem" stroke={1.5} />}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        }
      </Menu>
    </Group>
  );
}
