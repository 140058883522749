import { ReactNode } from 'react';
import { useGroupsByEventIdQuery } from '../../../../api/group.queries';
import {
  useGroupMembersByGroupIdQuery,
  useGroupMembershipsQuery,
} from '../../../../api/groupMember.queries';
import { useAuthContext } from '../../../../hooks/useAuth';
import { useIsFacilitator } from '../../../../hooks/useIsFacilitator';
import { useRequiredParam } from '../../../../hooks/useRequiredParam';
import { ProtectedGroupPage } from '../../../events/components/groups/ProtectedGroupPage';
import { OverlayLoader } from '../../../../components/loaders/overlay-loader/OverlayLoader';
import { Navigate } from 'react-router-dom';
import { linkToEvent } from '../../../../utils/event-urls';

interface RequireGroupMemberProps {
  children: ReactNode;
}

// ensure that the user can only see children components
// if they are a facilitator OR a group member
// joins the user to this group if they have no other group memberships
export function RequireGroupMemberOrFacilitator({
  children,
}: RequireGroupMemberProps): JSX.Element | null {
  const eventId = useRequiredParam('eventId');
  const groupId = useRequiredParam('groupId');
  const { user } = useAuthContext();

  const { data: groupMembers, isFetched: groupMembersFetched } =
    useGroupMembersByGroupIdQuery(groupId);

  const { data: eventGroups } = useGroupsByEventIdQuery(eventId);

  const groupMemberships = useGroupMembershipsQuery(eventGroups?.map((t) => t.id));

  const { isFacilitator, isFetched: isFacilitatorFetched } = useIsFacilitator(eventId);
  const isMemberHere: boolean = groupMembers?.some((m) => m.userId === user?.id) || false;

  const allowGroup: boolean = isMemberHere || isFacilitator;

  // always return children if the guard conditions are met
  // so the component is not unmounting on re-render / refetch
  if (allowGroup) {
    return <>{children}</>;
  }

  // cases below are for allowGroup==false (loading state or prohibited page)
  // only if all queries are settled and no mutation is running, show protected page
  if (groupMembersFetched && groupMemberships.allFetched && eventGroups && isFacilitatorFetched) {
    // if the user has no group membership, redirect to event page
    if (groupMemberships.memberGroupId === undefined) {
      return <Navigate to={linkToEvent(eventId)}></Navigate>;
    } else {
      // if they are a member of another group, show the prohibited page
      return (
        <ProtectedGroupPage
          eventId={eventId}
          groups={eventGroups}
          groupMemberships={groupMemberships}
        />
      );
    }
  }

  // while settling, show loader
  return <OverlayLoader title="Loading Group"></OverlayLoader>;
}
