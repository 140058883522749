import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ItemTypeApi, StrategicThemeApi, UsersApi, ValueStreamApi } from '../api/api';
import { useCreateBidMutation } from '../api/bid.queries';
import { useCreateBiddingItemMutation } from '../api/bidding-item.queries';
import { useCreateEventMutation, useDeleteEventMutation } from '../api/event.queries';
import { useAddEventMemberMutation } from '../api/eventMember.queries';
import { useCreateGroupMutation } from '../api/group.queries';
import { useAddGroupMemberMutation } from '../api/groupMember.queries';
import { useCreateItemTypeMutation } from '../api/itemType.queries';
import { useCreateStrategicThemeMutation } from '../api/strategicTheme.queries';
import { useCreateValueStreamMutation } from '../api/valueStream.queries';
import { DateFormat, formatDate } from '../components/layout/components/i18n/FormattedDate';
import {
  DefaultItemTypes,
  homeRenoMetaData,
  terrificTransportCorp,
  homeRenovation,
  ttcMetaData,
} from '../constants/demoEvent';
import { PostBid } from '../models/bid.interface';
import { PostBiddingItem } from '../models/bidding-item.interface';
import { PostGroup } from '../models/group.interface';
import { PostItemType } from '../models/itemType.interface';
import { PostStrategicTheme } from '../models/strategicTheme.interface';
import { PostValueStream } from '../models/valueStream.interface';
import { linkToEvents, linkToManageDetails } from '../utils/event-urls';
import { useAuthContext } from './useAuth';
import { useSharedStateContext } from './useSharedState';
import { useCallback } from 'react';

import { modals, openConfirmModal } from '@mantine/modals';

export function useDefaultEvent() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const createEvent = useCreateEventMutation();
  const createGroup = useCreateGroupMutation();
  const createItemType = useCreateItemTypeMutation();
  const handleCreateError = useHandleCreateEventError();

  const defaultSettings = {
    name: 'Funding Event - ' + formatDate({ date: new Date(), dateFormat: DateFormat.DateAndTime }),
    currencyCode: 'EUR',
    isCustomCurrency: false,
    fundingCycle: 'Funding Cycle',
    budget: 100,
    isDemo: false,
    isSample: false,
    allowUrlJoin: false,
    allowBidding: true,
    description: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: 'Welcome!',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Your bidding input on the items in this event can factor into funding and/or prioritization decisions. ',
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Please review any documents or information shared with you in preparation for this activity.',
              type: 'text',
            },
          ],
        },
      ],
    },
  };

  const createDynamicValues = async (eventId: string) => {
    const itemTypes: PostItemType[] = Object.entries(DefaultItemTypes).map(([key, value]) => ({
      value,
    }));

    await Promise.all([
      createGroup.mutateAsync({ eventId: eventId, name: 'Table 1' }),
      ...itemTypes.map((itemType) => createItemType.mutateAsync({ eventId, itemType })),
    ]);
  };

  return async () => {
    if (!user?.id) {
      throw new Error('createEvent failed, user is not defined');
    }
    let maybeEventId: string | undefined = undefined;
    try {
      const createdEvent = await createEvent.mutateAsync({
        ...defaultSettings,
      });
      maybeEventId = createdEvent.id;
      const createdEventId = createdEvent.id;
      await createDynamicValues(createdEventId);
      navigate('/events/' + createdEventId + '/details');
    } catch (error: unknown) {
      handleCreateError('custom', maybeEventId, error);
    }
  };
}

/**
 * hook class to seed demo data for a new user to get started.
 * This will eventually be deleted once we have a better onboarding experience
 */
export function useTTCDemoData() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const createEvent = useCreateEventMutation();
  const createItemType = useCreateItemTypeMutation();
  const createValueStream = useCreateValueStreamMutation();
  const createStrategicTheme = useCreateStrategicThemeMutation();
  const createGroup = useCreateGroupMutation();
  const createBudgetItem = useCreateBiddingItemMutation();

  const { setEventLoading } = useSharedStateContext();
  const handleCreateError = useHandleCreateEventError();

  const createDynamicValues = async (eventId: string) => {
    const itemTypes: PostItemType[] = Object.entries(ttcMetaData.itemTypes).map(([key, value]) => ({
      value,
    }));
    const valueStreams: PostValueStream[] = Object.entries(ttcMetaData.valueStreams).map(
      ([key, value]) => ({
        value,
      })
    );
    const strategicThemes: PostStrategicTheme[] = Object.entries(ttcMetaData.strategicThemes).map(
      ([key, value]) => ({
        value,
      })
    );

    await Promise.all([
      ...itemTypes.map((itemType) => createItemType.mutateAsync({ eventId, itemType })),
      ...valueStreams.map((valueStream) => createValueStream.mutateAsync({ eventId, valueStream })),
      ...strategicThemes.map((strategicTheme) =>
        createStrategicTheme.mutateAsync({ eventId, strategicTheme })
      ),
    ]);
  };

  return async () => {
    if (user?.id) {
      setEventLoading(true);

      let maybeEventId: string | undefined = undefined;

      try {
        const createdEvent = await createEvent.mutateAsync({
          ...terrificTransportCorp.settings,
          isDemo: true,
        });

        maybeEventId = createdEvent.id;
        const createdEventId = createdEvent.id;

        // create itemType, valueStream, strategicTheme
        await createDynamicValues(createdEventId);
        const eventItemTypes = await ItemTypeApi.getAll(createdEventId);
        const eventValueStreams = await ValueStreamApi.getAll(createdEventId);
        const eventStrategicThemes = await StrategicThemeApi.getAll(createdEventId);

        const groupsToCreate: PostGroup[] = [
          ...terrificTransportCorp.groups.map((group) => ({
            ...group,
            eventId: createdEventId,
          })),
        ];

        const biddingItemsToCreate: PostBiddingItem[] = terrificTransportCorp.items.map((item) => {
          // fill the data with actual IDs that belong to the event
          const mergedItem = {
            ...item,
            eventId: createdEventId,
            itemTypeId: eventItemTypes.find((v) => v.value === item.itemType)?.id || '',
            valueStreamIds: eventValueStreams
              .filter((vs) => item.valueStreams.some((name) => vs.value === name))
              .map((vs) => vs.id),
            strategicThemeIds: eventStrategicThemes
              .filter((st) => item.strategicThemes.some((name) => st.value === name))
              .map((st) => st.id),
          };

          // remove all "helper" fields from the object and only return with the post item fields
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { itemType, valueStreams, strategicThemes, ...rest } = mergedItem;
          return rest;
        });

        await Promise.all([
          ...groupsToCreate.map((group) => createGroup.mutateAsync(group)),
          ...biddingItemsToCreate.map((item) => createBudgetItem.mutateAsync(item)),
        ]);

        navigate(linkToManageDetails(createdEventId));
        setEventLoading(false);
      } catch (error: unknown) {
        setEventLoading(false);
        handleCreateError('demo', maybeEventId, error);
      }
    }
  };
}

/**
 * hook class to seed demo data for a new user to get started.
 * This will eventually be deleted once we have a better onboarding experience
 */
export function useRenoDemoData() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const createEvent = useCreateEventMutation();
  const createItemType = useCreateItemTypeMutation();
  const createValueStream = useCreateValueStreamMutation();
  const createStrategicTheme = useCreateStrategicThemeMutation();
  const createGroup = useCreateGroupMutation();
  const createBudgetItem = useCreateBiddingItemMutation();

  const { setEventLoading } = useSharedStateContext();
  const handleCreateError = useHandleCreateEventError();

  const createDynamicValues = async (eventId: string) => {
    const itemTypes: PostItemType[] = Object.entries(homeRenoMetaData.itemTypes).map(
      ([key, value]) => ({
        value,
      })
    );
    const valueStreams: PostValueStream[] = Object.entries(homeRenoMetaData.valueStreams).map(
      ([key, value]) => ({
        value,
      })
    );
    const strategicThemes: PostStrategicTheme[] = Object.entries(
      homeRenoMetaData.strategicThemes
    ).map(([key, value]) => ({
      value,
    }));

    await Promise.all([
      ...itemTypes.map((itemType) => createItemType.mutateAsync({ eventId, itemType })),
      ...valueStreams.map((valueStream) => createValueStream.mutateAsync({ eventId, valueStream })),
      ...strategicThemes.map((strategicTheme) =>
        createStrategicTheme.mutateAsync({ eventId, strategicTheme })
      ),
    ]);
  };

  return async () => {
    if (user?.id) {
      setEventLoading(true);

      let maybeEventId: string | undefined = undefined;

      try {
        const createdEvent = await createEvent.mutateAsync({
          ...homeRenovation.settings,
          isDemo: true,
        });

        maybeEventId = createdEvent.id;
        const createdEventId = createdEvent.id;

        // create itemType, valueStream, strategicTheme
        await createDynamicValues(createdEventId);
        const eventItemTypes = await ItemTypeApi.getAll(createdEventId);
        const eventValueStreams = await ValueStreamApi.getAll(createdEventId);
        const eventStrategicThemes = await StrategicThemeApi.getAll(createdEventId);

        const groupsToCreate: PostGroup[] = [
          ...homeRenovation.groups.map((group) => ({
            ...group,
            eventId: createdEventId,
          })),
        ];

        const biddingItemsToCreate: PostBiddingItem[] = homeRenovation.items.map((item) => {
          // fill the data with actual IDs that belong to the event
          const mergedItem = {
            ...item,
            eventId: createdEventId,
            itemTypeId: eventItemTypes.find((v) => v.value === item.itemType)?.id || '',
            valueStreamIds: eventValueStreams
              .filter((vs) => item.valueStreams.some((name) => vs.value === name))
              .map((vs) => vs.id),
            strategicThemeIds: eventStrategicThemes
              .filter((st) => item.strategicThemes.some((name) => st.value === name))
              .map((st) => st.id),
          };

          // remove all "helper" fields from the object and only return with the post item fields
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { itemType, valueStreams, strategicThemes, ...rest } = mergedItem;
          return rest;
        });

        await Promise.all([
          ...groupsToCreate.map((group) => createGroup.mutateAsync(group)),
          ...biddingItemsToCreate.map((item) => createBudgetItem.mutateAsync(item)),
        ]);

        navigate(linkToManageDetails(createdEventId));
        setEventLoading(false);
      } catch (error: unknown) {
        setEventLoading(false);
        handleCreateError('demo', maybeEventId, error);
      }
    }
  };
}

/**
 * hook class to seed sample data for a user to view what a event will look like as a facilitator.
 * This will eventually be deleted once we have a better onboarding experience
 *
 * A sample event is defined as:
 * Event Budget: €6,000,000
 * 2 Tables, Table 1 & Table 2
 * 5 Users:
     - Fatima Khan : Table 2
     - Antoine Lambert: Table 2
     - Kaya Nkosi: Table 2
     - Arjun Singh: Table 1
     - Maria Rodriguez: Table 1
 */
export function useSampleData() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const createEvent = useCreateEventMutation();
  const createItemType = useCreateItemTypeMutation();
  const createValueStream = useCreateValueStreamMutation();
  const createStrategicTheme = useCreateStrategicThemeMutation();
  const createGroup = useCreateGroupMutation();
  const createBudgetItem = useCreateBiddingItemMutation();
  const addEventMember = useAddEventMemberMutation();
  const addGroupMember = useAddGroupMemberMutation();
  const createBid = useCreateBidMutation();
  const queryClient = useQueryClient();
  const { setEventLoading } = useSharedStateContext();

  const handleCreateError = useHandleCreateEventError();

  const createDynamicValues = useCallback(
    async (eventId: string) => {
      const itemTypes: PostItemType[] = Object.entries(ttcMetaData.itemTypes).map(
        ([key, value]) => ({
          value,
        })
      );
      const valueStreams: PostValueStream[] = Object.entries(ttcMetaData.valueStreams).map(
        ([key, value]) => ({
          value,
        })
      );
      const strategicThemes: PostStrategicTheme[] = Object.entries(ttcMetaData.strategicThemes).map(
        ([key, value]) => ({
          value,
        })
      );

      await Promise.all([
        ...itemTypes.map((itemType) => createItemType.mutateAsync({ eventId, itemType })),
        ...valueStreams.map((valueStream) =>
          createValueStream.mutateAsync({ eventId, valueStream })
        ),
        ...strategicThemes.map((strategicTheme) =>
          createStrategicTheme.mutateAsync({ eventId, strategicTheme })
        ),
      ]);
    },
    [createItemType, createStrategicTheme, createValueStream]
  );

  return useCallback(async () => {
    if (user?.id) {
      setEventLoading(true);
      let maybeEventId: string | undefined = undefined;

      try {
        const createdEvent = await createEvent.mutateAsync({
          ...terrificTransportCorp.settings,
          name: ' Sample Event with Bids',
          isSample: true,
        });

        maybeEventId = createdEvent.id;
        const createdEventId = createdEvent.id;

        // create itemType, valueStream, strategicTheme
        await createDynamicValues(createdEventId);

        const eventItemTypes = await ItemTypeApi.getAll(createdEventId);
        const eventValueStreams = await ValueStreamApi.getAll(createdEventId);
        const eventStrategicThemes = await StrategicThemeApi.getAll(createdEventId);

        const groupsToCreate: PostGroup[] = [
          ...terrificTransportCorp.groups.map((group) => ({
            ...group,
            eventId: createdEventId,
          })),
        ];

        const biddingItemsToCreate: PostBiddingItem[] = terrificTransportCorp.items.map((item) => {
          // fill the data with actual IDs that belong to the event
          const mergedItem = {
            ...item,
            eventId: createdEventId,
            itemTypeId: eventItemTypes.find((v) => v.value === item.itemType)?.id || '',
            valueStreamIds: eventValueStreams
              .filter((vs) => item.valueStreams.some((name) => vs.value === name))
              .map((vs) => vs.id),
            strategicThemeIds: eventStrategicThemes
              .filter((st) => item.strategicThemes.some((name) => st.value === name))
              .map((st) => st.id),
          };

          // remove all "helper" fields from the object and only return the postItem
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { itemType, valueStreams, strategicThemes, ...postItem } = mergedItem;

          return postItem;
        });

        const groups = await Promise.all(
          groupsToCreate.map((group) => createGroup.mutateAsync(group))
        );

        const table1 = groups.find((g) => g.name === 'Table 1');
        const table2 = groups.find((g) => g.name === 'Table 2');

        if (!table1 || !table2) {
          console.error('Some sample groups are not defined', groups);
          throw new Error('Creating sample event failed- Some sample groups are not defined');
        }

        const biddingItems = await Promise.all(
          biddingItemsToCreate.map((item) => createBudgetItem.mutateAsync(item))
        );

        // Fetch the global sample users (created by migration)
        const sampleUsers = await UsersApi.getSampleUsers();

        const arjunSingh = sampleUsers.find((u) => u.userName === 'Arjun Singh'); // Table 1
        const mariaRodriguez = sampleUsers.find((u) => u.userName === 'Maria Rodriguez'); // Table 1

        const fatimaKhan = sampleUsers.find((u) => u.userName === 'Fatima Khan'); // Table 2
        const antoineLambert = sampleUsers.find((u) => u.userName === 'Antoine Lambert'); // Table 2
        const kayaNkosi = sampleUsers.find((u) => u.userName === 'Kaya Nkosi'); // Table 2

        if (!fatimaKhan || !antoineLambert || !mariaRodriguez || !kayaNkosi || !arjunSingh) {
          console.error('Some sample users are not defined', sampleUsers);
          throw new Error('Creating sample event failed- Some sample users are not defined');
        }

        const table1Users = [arjunSingh, mariaRodriguez];
        const table2Users = [fatimaKhan, antoineLambert, kayaNkosi];

        const eventMembers = await Promise.all(
          sampleUsers.map((u) =>
            addEventMember.mutateAsync({
              eventId: createdEventId,
              eventMember: { userId: u?.id as string },
            })
          )
        );

        const table1Members = await Promise.all(
          table1Users.map((user) =>
            addGroupMember.mutateAsync({
              groupId: table1.id,
              groupMember: {
                userId: user.id,
              },
            })
          )
        );

        const table2Members = await Promise.all(
          table2Users.map((user) =>
            addGroupMember.mutateAsync({
              groupId: table2.id,
              groupMember: {
                userId: user.id,
              },
            })
          )
        );

        const allGroupMembers = [...table1Members, ...table2Members];

        // Place bids as specified in
        // https://docs.google.com/spreadsheets/d/1kR-DAmmT37fGPpl5gTC89LlZ4gZlCdGTwuPlzUJmQwI/edit#gid=0
        /*
         search bidding item by name
         place bids by user / table / amount
  
         => create structure with bids and iterate over it
        */
        interface SampleBid {
          item: string;
          userId: string;
          amount: number;
        }

        const sampleBids: SampleBid[] = [
          {
            item: 'Extend to more languages',
            userId: fatimaKhan.id,
            amount: 25000,
          },
          {
            item: 'Extend to more languages',
            userId: antoineLambert.id,
            amount: 40000,
          },
          {
            item: 'Extend to more languages',
            userId: mariaRodriguez.id,
            amount: 25000,
          },
          {
            item: 'Extend to more languages',
            userId: kayaNkosi.id,
            amount: 35000,
          },
          {
            item: 'Extend to more languages',
            userId: arjunSingh.id,
            amount: 75000,
          },
          {
            item: 'Gamification for deliveries',
            userId: fatimaKhan.id,
            amount: 125000,
          },
          {
            item: 'Gamification for deliveries',
            userId: kayaNkosi.id,
            amount: 200000,
          },
          {
            item: 'Gamification for deliveries',
            userId: arjunSingh.id,
            amount: 200000,
          },
          {
            item: 'Drone delivery platform',
            userId: fatimaKhan.id,
            amount: 490000,
          },
          {
            item: 'Drone delivery platform',
            userId: mariaRodriguez.id,
            amount: 450000,
          },
          {
            item: 'Drone delivery platform',
            userId: arjunSingh.id,
            amount: 450000,
          },
          {
            item: 'Support Apple Pay',
            userId: mariaRodriguez.id,
            amount: 50000,
          },
          {
            item: 'Local Farm delivery of perishable goods',
            userId: fatimaKhan.id,
            amount: 10000,
          },
          {
            item: 'Local Farm delivery of perishable goods',
            userId: antoineLambert.id,
            amount: 50000,
          },
          {
            item: 'Local Farm delivery of perishable goods',
            userId: mariaRodriguez.id,
            amount: 25000,
          },
          {
            item: 'Local Farm delivery of perishable goods',
            userId: arjunSingh.id,
            amount: 40000,
          },
          {
            item: 'Autonomous Commercial Delivery via vans',
            userId: fatimaKhan.id,
            amount: 600000,
          },
          {
            item: 'Autonomous Commercial Delivery via vans',
            userId: antoineLambert.id,
            amount: 700000,
          },
          {
            item: 'Autonomous Commercial Delivery via vans',
            userId: mariaRodriguez.id,
            amount: 1000000,
          },
          {
            item: 'Autonomous Commercial Delivery via vans',
            userId: kayaNkosi.id,
            amount: 665000,
          },
          {
            item: 'Autonomous Commercial Delivery via vans',
            userId: arjunSingh.id,
            amount: 1000000,
          },
          {
            item: 'Delivery platform partnership',
            userId: mariaRodriguez.id,
            amount: 350000,
          },
          {
            item: 'Delivery platform partnership',
            userId: arjunSingh.id,
            amount: 350000,
          },
          {
            item: 'Delivery platform development',
            userId: mariaRodriguez.id,
            amount: 350000,
          },
          {
            item: 'Delivery platform development',
            userId: kayaNkosi.id,
            amount: 300000,
          },
          {
            item: 'Delivery platform development',
            userId: arjunSingh.id,
            amount: 200000,
          },
          {
            item: 'Fleet management delivery platform integration',
            userId: antoineLambert.id,
            amount: 200000,
          },
          {
            item: 'Fleet management delivery platform integration',
            userId: arjunSingh.id,
            amount: 200000,
          },
          {
            item: 'Continuous Delivery Pipeline',
            userId: antoineLambert.id,
            amount: 300000,
          },
          {
            item: 'Upgrade sensors',
            userId: kayaNkosi.id,
            amount: 500000,
          },
          {
            item: 'Enhance navigation',
            userId: mariaRodriguez.id,
            amount: 300000,
          },
          {
            item: 'Edge Detection capability',
            userId: fatimaKhan.id,
            amount: 250000,
          },
          {
            item: 'Edge Detection capability',
            userId: antoineLambert.id,
            amount: 250000,
          },
          {
            item: 'Edge Detection capability',
            userId: kayaNkosi.id,
            amount: 300000,
          },
          {
            item: 'Autonomous Public Transport via Buses',
            userId: fatimaKhan.id,
            amount: 500000,
          },
          {
            item: 'Autonomous Public Transport via Buses',
            userId: mariaRodriguez.id,
            amount: 350000,
          },
          {
            item: 'Autonomous Public Transport via Buses',
            userId: arjunSingh.id,
            amount: 235000,
          },
        ];

        const userBidsToCreate: PostBid[] = [];
        for (const sampleBid of sampleBids) {
          const item = biddingItems.find(
            (i) => i.name.trim().toLowerCase() === sampleBid.item.trim().toLowerCase()
          );
          const groupMember = allGroupMembers.find((m) => m.userId === sampleBid.userId);
          if (item && groupMember) {
            userBidsToCreate.push({
              bidAmount: sampleBid.amount,
              biddingItemId: item.id,
              groupId: groupMember.groupId,
              userId: sampleBid.userId, // override the userId for sample bids
            });
          } else {
            if (!item) {
              console.error(`Sample Item '${sampleBid.item}' not found`);
            }
            if (!groupMember) {
              console.error(`Group Membership for user ${sampleBid.userId} not found`);
            }
          }
        }

        await Promise.all(userBidsToCreate.map((bid) => createBid.mutateAsync(bid)));
        // invalidate queries to fetch users already being added to event
        queryClient.invalidateQueries();

        navigate(`/events/${createdEventId}?sampleEvent=true`);
        setEventLoading(false);
      } catch (error: unknown) {
        setEventLoading(false);
        handleCreateError('sample', maybeEventId, error);
      }
    }
  }, [
    addEventMember,
    addGroupMember,
    createBid,
    createBudgetItem,
    createDynamicValues,
    createEvent,
    createGroup,
    navigate,
    queryClient,
    user,
    setEventLoading,
    handleCreateError,
  ]);
}

function captureCreateEventErrorInSentry(
  eventType: string,
  eventId: string | undefined,
  error: unknown
) {
  if (error instanceof Error) {
    Sentry.captureException(error, {
      level: 'error',
      tags: {
        eventId: eventId,
        eventType: eventType,
      },
    });
  } else {
    Sentry.captureMessage('Error creating Event', {
      level: 'error',
      tags: {
        eventId: eventId,
        eventType: eventType,
      },
    });
  }
}

// provides a callback that can be used to show a confirmation modal for errors and send the right sentry message
const useHandleCreateEventError = () => {
  const deleteEvent = useDeleteEventMutation();
  const navigate = useNavigate();

  return useCallback(
    // if createdEventId is defined, there is a partial error (the event was created but data is missing)
    // if undefined, the event generation failed in its first step.
    (eventType: string, createdEventId: string | undefined, error: unknown) => {
      captureCreateEventErrorInSentry(eventType, createdEventId, error);

      // provide a simple error message to the user, so that we can easily debug screenshots sent to us
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';

      const message = createdEventId
        ? `It seems like there was an issue while creating your ${eventType} event, part of your event was created but there are missing elements.`
        : `It seems like there was an issue while creating your ${eventType} event.`;

      const labels = createdEventId
        ? { cancel: 'Keep Event', confirm: 'Try again' }
        : { cancel: 'Cancel', confirm: 'Try again' };

      openConfirmModal({
        title: 'Error Creating Your Event',
        children: message + ' ' + errorMessage,
        labels: labels,

        onConfirm: () => {
          if (createdEventId) {
            deleteEvent.mutateAsync(createdEventId);
            navigate(linkToEvents());
          } else {
            modals.closeAll();
          }
        },
        onCancel: () => {
          modals.closeAll();
        },
      });
    },
    [navigate, deleteEvent]
  );
};
