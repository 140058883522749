/* Framework */
import { Stack } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useState } from 'react';
import { ImportFileDropArea } from './ImportFileDropArea';

import { useItemUploadFormContext } from './ItemUploadFormContext';
import { useStyles } from './wizardStyles';

import { CsvParseResult, parseCsvFile } from './CsvFileParser';

interface ImportFileFormProps {
  onComplete: (data: CsvParseResult) => void;
}

export function ImportFileForm({ onComplete }: ImportFileFormProps): JSX.Element {
  const { classes } = useStyles();
  const form = useItemUploadFormContext();
  const [loading, setLoading] = useState(false);

  const handleFileDropped = async (file: FileWithPath) => {
    setLoading(true);
    const parseResult = await parseCsvFile(file);
    onComplete(parseResult);
    setLoading(false);
  };

  return (
    <>
      <Stack spacing="lg" style={{ width: '100%' }}>
        <ImportFileDropArea onFileDropped={handleFileDropped} loading={loading} />
      </Stack>
    </>
  );
}
