import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { AppRefreshDialog } from '../dialogs/AppRefreshDialog';

export type AppRefreshContextType = {
  needsAppRefresh: boolean;
  setNeedsAppRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppRefreshContext = createContext<AppRefreshContextType | null>(null);

// required component that provides useAppRefresh capabilities
export function AppRefreshProvider({ children }: { children: ReactNode }): JSX.Element {
  const [needsAppRefresh, setNeedsAppRefresh] = useState(false);

  const providerValue = useMemo(() => {
    return { needsAppRefresh, setNeedsAppRefresh };
  }, [needsAppRefresh]);

  return (
    <AppRefreshContext.Provider value={providerValue}>
      <AppRefreshDialog opened={needsAppRefresh} />
      <>{children}</>
    </AppRefreshContext.Provider>
  );
}

// hook to read/set app-refresh flag
export function useAppRefresh() {
  const context = useContext(AppRefreshContext);

  if (!context) {
    throw new Error('useAppRefresh context is not defined. missing an outer AppRefreshProvider');
  }

  return context;
}

// checks if a version update (refresh) is needed on route change
export function useAppRefreshOnRouteChange() {
  const location = useLocation();
  const { needsAppRefresh } = useAppRefresh();
  // use a ref to avoid refreshing the app immediately on detection
  const needsRefreshRef = useRef(needsAppRefresh);
  const initialLocationRef = useRef(location);

  useEffect(() => {
    needsRefreshRef.current = needsAppRefresh;
  }, [needsAppRefresh]);

  useLayoutEffect(() => {
    // skip the first location change, to avoid a redirect-loop when the app initially loads
    // this can happen when the client is too fast loading the new content
    // and the server is still lagging behind
    if (initialLocationRef.current.pathname === location.pathname) {
      return;
    }

    if (!needsRefreshRef.current) {
      // do nothing if no refresh needed
      return;
    }

    // if there is an update available and no state passed to route
    if (!location.state) {
      console.log('useAppRefreshOnRouteChange triggers reload because of version mismatch');
      window.location.reload(); // refresh the browser
    }
  }, [location]);
}
