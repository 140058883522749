export enum EventMemberRole {
  Participant = 0,
  Facilitator = 1,
}

export type EventMember = {
  id: string;
  userId: string;
  isMember: boolean;
  role?: EventMemberRole;
  eventId: string;
};

export type PostEventMember = Omit<EventMember, 'id' | 'isMember' | 'eventId'>;

export type PatchEventMember = Required<Pick<EventMember, 'userId' | 'role' | 'id'>>;
