import { ReactNode } from 'react';
import { useRequiredParam } from '../../../../hooks/useRequiredParam';
import { EventMemberRole } from '../../../../models/eventMember.interface';
import { Navigate } from 'react-router-dom';
import { linkToEvent } from '../../../../utils/event-urls';
import { useMyRoleByEventIdQuery } from '../../../../api/eventMember.queries';

interface RequireEventRoleProps {
  role: EventMemberRole;
  children: ReactNode;
}

// checks if user has required role, and shows content
// otherwise redirects to event page
export function RequireEventRole({ children, role }: RequireEventRoleProps): JSX.Element | null {
  const eventId = useRequiredParam('eventId');
  const { data } = useMyRoleByEventIdQuery(eventId);

  if (!data) {
    return null;
  }

  // either show content or redirect to event page
  if (data.role === role) {
    return <>{children}</>;
  } else {
    return <Navigate to={linkToEvent(eventId)}></Navigate>;
  }
}
