import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';

export type MessageHandler = (ev: MessageEvent) => void;
export type SocketGenerator = () => WebSocket;

export interface WebSocketManagedOptions {
  // a generator function, must return a new websocket every time it is called
  newWebSocket: SocketGenerator;
  // the message handler for the websocket
  onMessage: MessageHandler;

  // limit the amount of attempts,
  // if negative or undefined, try forever until close() is called
  // default: try forever
  maxReconnectAttempts?: number;

  // called whenever a connection is made
  onOpen?: (ev: Event, reconnectAttempts: number) => void;
  // called when the connection was lost or closed
  onCloseOrError?: (ev: CloseEvent | Event) => void;
  // called when a reconnect attempt is made
  onReconnecting?: (attempts: number) => void;

  // called when this logic gives up on reconnecting
  onReconnectFailed?: () => void;
}

/**
 * A util class to provide a WebSocket with connection retries.
 */
class WebSocketManaged {
  private ws: WebSocket | null = null;
  private shouldReconnect = true;
  private reconnectAttempts = 0;

  constructor(private options: WebSocketManagedOptions) {}

  connect() {
    if (this.ws === null) {
      this.shouldReconnect = true;

      // create new socket
      const ws = this.options.newWebSocket();
      this.ws = ws;

      // attach handlers
      this.ws.onclose = (ev) => this.onErrorOrClose(ws, ev);
      this.ws.onerror = (ev) => this.onErrorOrClose(ws, ev);
      this.ws.onopen = (ev) => this.onOpen(ws, ev);
      this.ws.onmessage = this.options.onMessage;
    }
  }

  // explicitly close this socket, i.e. to clean up this connection when it is not used anymore
  // this will not attempt any reconnects, unless connect() is called again
  close() {
    this.shouldReconnect = false;
    if (this.ws !== null) {
      // capture variable for closures
      const websocket: WebSocket = this.ws;
      this.ws = null;

      // websocket.close can create errors, if it is not connected (yet).
      // close the socket asynchronously to avoid errors in strict-mode.
      if (websocket.readyState === WebSocket.CONNECTING) {
        // make sure to call the old onopen handler
        const oldOpenFn = websocket.onopen;
        websocket.onopen = (ev: Event) => {
          if (oldOpenFn != null) {
            oldOpenFn.call(websocket, ev);
          }
          websocket.close();
        };
      } else if (websocket.readyState === WebSocket.OPEN) {
        // connected, close socket
        websocket.close();
      }
      // other states: the websocket is either closing or closed
    }
  }

  // update websocket generator function
  setGenerator(newWebSocket: SocketGenerator) {
    this.options.newWebSocket = newWebSocket;
  }

  private scheduleReconnect() {
    this.reconnectAttempts++;
    // exponential backoff with randomness
    // 2, 4, 8, 16, then cap at 30 seconds
    const nextReconnectSeconds =
      this.reconnectAttempts > 4
        ? 30 + Math.random()
        : Math.pow(2, this.reconnectAttempts) + Math.random();

    const nextReconnectTime = nextReconnectSeconds * 1000;

    setTimeout(() => {
      // prevent reconnect if close() was called in the meantime
      if (this.shouldReconnect) {
        this.connect();
      }
    }, nextReconnectTime);

    this.options.onReconnecting?.(this.reconnectAttempts);
  }

  isOpen(): boolean {
    if (this.ws === undefined) {
      return false;
    } else {
      return this.ws?.readyState === WebSocket.OPEN;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onOpen(ws: WebSocket, ev: Event) {
    try {
      this.options.onOpen?.(ev, this.reconnectAttempts);
    } finally {
      this.reconnectAttempts = 0;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onErrorOrClose(ws: WebSocket, ev: CloseEvent | Event) {
    // this handler can be called multiple times, by different websockets
    // as they may be opening/closing at the same time.

    try {
      // any error thrown in this function should not stop our cleanup logic
      this.options.onCloseOrError?.(ev);
    } finally {
      // always clean the socket on close (safe to call this multiple times)
      // this also prevents the onErrorOrClose handler to be called twice
      // on error
      cleanUpWebsocket(ws);

      // if we were currently attached to this socket, clean it
      if (this.ws === ws) {
        this.ws = null;

        if (!this.shouldReconnect) {
          // close was called, do not reconnect
        } else if (
          this.options.maxReconnectAttempts !== undefined &&
          this.options.maxReconnectAttempts >= 0 &&
          this.reconnectAttempts >= this.options.maxReconnectAttempts
        ) {
          // give up on reconnects
          this.options.onReconnectFailed?.();
        } else {
          this.scheduleReconnect();
        }
      }
    }
  }
}

// avoid any memory leaks by cleaning up the ws handlers
function cleanUpWebsocket(ws: WebSocket) {
  ws.onmessage = null;
  ws.onopen = null;
  ws.onclose = null;
  ws.onerror = null;
}

// Hook that manages the lifecycle of any opened websocket connections
// if there is no acctive websocket connection, create a new one and return it
// if we already have an open websocket connection, return it without creating a new one
// if there are no listeners with a debounce of 500ms (listening components using this hook), close the active websocket connection
export function useWebsocket(messageHandler: MessageHandler) {
  // keep a persistent reference to the websocket, and store its open status in state
  const webSocketRef = useRef<WebSocketManaged>();
  const messageHandlerRef = useRef<MessageHandler>(messageHandler);
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setStateToForceRender] = useState({});

  useEffect(() => {
    // react to changes to the messageHandler without requiring to create a new websocket connection
    messageHandlerRef.current = messageHandler;
  }, [messageHandler]);

  const subscribe = useCallback(
    (socketGenerator: SocketGenerator) => {
      console.log('new websocket');
      // don't create a new websocket if we already have an active one
      if (!webSocketRef.current) {
        const socketManaged = new WebSocketManaged({
          newWebSocket: socketGenerator,
          onMessage: (message) =>
            messageHandlerRef.current
              ? messageHandlerRef.current(message)
              : console.warn('websocket messageHandler not defined. message was dropped.', {
                  message,
                }),
          onOpen: (ev, reconnectAttempts) => {
            console.log('websocket open!, reconnectAttempts: ', reconnectAttempts);
            setStateToForceRender({}); // required, so that "isOpen" is recalculated
            if (reconnectAttempts > 0) {
              console.log('Invalidating all queries');
              queryClient.invalidateQueries();
            }
          },
          onCloseOrError: (ev) => {
            const type: string = ev.type;
            const closeCode: number | undefined = ev instanceof CloseEvent ? ev.code : undefined;
            console.log('websocket closed', { type, closeCode });
            setStateToForceRender({}); // required, so that "isOpen" is recalculated
          },
          onReconnecting: (attempt) => console.log('websocket reconnecting - attempt: ' + attempt),
          onReconnectFailed: () => console.log('websocket reconnect failed'),
          maxReconnectAttempts: 15,
        });
        webSocketRef.current = socketManaged;
      } else {
        // update socket generator for already created socket(s)
        // so that on any connection attempt, the fresh reference is used
        webSocketRef.current.setGenerator(socketGenerator);
      }

      webSocketRef.current.connect();
    },
    [queryClient]
  );

  const unsubscribe = useCallback(() => {
    webSocketRef.current?.close();
    webSocketRef.current = undefined;
  }, []);

  const socketOpen = webSocketRef.current?.isOpen() || false;

  return {
    subscribe,
    unsubscribe,
    socketOpen,
  };
}
