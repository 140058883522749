import { Text, Box, Paper, Title, Flex } from '@mantine/core';
import LoginForm from '../../../components/form/login/LoginForm';

export const LoginHero = () => {
  return (
    <Flex wrap="wrap" p="md" gap={30} sx={(theme) => ({ background: theme.colors.teal[4] })}>
      <Box style={{ flex: 3, alignSelf: 'center' }}>
        <Title color="white" order={1}>
          SAFe CoFund
        </Title>
        <Text size="xl" sx={(theme) => ({ color: theme.colors.teal[0] })}>
          It&apos;s Participatory Budgeting, simplified
        </Text>
        <Text color="white" size="xl">
          A powerful application that lets you inform value stream funding collaboratively.
        </Text>
      </Box>
      <Paper py="xl" style={{ flex: 2 }} withBorder>
        <LoginForm />
      </Paper>
    </Flex>
  );
};
