export function parseNumberInput(value: string): string {
  return value === '' ? '0' : value.replace(/\$\s?|([.,]*)/g, '');
}

export function formatNumberInput(value: string): string {
  if (!value) {
    return '';
  }
  const n = parseInt(value);
  if (n === 0) {
    return '';
  }
  return n.toLocaleString('en-US', {
    minimumFractionDigits: Math.ceil(n % 1) * 2,
    maximumFractionDigits: 2,
  });
}
