import { createStyles, MantineTheme } from '@mantine/core';

export function useSmallEditorStyles() {
  const { classes } = useStyles();
  return {
    content: classes.editorSmall,
  };
}

export function useMediumEditorStyles() {
  const { classes } = useStyles();
  return {
    content: classes.editorMedium,
  };
}

export function useDiabledEditorStyles() {
  const { classes } = useStyles();
  return {
    content: classes.editorDisabled,
  };
}

export function useLargeEditorStyles() {
  const { classes } = useStyles();
  return {
    content: classes.editorLarge,
  };
}

export const useStyles = createStyles((theme) => ({
  editorSmall: {
    maxHeight: 120,
    overflowY: 'auto',
  },
  editorLarge: {
    minHeight: 120,
    maxHeight: 600,
    marginTop: 20,
    overflowY: 'auto',
  },
  editorMedium: {
    minHeight: 80,
    maxHeight: 270,
    overflowY: 'auto',
  },
  editorDisabled: {
    minHeight: 80,
    maxHeight: 270,
    overflowY: 'auto',
    pointerEvents: 'none',
    opacity: 0.5,
  },
  editorContent: {
    ul: {
      p: {
        margin: theme.spacing.xs,
      },
    },
  },
}));
