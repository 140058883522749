/* Framework */
import { Loader, Select, SelectItem, Stack, Table, Text } from '@mantine/core';
import { useState } from 'react';

import {
  CsvFieldMapping,
  DragonFruitFieldType,
  TDragonFruitFieldNames,
  labelToLower,
  useItemUploadFormContext,
} from './ItemUploadFormContext';
import { useStyles } from './wizardStyles';

function mapCsvFields(dfFields: DragonFruitFieldType[], csvFields?: string[]): CsvFieldMapping[] {
  const mapping: CsvFieldMapping[] = [];
  for (const dfField of dfFields) {
    const r: CsvFieldMapping = {
      dfField: dfField.name,
      dfFieldLower: labelToLower(dfField.name),
      mandatory: dfField.mandatory,
    };
    const csvField = csvFields?.find((c) => labelToLower(c) === r.dfFieldLower);
    if (csvField) {
      r.csvField = csvField;
      r.csvFieldLower = labelToLower(csvField);
    }
    mapping.push(r);
  }

  return mapping;
}

function mapSelectFields(headers: string[], fieldMapping: CsvFieldMapping[]): SelectItem[] {
  return (
    headers.map((v) => ({
      label: v,
      value: v,
      disabled: fieldMapping.some((m) => m.csvField === v),
    })) ?? []
  );
}

interface AssignCsvFieldsFormProps {
  csvFieldNames: TDragonFruitFieldNames;
  loading?: boolean;
}

export function AssignCsvFieldsForm({
  loading,
  csvFieldNames,
}: AssignCsvFieldsFormProps): JSX.Element {
  const { classes } = useStyles();
  const form = useItemUploadFormContext();

  const DragonFruitFields: DragonFruitFieldType[] = [
    { name: csvFieldNames.NAME, mandatory: true },
    { name: csvFieldNames.DESC },
    { name: csvFieldNames.COST_EST, mandatory: true },
    { name: csvFieldNames.ITEM_TYPE, mandatory: true },
    { name: csvFieldNames.STRATEGIC_THEMES },
    { name: csvFieldNames.VALUE_STREAMS },
    { name: csvFieldNames.HORIZON },
  ];

  const [fieldMapping, setFieldMapping] = useState<CsvFieldMapping[]>(() =>
    mapCsvFields(DragonFruitFields, form.values.csvHeaders)
  );
  const [selectFields, setSelectFields] = useState<SelectItem[]>(() =>
    mapSelectFields(form.values.csvHeaders || [], fieldMapping)
  );

  const handleMappingChange = (dfFieldIdent: string, v: string | null | undefined) => {
    setFieldMapping((state) => {
      const nextState: CsvFieldMapping[] = state.map((m) => {
        if (m.dfFieldLower === dfFieldIdent) {
          if (v) {
            return { ...m, csvField: v, csvFieldLower: labelToLower(v) };
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { csvField, csvFieldLower, ...strippedMap } = m;
            return strippedMap;
          }
        } else {
          return m;
        }
      });
      // Also disable items that have been selected already
      setSelectFields(mapSelectFields(form.values.csvHeaders || [], nextState));

      form.setValues({
        mandatoryFieldsMapped: nextState.filter((m) => m.mandatory && !m.csvField).length === 0,
        csvFieldMapping: nextState,
      });
      return nextState;
    });
  };

  return loading ? (
    <Stack align="center" justify="center" mb="md">
      <Loader size="200px" />
    </Stack>
  ) : (
    <>
      <Stack align="center" justify="center" spacing={0} mb="md">
        <Text className={classes.uploadSuccessful}>Map Spreadsheet Columns</Text>

        <Text w="30rem" className={classes.matchError}>
          The Item Name, Item Type, and Estimated Cost fields must be mapped to columns from the
          spreadsheet to proceed.
        </Text>
        <Text w="30rem" className={classes.matchHint}>
          Feel free to change existing mappings or map additional spreadsheet columns to fields
          below.
        </Text>
      </Stack>
      <Stack spacing="lg" style={{ width: '100%' }}>
        <Table verticalSpacing="xs">
          <thead className={classes.tableHeader}>
            <tr>
              <th>CoFund Field</th>
              <th>Spreadsheet Column</th>
            </tr>
          </thead>
          <tbody>
            {fieldMapping.map((mapping, idx) => (
              // use better key
              <tr key={idx}>
                <td className={classes.tableCell}>
                  <span>{mapping.dfField}</span>
                  {mapping.mandatory && <span className={classes.mandatoryField}> *</span>}
                </td>
                <td className={classes.tableCell}>
                  <Select
                    allowDeselect
                    clearable
                    data={selectFields}
                    placeholder="Select Spreadsheet Column"
                    value={mapping.csvField ?? null}
                    onChange={(v) => handleMappingChange(mapping.dfFieldLower, v)}
                    style={{ flex: 1 }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Stack>
    </>
  );
}
