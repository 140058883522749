import { Button, Group, SelectItem, Text } from '@mantine/core';
import { ContextModalProps, closeModal, openContextModal } from '@mantine/modals';
import { useState } from 'react';
import { useUpdateEventJoinLinkMutation } from '../../api/event-join-link.queries';
import { roleId } from '../../components/participants/EventJoinLinkTableEntry';
import { EventJoinLink } from '../../models/event-join-link.interface';
import { ParticipantEditForm, useParticipantEditForm } from './ParticipantEditForm';
import { PARTICIPANT_STATUS_AWAIT_JOIN, PARTICIPANT_STATUS_JOINED } from './participantsHelpers';

export const inviteParticipantEditModalKey = 'inviteParticipantEdit';

export function openInviteParticipantEditModal(
  eventJoinLink: EventJoinLink,
  groupChoices: SelectItem[]
) {
  const props: InviteParticipantEditModalProps = {
    eventJoinLink,
    groupChoices,
  };
  return openContextModal({
    modal: inviteParticipantEditModalKey,
    title: 'Edit Invited Participant',
    innerProps: props,
    size: 'md',
  });
}

export interface InviteParticipantEditModalProps extends Record<string, unknown> {
  eventJoinLink: EventJoinLink;
  groupChoices: SelectItem[];
}

export function InviteParticipantEditModal({
  context,
  id,
  innerProps,
}: ContextModalProps<InviteParticipantEditModalProps>): JSX.Element {
  const participantEditForm = useParticipantEditForm({
    ...innerProps.eventJoinLink,
    emailSentAtStr: innerProps.eventJoinLink.emailSentAt
      ? innerProps.eventJoinLink.emailSentAt
      : '',
    status: innerProps.eventJoinLink.hasUserJoined
      ? PARTICIPANT_STATUS_JOINED
      : PARTICIPANT_STATUS_AWAIT_JOIN,
    roleId: roleId(innerProps.eventJoinLink.role),
    selectedGroupId: innerProps.eventJoinLink.groupId || '',
  });

  const updateEventJoinLink = useUpdateEventJoinLinkMutation();

  const [errorMsg, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    updateEventJoinLink
      .mutateAsync({
        id: innerProps.eventJoinLink.id,
        groupId: participantEditForm.values.selectedGroupId || null,
        role: participantEditForm.values.role,
      })
      .then(
        () => {
          closeModal(id);
        },
        (error) => {
          const msg = 'Can not update event join link: ' + error;
          setErrorMessage(msg);
        }
      );
  };

  return (
    <>
      <ParticipantEditForm
        inviteParticipantEditForm={participantEditForm}
        groupChoices={innerProps.groupChoices}
      />
      {errorMsg.length ? (
        <Text c="error.1" mt="md">
          {errorMsg}
        </Text>
      ) : null}
      <Group position="center" mt="md">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Group>
    </>
  );
}
