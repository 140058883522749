import { generateHTML, generateJSON, JSONContent } from '@tiptap/react';
import { Document } from '@tiptap/extension-document';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Text } from '@tiptap/extension-text';
import { editorExtensions } from './editorConfig';

export function generateJsonFromString(str: string): JSONContent {
  return generateJSON(`<p>${str}</p>`, [Document, Paragraph, Text]);
}

export function generateHtmlFromJson(content?: JSONContent | null) {
  if (content) {
    return generateHTML(content, editorExtensions({ contentEditable: false }));
  }
  return '';
}
