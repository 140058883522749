export const ApiBase = {
  PROTOCOL: 'http',
  SERVER: 'localhost:3080', // API Base
  VERSION: '/api/v1',
};

export const ApiConstants = {
  BASE_URL: `${ApiBase.VERSION}`,
};

export const ApiEndpoints = {
  SESSIONS: '/sessions',
  USERS: '/users',
  PARTICIPANTS: '/participants',
  EVENTS: '/events',
  BIDDING_ITEMS: '/bidding-items',
  EVENT_JOIN_LINKS: '/event-join-links',
  BIDS: '/bids',
  GROUPS: '/groups',
  MY_GROUP: 'groups/me',
  WEBSOCKET: '/ws',
};

export const MAX_RETRIES = 3;
export const HTTP_STATUS_TO_NOT_RETRY = [404, 500, 401];
