import {
  Container,
  Group,
  Anchor,
  ActionIcon,
  Text,
  em,
  getBreakpointValue,
  Box,
} from '@mantine/core';
import { useStyles } from '../layoutStyles';
import {
  IconBrandFacebookFilled,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandYoutube,
} from '@tabler/icons-react';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useGetScreenSize } from '../../../hooks/useResponsiveDevices';

import styles from './components/footer.module.css';

import { EventFooterNavigation } from './components/EventFooterNavigation';
import { GroupFooterNavigation } from './components/GroupFooterNavigation';
import { useIsFacilitator } from '../../../hooks/useIsFacilitator';

type FooterLinkProps = {
  label: string;
  externalLink?: string;
  internalLink?: string;
};

interface FooterProps {
  links: FooterLinkProps[];
}

export function AppFooter({ links }: FooterProps) {
  const { classes } = useStyles();
  const { eventId, groupId } = useParams();
  const [searchParams] = useSearchParams();
  const isSample = searchParams.get('sampleEvent');
  const { isFacilitator } = useIsFacilitator(eventId);
  const { smallScreen } = useGetScreenSize();
  const location = useLocation();

  const activeNavLink = isSample ? `${location.pathname}?sampleEvent=true` : `${location.pathname}`;
  const hideEventNavigation = !groupId && !isFacilitator;
  const items = links.map((link) => (
    <Box key={link.label}>
      {link.internalLink ? (
        <Link to={link.internalLink} target="_blank" style={{ textDecoration: 'none' }}>
          {' '}
          <Text size="sm" color="dimmed" weight={700}>
            {link.label}{' '}
          </Text>
        </Link>
      ) : (
        <Anchor<'a'> color="dimmed" href={link.externalLink} size="sm" target="_blank">
          <Group>
            <Text size="sm" weight={700}>
              {link.label}{' '}
            </Text>
            <Text>&bull;</Text>
          </Group>
        </Anchor>
      )}
    </Box>
  ));

  return (
    <>
      {smallScreen && eventId ? (
        <Container
          w="100%"
          m={0}
          p={0}
          fluid
          pos={'fixed'}
          bottom={0}
          style={{ zIndex: 99 }}
          hidden={hideEventNavigation}
        >
          <Box
            h={70}
            w="100%"
            sx={(theme) => ({ background: theme.colors.gray[0], boxShadow: theme.shadows.xl })}
          >
            {groupId ? (
              <Box>
                <GroupFooterNavigation
                  activeNavLink={activeNavLink}
                  eventId={eventId}
                  groupId={groupId}
                  isSample={!!isSample}
                />
              </Box>
            ) : (
              <EventFooterNavigation
                activeNavLink={activeNavLink}
                eventId={eventId}
                isSample={!!isSample}
              />
            )}
          </Box>
        </Container>
      ) : null}

      <Container m={0} p={0} fluid>
        <Group p="md" position="apart" sx={(theme) => ({ background: theme.colors.yellow[0] })}>
          <Group className={classes.links}>
            <Text
              sx={(theme) => ({
                [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]: {
                  width: '100%',
                  textAlign: 'center',
                },
              })}
            >
              © 2023 Scaled Agile, Inc.
            </Text>
            {items}
          </Group>
          <Group></Group>
          <Group
            spacing="xs"
            position="center"
            noWrap
            sx={(theme) => ({
              [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.xl) - 1)})`]: {
                width: '100%',
              },
            })}
          >
            <ActionIcon
              size="lg"
              variant="default"
              radius="xl"
              component="a"
              href="https://www.facebook.com/ScaledAgile/"
              target="_blank"
            >
              <IconBrandFacebookFilled size="1.05rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              variant="default"
              radius="xl"
              component="a"
              href="https://www.linkedin.com/company/scaled-agile-inc-/mycompany/"
              target="_blank"
            >
              <IconBrandLinkedin size="1.05rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              variant="default"
              radius="xl"
              component="a"
              href="https://twitter.com/ScaledAgile"
              target="_blank"
            >
              <IconBrandTwitter size="1.05rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon
              size="lg"
              variant="default"
              radius="xl"
              component="a"
              href="https://www.youtube.com/user/scaledagile"
              target="_blank"
            >
              <IconBrandYoutube size="1.05rem" stroke={1.5} />
            </ActionIcon>
          </Group>
        </Group>
      </Container>
    </>
  );
}
