import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GroupApi } from './api';
import { PostGroup, EventGroup } from '../models/group.interface';
import { QueryKeysMap } from '../models/keys.type';

type Filters = {
  eventId: string;
};

export const groupsKeys: QueryKeysMap<'groups', Filters> = {
  all: ['groups'] as const,
  list: () => [...groupsKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...groupsKeys.list(), { ...filters }] as const,
  detail: () => [...groupsKeys.all, 'detail'] as const,
  byId: (groupId: string) => [...groupsKeys.detail(), groupId] as const,
};

export function useGroupsByEventIdQuery(eventId: string) {
  return useQuery({
    queryKey: groupsKeys.filter({ eventId: eventId }),
    queryFn: () => GroupApi.getAll({ eventId }),
    select: (data) => [...data].sort((a, b) => a.name.localeCompare(b.name)),
  });
}

export function useParticipantGroups(eventId: string) {
  return useQuery({
    queryKey: groupsKeys.filter({ eventId }),
    queryFn: () => GroupApi.getParticipantGroup(),
  });
}

export function useGroupByIdQuery(groupId: string) {
  return useQuery({
    queryKey: groupsKeys.byId(groupId),
    queryFn: () => GroupApi.getDetails(groupId),
    enabled: !!groupId,
  });
}

export function useCreateGroupMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (group: PostGroup) => GroupApi.createGroup(group),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: groupsKeys.all });
    },
  });
}

export function useUpdateGroupMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (group: EventGroup) => GroupApi.updateGroup(group),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: groupsKeys.all });
    },
  });
}

export function useDeleteGroupMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (groupId: string) => GroupApi.deleteGroup(groupId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: groupsKeys.all });
    },
  });
}
