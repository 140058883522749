import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../hooks/useAuth';
import { useQueryCacheManager } from '../hooks/useQueryCacheManager';
import { EventMember, PatchEventMember, PostEventMember } from '../models/eventMember.interface';
import { QueryKeysMap } from '../models/keys.type';
import { EventMemberApi, EventMembershipApi } from './api';

type Filters = {
  eventId?: string;
};

export const eventMemberKeys: QueryKeysMap<'eventMembers', Filters> = {
  all: ['eventMembers'] as const,
  list: () => [...eventMemberKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...eventMemberKeys.list(), { ...filters }],
  detail: () => [...eventMemberKeys.all, 'detail'] as const,
  byId: (id: string) => [...eventMemberKeys.detail(), id] as const,
};

function eventMembersByEventIdQuery(eventId: string | undefined) {
  // Tanstack's making sure this only gets called with a defined value
  // options: https://tkdodo.eu/blog/react-query-and-type-script#type-safety-with-the-enabled-option
  return {
    queryKey: eventMemberKeys.filter({ eventId }),
    queryFn: () => EventMemberApi.getAll(eventId || 'undefined'),
    enabled: !!eventId,
  };
}

export function useEventMembersByEventIdQuery(eventId: string) {
  return useQuery(eventMembersByEventIdQuery(eventId));
}

export function useMyRoleByEventIdQuery(eventId: string | undefined) {
  const { user } = useAuthContext();
  const userId = user?.id;
  return useQuery({
    ...eventMembersByEventIdQuery(eventId),
    select: (data) => {
      const role = data.find((m) => m.userId === userId)?.role;
      return { role, isMember: role !== undefined };
    },
    enabled: !!userId && !!eventId,
  });
}

export function useAddEventMemberMutation() {
  const { addCachedData } = useQueryCacheManager();
  return useMutation({
    mutationFn: ({
      eventId,
      eventMember,
      inviteId,
    }: {
      eventId: string;
      eventMember: PostEventMember;
      inviteId?: string;
    }) => EventMemberApi.addMemberToEvent(eventId, eventMember, inviteId),
    onSuccess(data) {
      // updates to event-members can happen outside of websocket lifecycle
      // so make sure to update any caches
      addCachedData({
        resource: eventMemberKeys.all[0],
        data: data,
      });
    },
  });
}

export type JoinEventResult = {
  member?: EventMember;
  canJoin: boolean;
  added: boolean;
};

// special mutation that will
// - check if userId can be added to event OR if userId is already added
// - make a POST request to add the user
export function useJoinEventMemberMutation() {
  const { addCachedData, updateCachedData } = useQueryCacheManager();
  return useMutation({
    throwOnError: true, // if anything fails, make sure error is shown to user via boundary
    mutationFn: async ({
      userId,
      eventId,
      inviteId,
    }: {
      userId: string;
      eventId: string;
      inviteId?: string;
    }): Promise<JoinEventResult> => {
      const memberCheck = await EventMembershipApi.get(eventId, inviteId);

      if (memberCheck.isMember) {
        // if already added, get the member and return that result
        const members = await EventMemberApi.getAll(eventId);
        const memberMe = members.find((m) => m.userId === userId);

        // it shouldn't be possible for the existing member to not be found,
        // but just in case they aren't, the POST below will be called to re-add them
        if (memberMe) {
          return {
            member: memberMe,
            added: false,
            canJoin: true,
          };
        }
      } else if (!memberCheck.canJoin) {
        return {
          added: false,
          canJoin: false,
        };
      }

      // otherwise, make POST call
      const memberNew = await EventMemberApi.addMemberToEvent(
        eventId,
        {
          userId: userId,
        },
        inviteId
      );

      return {
        member: memberNew,
        added: true,
        canJoin: true,
      };
    },
    onSuccess(data) {
      // updates to event-members can happen outside of websocket lifecycle
      // for example, when you join
      // so make sure to update any existing caches
      const { member, added } = data;
      if (member === undefined) {
        return;
      }

      if (added) {
        addCachedData({
          resource: eventMemberKeys.all[0],
          data: member,
        });
      } else {
        updateCachedData({
          resource: eventMemberKeys.all[0],
          data: member,
        });
      }
    },
  });
}

export function useUpdateEventMemberMutation() {
  const { updateCachedData } = useQueryCacheManager();

  return useMutation({
    mutationFn: ({ eventId, eventMember }: { eventId: string; eventMember: PatchEventMember }) =>
      EventMemberApi.updateMemberInEvent(eventId, eventMember),
    onSuccess: (member) => {
      updateCachedData({
        resource: eventMemberKeys.all[0],
        data: member,
      });
    },
  });
}

export function useDeleteEventMemberMutation() {
  const { removeCachedData } = useQueryCacheManager();

  return useMutation({
    mutationFn: ({ eventId, member }: { eventId: string; member: EventMember }) =>
      EventMemberApi.deleteEventMember(eventId, member),
    onSuccess: (data, variables) => {
      removeCachedData({
        resource: eventMemberKeys.all[0],
        data: variables.member,
      });
    },
  });
}
