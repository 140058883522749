import { ActionIcon, Avatar, Group, Select, SelectItem, Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconMailFast, IconPencil, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
  useDeleteEventJoinLinkMutation,
  useSendInvitationEmailMutation,
  useUpdateEventJoinLinkMutation,
} from '../../api/event-join-link.queries';
import { openInviteParticipantEditModal } from '../../dialogs/participants/InviteParticipantEditModal';
import {
  PARTICIPANT_STATUS_AWAIT_INVITE,
  PARTICIPANT_STATUS_AWAIT_JOIN,
  PARTICIPANT_STATUS_JOINED,
} from '../../dialogs/participants/participantsHelpers';
import { EventJoinLink } from '../../models/event-join-link.interface';
import { EventMemberRole } from '../../models/eventMember.interface';
import { EventGroup } from '../../models/group.interface';
import { useStyles } from '../../pages/events/eventStyles';
import { userAvatarUrl } from '../../utils/avatar-helpers';
import { DateFormat, formatDate } from '../layout/components/i18n/FormattedDate';
import { CopyInviteLinkButton } from './CopyInviteLinkButton';

export function roleId(role?: EventMemberRole): string {
  switch (role) {
    case EventMemberRole.Facilitator:
      return 'Facilitator';
    case EventMemberRole.Participant:
      return 'Participant';

    default:
      return 'Unknown';
  }
}

export function roleLabel(role?: EventMemberRole): string {
  switch (role) {
    case EventMemberRole.Facilitator:
      return 'Facilitator';
    case EventMemberRole.Participant:
      return 'Participant';

    default:
      return 'Unknown';
  }
}

function includedInSearch(search: string | undefined, str: string | undefined): boolean {
  if (!search || !str) {
    return true;
  }
  return str.toLowerCase().includes(search);
}

/**
 * Don't send complete email address to avatar service for security reasons
 */
function emailFirstPart(email: string): string {
  const pos = email.indexOf('@');
  return email.substring(0, pos > 0 ? pos : email.length);
}

export const selectableRoles = [
  { value: roleId(EventMemberRole.Facilitator), label: roleLabel(EventMemberRole.Facilitator) },
  { value: roleId(EventMemberRole.Participant), label: roleLabel(EventMemberRole.Participant) },
];

export interface EventJoinLinkTableEntryProps {
  groups: EventGroup[];
  eventJoinLink: EventJoinLink;
  search?: string;
}

export function EventJoinLinkTableEntry({
  groups,
  eventJoinLink,
  search,
}: EventJoinLinkTableEntryProps): JSX.Element | null {
  const groupData = groups.map((group) => ({ value: group.id, label: group.name }));
  const updateEventJoinLink = useUpdateEventJoinLinkMutation();
  const deleteEventJoinLink = useDeleteEventJoinLinkMutation();
  const sendInvitation = useSendInvitationEmailMutation();

  const { classes } = useStyles();

  const selectedGroupId = eventJoinLink.groupId || '';
  const groupChoices: SelectItem[] = [...groupData, { value: '', label: 'No Table' }];

  const handleGroupChange = (newValue: string | null) => {
    updateEventJoinLink.mutateAsync({
      id: eventJoinLink.id,
      groupId: newValue || null,
      role: eventJoinLink.role,
    });
  };

  const handleRoleChange = (newValue: string) => {
    let newRole = eventJoinLink.role || EventMemberRole.Participant;
    let newGroupId = eventJoinLink.groupId || null;
    switch (newValue) {
      case roleId(EventMemberRole.Facilitator):
        newRole = EventMemberRole.Facilitator;
        // remove groupId if changing to a facilitator
        newGroupId = null;
        break;

      case roleId(EventMemberRole.Participant):
        newRole = EventMemberRole.Participant;
        break;

      default:
    }
    updateEventJoinLink.mutateAsync({
      id: eventJoinLink.id,
      groupId: newGroupId,
      role: newRole,
    });
  };

  let status = '';
  let inviteSentDate = '';
  if (eventJoinLink) {
    status = eventJoinLink.hasUserJoined
      ? PARTICIPANT_STATUS_JOINED
      : eventJoinLink.emailSentAt
      ? PARTICIPANT_STATUS_AWAIT_JOIN
      : PARTICIPANT_STATUS_AWAIT_INVITE;
    inviteSentDate = eventJoinLink.emailSentAt
      ? formatDate({ date: eventJoinLink.emailSentAt, dateFormat: DateFormat.DateAndTime })
      : '';
  }

  const hasRecentlySentInvitation = (eventJoinLink: EventJoinLink): boolean => {
    if (eventJoinLink.emailSentAt) {
      return dayjs(eventJoinLink.emailSentAt).add(2, 'hours').isAfter(dayjs());
    }
    return false;
  };

  // EventJoinLink delete with confirmation modal
  const handleDeleteEventJoinLink = (eventJoinLink: EventJoinLink) => {
    openConfirmModal({
      title: 'Confirm Deletion',
      children: (
        <>
          <Text size="sm">
            Do you really want to remove the invitation for &quot;{eventJoinLink.emailAddress}
            &quot;?
          </Text>
        </>
      ),
      labels: { confirm: 'Remove Invitation', cancel: 'Cancel' },
      onConfirm: () => deleteEventJoinLink.mutateAsync(eventJoinLink.id),
    });
  };

  const handleSendSingleInvitation = (eventJoinLink: EventJoinLink) => {
    openConfirmModal({
      title: 'Confirm Email Sending',
      children: (
        <>
          <Text size="sm">
            Do you really want to send an invitation email to &quot;{eventJoinLink.emailAddress}
            &quot;?
          </Text>
          {eventJoinLink.emailSentAt && (
            <Text size="sm">The last invitation was sent on &quot;{inviteSentDate}&quot;.</Text>
          )}
        </>
      ),
      labels: { confirm: 'Send Invitation', cancel: 'Cancel' },
      onConfirm: () => sendInvitation.mutateAsync(eventJoinLink.id),
    });
  };

  return includedInSearch(search, eventJoinLink.emailAddress) ? (
    <tr>
      <td className={classes.tableCell}>
        <Avatar
          size="sm"
          src={userAvatarUrl(`${emailFirstPart(eventJoinLink.emailAddress)}`)}
          radius={45}
        />
      </td>
      <td className={classes.tableCell}>{eventJoinLink.emailAddress}</td>
      <td className={classes.tableCell}>{inviteSentDate}</td>
      <td className={classes.tableCell}>
        {status}
        <CopyInviteLinkButton eventJoinLink={eventJoinLink} />
      </td>
      <td className={classes.tableCell}>
        <Select
          data={selectableRoles}
          placeholder="Select Role"
          value={roleId(eventJoinLink.role)}
          onChange={handleRoleChange}
        />
      </td>
      <td className={classes.tableCell}>
        <Select
          disabled={roleId(eventJoinLink.role) === 'Facilitator'}
          data={groupChoices}
          value={selectedGroupId}
          onChange={handleGroupChange}
        />
      </td>
      <td className={classes.tableCell}>
        {eventJoinLink && (
          <Group spacing={5}>
            <ActionIcon onClick={() => openInviteParticipantEditModal(eventJoinLink, groupChoices)}>
              <IconPencil className={classes.editIcon} size={22} />
            </ActionIcon>
            <ActionIcon
              onClick={() => handleSendSingleInvitation(eventJoinLink)}
              title="Send Invitation"
            >
              <IconMailFast
                size={28}
                className={
                  eventJoinLink.hasUserJoined || hasRecentlySentInvitation(eventJoinLink)
                    ? classes.iconOff
                    : classes.editIcon
                }
              />
            </ActionIcon>
            <ActionIcon
              onClick={() => handleDeleteEventJoinLink(eventJoinLink)}
              title="Delete Invitation"
            >
              <IconTrash
                size={22}
                className={eventJoinLink.hasUserJoined ? classes.iconOff : classes.deleteIcon}
              />
            </ActionIcon>
          </Group>
        )}
      </td>
    </tr>
  ) : null;
}
