/* Framework */
import { Center, Box, Title } from '@mantine/core';

/* Internal */
import { useGetScreenSize } from '../../../hooks/useResponsiveDevices';
// Styling
import { useStyles } from './emptyStateStyles';

interface EmptyStateProps {
  title: string;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
}

const EmptyState = ({ title, primaryAction, secondaryAction }: EmptyStateProps) => {
  const { classes } = useStyles();
  const { smallScreen } = useGetScreenSize();

  return (
    <Center sx={{ flexDirection: 'column' }} px="md">
      <div className={classes.emptyEvents}>
        <Title order={smallScreen ? 6 : 3}>{title}</Title>
        {primaryAction}
        {secondaryAction && (
          <Box>
            or
            {secondaryAction}
          </Box>
        )}
      </div>
    </Center>
  );
};

export default EmptyState;
