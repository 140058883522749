import { useEventByIdQuery } from '../../../../api/event.queries';
import { useRequiredParam } from '../../../../hooks/useRequiredParam';
import { valueStreamName } from '../../../../constants/formDefaults';

export function CustomValueStreamLabel() {
  const eventId = useRequiredParam('eventId');
  const { data: event } = useEventByIdQuery(eventId);

  return (
    <>
      {event?.customLabelValueStreams ? (
        <span>{event.customLabelValueStreams}</span>
      ) : (
        <span>{valueStreamName}</span>
      )}
    </>
  );
}
