import { Loader, Overlay, Title, Stack, useMantineTheme } from '@mantine/core';

interface OverlayLoaderProps {
  title?: string;
}

export const OverlayLoader = ({ title }: OverlayLoaderProps) => {
  const theme = useMantineTheme();

  return (
    <Overlay blur={4} center color={theme.colors.gray[0]} sx={{ zIndex: 90 }}>
      <Stack align="center">
        <Title order={2}> {title} </Title>
        <Loader color={theme.colors.teal[5]} size={70} />
      </Stack>
    </Overlay>
  );
};
