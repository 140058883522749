import { Button, Center } from '@mantine/core';
import { Link } from 'react-router-dom';
import { GroupMemberResult } from '../../../../api/groupMember.queries';
import EmptyState from '../../../../components/loaders/empty-state/EmptyState';
import { EventGroup } from '../../../../models/group.interface';
import { linkToEvent } from '../../../../utils/event-urls';
import { linkToBidding } from '../../../../utils/group-urls';

interface ProtectedEventPageProps {
  eventId: string;
  groups: EventGroup[];
  groupMemberships: GroupMemberResult;
}

export function ProtectedGroupPage({
  eventId,
  groups,
  groupMemberships,
}: ProtectedEventPageProps): JSX.Element {
  let groupMemberIn: EventGroup | undefined = undefined;
  for (const key in groupMemberships.groupResult) {
    if (groupMemberships.groupResult[key].isMember) {
      groupMemberIn = groups.find((g) => g.id === key);
      break;
    }
  }

  const actionBtn = groupMemberIn ? (
    <Button mt="xl" component={Link} to={linkToBidding(eventId, groupMemberIn.id)}>
      Go to {groupMemberIn.name}
    </Button>
  ) : (
    <Button mt="xl" component={Link} to={linkToEvent(eventId)}>
      Go to Event Overview
    </Button>
  );

  return (
    <Center mt={'15%'}>
      <EmptyState title={'You are not a member of this group.'} primaryAction={actionBtn} />
    </Center>
  );
}
