import { Container } from '@mantine/core';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthContext } from '../../hooks/useAuth';
import { LoginHero } from './components/LoginHero';
import { LoginPromotional } from './components/LoginPromotional';
import { LoginStatements } from './components/LoginStatements';
import { LoginVideos } from './components/LoginVideos';

export default function Login() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      let url = state?.path || '/';
      if (state?.search) {
        url = `${url}${state.search}`;
      }
      navigate(url);
    }
  }, [user, navigate, state?.path, state?.search]);

  return (
    <Container fluid m={0} p={0}>
      <LoginHero />
      <LoginPromotional />
      <LoginStatements />
      <LoginVideos />
    </Container>
  );
}
