/* Framework */
import { Stack, Textarea } from '@mantine/core';

/* Internal */
// Data
import { useForm } from '@mantine/form';
import { UseFormReturnType } from '@mantine/form/lib/types';
import { EventJoinLink } from '../../models/event-join-link.interface';
import { useStyles } from './participantStyles';
//Styling

interface ParsedEmailList {
  valid: string[];
  invalid: string[];
  duplicate: string[];
}

/**
 * @param email Based on Tyler McGinnis: https://ui.dev/validate-email-address-javascript
 * Checks if email is valid.
 * If valid will return possibly sanitized email
 * If not valid will return null
 */
function emailIsValid(email: string): string | null {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  if (isValid) {
    return email;
  }

  const compositeRegex = /^\s*(.+?)\s*<([^\s@]+@[^\s@]+\.[^\s@]+)>\s*$/g;
  // check if email is of the form "some name <some.name@example.com>"
  if (compositeRegex.test(email)) {
    // take part between <...> I tried `email.matchAll(compositeRegex)` but just couldn't get it to work
    const pos1 = email.indexOf('<');
    const pos2 = email.indexOf('>', pos1 + 1);
    if (pos1 > -1 && pos2 > pos1) {
      const chunk = email.substring(pos1 + 1, pos2);
      return chunk;
    }
  }

  return null;
}

function emailIsUnique(email: string, eventJoinLinks?: EventJoinLink[]): boolean {
  return !eventJoinLinks?.some((l) => l.emailAddress?.toLowerCase() === email);
}

function partitionEmails(
  emails: string[],
  eventJoinLinks?: EventJoinLink[]
): [string[], string[], string[]] {
  const pass: string[] = [];
  const fail: string[] = [];
  const duplicate: string[] = [];
  for (const email of emails) {
    const sanitizedEmail = emailIsValid(email);
    const isUnique = emailIsUnique(sanitizedEmail || email, eventJoinLinks);
    if (sanitizedEmail?.length && isUnique) {
      pass.push(sanitizedEmail);
    } else if (!isUnique) {
      duplicate.push(sanitizedEmail || email);
    } else {
      fail.push(email);
    }
  }
  const dedupeEmails = [...new Set(pass)];
  return [dedupeEmails, fail, duplicate];
}

function parseEmailList(emailList: string, eventJoinLinks?: EventJoinLink[]): ParsedEmailList {
  if (!emailList?.length) {
    return { valid: [], invalid: [], duplicate: [] };
  }
  const l = emailList
    .split(/[,;\r\n\t]+/)
    .map((v) => v.trim().toLowerCase())
    .filter((v) => v.length);

  const [valid, invalid, duplicate] = partitionEmails(l, eventJoinLinks);

  return { valid, invalid, duplicate };
}

export interface InviteParticipantsFormValues {
  emailList: string;
  validEmails: string[];
  invalidEmails: string[];
  duplicateEmails: string[];
}

export const useInviteParticipantsForm = () =>
  useForm<InviteParticipantsFormValues>({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      emailList: '',
      validEmails: [],
      invalidEmails: [],
      duplicateEmails: [],
    },
    validate: {
      emailList: (v, values) => {
        // just return the error state, we apply the errors manually
        if (
          !values.invalidEmails.length &&
          !values.duplicateEmails.length &&
          values.validEmails.length
        ) {
          return null;
        }

        return true;
      },
    },
  });

interface InviteParticipantsFormProps {
  inviteParticipantsForm: UseFormReturnType<InviteParticipantsFormValues>;
  eventJoinLinks?: EventJoinLink[];
}

/**
 * A form to add multiple participants to an event by their email address
 *
 * Use `useInviteParticipantsForm` in your component and pass it into this component.
 */
export function InviteParticipantsForm({
  inviteParticipantsForm,
  eventJoinLinks,
}: InviteParticipantsFormProps): JSX.Element {
  const { classes } = useStyles();

  const handleEmailListChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const result = parseEmailList(event.currentTarget.value, eventJoinLinks);
    inviteParticipantsForm.setValues({
      validEmails: result.valid,
      invalidEmails: result.invalid,
      duplicateEmails: result.duplicate,
    });

    // pass on to original onChange handler
    inviteParticipantsForm.getInputProps('emailList').onChange(event);
  };

  return (
    <>
      <Stack spacing={0} style={{ width: '100%' }}>
        <Textarea
          label="Email Addresses"
          autosize
          rows={10}
          maxRows={15}
          placeholder="Add Email Addresses"
          withAsterisk
          style={{ flex: 1 }}
          {...{
            ...inviteParticipantsForm.getInputProps('emailList'),
            onChange: handleEmailListChange,
          }}
        />
        <ul className={classes.helpTextList}>
          {inviteParticipantsForm.values.validEmails.length ? (
            <li className={classes.helpText}>
              <span>{inviteParticipantsForm.values.validEmails.length}</span> participant(s) will be
              added
            </li>
          ) : null}
          {inviteParticipantsForm.values.invalidEmails.length ? (
            <li className={classes.errorText}>
              <span>{inviteParticipantsForm.values.invalidEmails.length}</span> invalid email(s):{' '}
              {inviteParticipantsForm.values.invalidEmails.join(', ')}
            </li>
          ) : null}
          {inviteParticipantsForm.values.duplicateEmails.length ? (
            <li className={classes.errorText}>
              <span>{inviteParticipantsForm.values.duplicateEmails.length}</span> duplicate
              email(s): {inviteParticipantsForm.values.duplicateEmails.join(', ')}
            </li>
          ) : null}
        </ul>
      </Stack>
    </>
  );
}
