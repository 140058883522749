import { FileWithPath } from '@mantine/dropzone';
import Papa, { ParseError } from 'papaparse';

export type CsvParseResult = {
  data: ValidatedRawCsvData[] | undefined;
  headers: string[] | undefined;
  aborted: boolean;
  errors: ParseError[] | undefined;
  delimiter: string;
};

export interface RawCsvData {
  [key: string]: string;
}

export interface ValidatedRawCsvData {
  row: number;
  csvData: RawCsvData;
  isValid: boolean;
  error?: ParseError;
}

function validateData(records: RawCsvData[], errors: ParseError[]): ValidatedRawCsvData[] {
  const validated: ValidatedRawCsvData[] = [];
  for (let i = 0; i < records?.length; i++) {
    const error = errors?.find((e) => e.row === i);
    validated.push({
      row: i,
      csvData: records[i],
      isValid: !error,
      error,
    });
  }

  return validated;
}

/**
 * Loads the CSV content in memory
 */
export async function parseCsvFile(file: FileWithPath): Promise<CsvParseResult> {
  return new Promise((resolve, reject) => {
    Papa.parse<RawCsvData>(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.meta.aborted) {
          reject({
            data: validateData(results.data, results.errors),
            headers: results.meta.fields,
            aborted: results.meta.aborted,
            errors: results.errors,
            delimiter: results.meta.delimiter,
          });
        } else {
          resolve({
            data: validateData(results.data, results.errors),
            headers: results.meta.fields,
            aborted: results.meta.aborted,
            errors: results.errors,
            delimiter: results.meta.delimiter,
          });
        }
      },
    });
  });
}
