import { Anchor, Box, Group, useMantineTheme } from '@mantine/core';
import {
  IconChartPieFilled,
  IconHomeFilled,
  IconLayoutListFilled,
  IconSettingsFilled,
  IconUserFilled,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import styles from './footer.module.css';
import {
  linkToEvent,
  linkToEventAnalysis,
  linkToManageDetails,
  linkToManageItems,
  linkToManagePeople,
} from '../../../../utils/event-urls';

interface FooterNavProps {
  eventId: string;
  isSample: boolean;
  activeNavLink: string;
}

export function EventFooterNavigation({ eventId, isSample, activeNavLink }: FooterNavProps) {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const navLinks = [
    {
      label: 'Overview',
      value: linkToEvent(eventId, isSample),
      icon: <IconHomeFilled color={theme.colors.gray[5]} />,
    },
    {
      label: 'Analysis',
      value: linkToEventAnalysis(eventId, isSample),
      icon: <IconChartPieFilled color={theme.colors.gray[5]} />,
    },
    {
      label: 'Details',
      value: linkToManageDetails(eventId, isSample),
      icon: <IconSettingsFilled color={theme.colors.gray[5]} />,
    },
    {
      label: 'Items',
      value: linkToManageItems(eventId, isSample),
      icon: <IconLayoutListFilled color={theme.colors.gray[5]} />,
    },
    {
      label: 'People',
      value: linkToManagePeople(eventId, isSample),
      icon: <IconUserFilled color={theme.colors.gray[5]} />,
    },
  ];

  return (
    <Group ml={3}>
      {navLinks.map((navLink) => (
        <Anchor
          key={navLink.label}
          className={navLink.value === activeNavLink ? styles.navitemActive : styles.navitem}
          component="button"
          type="button"
          mt={7}
          mb={4}
          onClick={() => navigate(`${navLink.value}`)}
        >
          {navLink.icon}
          <Box display={'inline'}>{navLink.label}</Box>
        </Anchor>
      ))}
    </Group>
  );
}
