import { Text, Box, Flex, Anchor, Title, useMantineTheme } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
export const LoginVideos = () => {
  const theme = useMantineTheme();
  return (
    <Flex py={100} wrap="wrap" sx={(theme) => ({ background: theme.colors.sky[0] })}>
      <Box px={50} sx={() => ({ margin: '0 auto' })}>
        <iframe
          title="Why CoFund?"
          src="//play.vidyard.com/4KePHu6ecUMeVKoSstToDV.html?"
          width="100%"
          height="360"
        ></iframe>
      </Box>
      <Box px={50} sx={() => ({ flex: 1, alignSelf: 'center' })}>
        <Box>
          <Title order={3} fw={800} pb="sm" sx={(theme) => ({ color: theme.colors.teal[4] })}>
            Sign in with SAFe Studio today!
          </Title>
          <Text size="lg" pt="xs">
            All SAFe Studio members have access to create pre-configured demo events in SAFe CoFund.
          </Text>
          <Text size="lg" pt="sm">
            <span style={{ fontWeight: 600 }}>
              Don&apos;t have SAFe Studio, or looking to create custom events with your own data?
            </span>
            <Text pt="sm">
              Contact Us at{' '}
              <span>
                <Anchor
                  sx={(theme) => ({ color: theme.colors.teal[4] })}
                  href="https://scaledagile.com/cofund/#:~:text=Contact%20us%20to%20get%20CoFund%20today"
                  target="_blank"
                >
                  <span style={{ fontWeight: 800, fontSize: 20 }}>
                    scaledagile.com/cofund
                    <span>
                      <IconExternalLink
                        size={23}
                        style={{
                          position: 'relative',
                          left: 3,
                          top: 4,
                          color: theme.colors.teal[4],
                        }}
                      />
                    </span>
                  </span>
                </Anchor>
              </span>
            </Text>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};
