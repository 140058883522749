import {
  Button,
  SimpleGrid,
  Stack,
  Text,
  em,
  getBreakpointValue,
  useMantineTheme,
} from '@mantine/core';
import { modals, openContextModal } from '@mantine/modals';
import { IconChartHistogram, IconHomeStats, IconTruckDelivery } from '@tabler/icons-react';
import { useDefaultEvent, useTTCDemoData, useRenoDemoData } from '../../../hooks/useDemoData';
import { useCallback, useState } from 'react';

import styles from './eventModal.module.css';
import { canCreateCustomEvent, canCreateDemoEvent } from '../../../utils/auth-helpers';
import { useAuthContext } from '../../../hooks/useAuth';
import { EventCreationCard } from './components/EventCreationCard';

export const newEventModalKey = 'newEvent';

export enum EventType {
  CUSTOM = 'Custom Event',
  TTC = 'TTC Demo Event',
  RENOVATION = 'Renovation Demo Event',
}

export type EventTemplateType = {
  id: EventType;
  name: string;
  icon: React.ReactNode;
};

const eventTypes = [
  {
    id: EventType.TTC,
    name: 'Terrific Transport Corp Demo Event',
    icon: <IconTruckDelivery size={80} stroke={1} />,
  },
  {
    id: EventType.RENOVATION,
    name: 'Home Renovation Demo Event',
    icon: <IconHomeStats size={75} stroke={1} />,
  },
  {
    id: EventType.CUSTOM,
    name: 'Custom Event',
    icon: <IconChartHistogram size={75} stroke={1} />,
  },
];

export function openNewEventModal() {
  return openContextModal({
    modal: newEventModalKey,
    title: 'Create New Event',
    innerProps: {},
    size: 'xl',
  });
}

export function NewEventModal(): JSX.Element {
  const theme = useMantineTheme();
  const [selectedEvent, setSelectedEvent] = useState<string>();

  const createTTCDemoEvent = useTTCDemoData();
  const createRenoEvent = useRenoDemoData();
  const createEvent = useDefaultEvent();
  const { user } = useAuthContext();

  const onCreateEvent = useCallback(async () => {
    switch (true) {
      case selectedEvent === EventType.CUSTOM:
        await createEvent();
        modals.closeAll();
        break;
      case selectedEvent === EventType.TTC:
        await createTTCDemoEvent();
        modals.closeAll();
        break;
      case selectedEvent === EventType.RENOVATION:
        await createRenoEvent();
        modals.closeAll();
        break;
      default:
        break;
    }
  }, [selectedEvent, createEvent, createTTCDemoEvent, createRenoEvent]);

  const checkEventEnabled = (eventType: EventType) => {
    switch (true) {
      case eventType === EventType.CUSTOM:
        return canCreateCustomEvent(user?.roles);
      case eventType === EventType.TTC:
        return canCreateDemoEvent(user?.roles);
      case eventType === EventType.RENOVATION:
        return canCreateDemoEvent(user?.roles);
      default:
        break;
    }
  };

  return (
    <>
      <Stack spacing="sm" py="lg">
        <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'md', cols: 1, spacing: 'sm' }]}>
          {eventTypes.map((event) => (
            <EventCreationCard
              key={event.id}
              event={event}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              enabled={checkEventEnabled(event.id)}
            />
          ))}
        </SimpleGrid>
        <Button
          my="xl"
          h={50}
          radius="md"
          className={!selectedEvent ? styles.disabled : styles.enabled}
          onClick={onCreateEvent}
        >
          <Text size="lg">Create Event</Text>
        </Button>
      </Stack>
    </>
  );
}
