import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { GroupMemberApi } from './api';
import { PatchGroupMember, PostGroupMember, GroupMember } from '../models/groupMember.interface';
import { QueryKeysMap } from '../models/keys.type';
import { useAuthContext } from '../hooks/useAuth';
import { useMemo } from 'react';
import { useGroupsByEventIdQuery } from './group.queries';

type Filters = {
  groupId?: string;
};

type GroupMemberResultEntry = {
  [key: string]: {
    isMember: boolean;
    isFetched: boolean;
  };
};

export type GroupBudgetAndMembersEntry = {
  id?: string;
  memberBudget?: number;
  totalMembers: number;
  allocatedBudget: number;
};

type TotalGroupMembersEntry = {
  groupId: string;
  totalMembers?: number;
};

export type GroupMemberResult = {
  allFetched: boolean;
  memberGroupId: string | undefined;
  groupResult: GroupMemberResultEntry;
};

export type GroupBudgetAndMembersResult = {
  allFetched: boolean;
  groupResult: GroupBudgetAndMembersEntry[];
};

export type TotalGroupMembersResult = {
  allFetched: boolean;
  groupResult: TotalGroupMembersEntry[];
};

export const groupMemberKeys: QueryKeysMap<'groupMembers', Filters> = {
  all: ['groupMembers'] as const,
  list: () => [...groupMemberKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...groupMemberKeys.list(), { ...filters }],
  detail: () => [...groupMemberKeys.all, 'detail'] as const,
  byId: (id: string) => [...groupMemberKeys.detail(), id] as const,
};

export function useGroupMembersByGroupIdsQuery(groupIds?: string[]) {
  const queriesArr = groupIds?.map((groupId) => ({
    queryKey: groupMemberKeys.filter({ groupId: groupId }),
    queryFn: () => GroupMemberApi.getAll(groupId),
  }));
  return useQueries({
    queries: queriesArr || [],
  });
}

export function useTotalMembersByGroup(groupIds?: string[]) {
  const groupMembersQueries = useGroupMembersByGroupIdsQuery(groupIds);

  const groupMemberResult: TotalGroupMembersResult = useMemo(() => {
    let groupResult: TotalGroupMembersEntry[] = [];
    let allFetched = true;
    if (groupIds?.length) {
      for (let i = 0; i < groupIds.length; i++) {
        const groupId = groupIds[i];
        const query = groupMembersQueries?.[i];

        groupResult = [
          ...groupResult,
          {
            groupId,
            totalMembers: query.data?.length,
          },
        ];
        allFetched = allFetched && query.isFetched;
      }
    } else {
      allFetched = false;
    }

    return {
      allFetched: allFetched,
      groupResult: groupResult,
    };
  }, [groupIds, groupMembersQueries]);

  return groupMemberResult;
}

/**
 * This query fetches all memberships from all groups and iterates over them.
 * It checks if the current user is a member in any of these groups.
 * @param groupIds list of groups to query membership
 * @param userIdOverride add this to override the current user
 */
export function useGroupMembershipsQuery(
  groupIds?: string[],
  userIdOverride?: string
): GroupMemberResult {
  const { user } = useAuthContext();

  // tanstack guarantees that "the order returned is the same as the input order."
  // There seems to be no other connector between the queryKey and the returned QueryResult.
  // https://tanstack.com/query/v4/docs/react/reference/useQueries
  const groupMembersQueries = useGroupMembersByGroupIdsQuery(groupIds);

  const groupMemberResult: GroupMemberResult = useMemo(() => {
    const groupResult: GroupMemberResultEntry = {};
    let allFetched = true;
    let memberGroupId = undefined;
    if (groupIds?.length) {
      for (let i = 0; i < groupIds.length; i++) {
        const groupId = groupIds[i];
        const query = groupMembersQueries?.[i];
        const isMember = query.data?.some((m) => {
          if (userIdOverride) {
            return m.userId === userIdOverride && m.isMember;
          } else {
            return m.userId === user?.id && m.isMember;
          }
        });
        if (isMember) {
          memberGroupId = groupId;
        }
        groupResult[groupId] = {
          isMember: isMember ?? false,
          isFetched: query.isFetched,
        };
        allFetched = allFetched && query.isFetched;
      }
    } else {
      allFetched = false;
    }

    return {
      allFetched: allFetched,
      memberGroupId,
      groupResult,
    };
  }, [groupIds, groupMembersQueries, user]);

  return groupMemberResult;
}

export function useGroupBudgetAndMembers(eventId: string, groupIds?: string[]) {
  const totalMembersByGroup = useTotalMembersByGroup(groupIds);
  const { data: queryEvent } = useGroupsByEventIdQuery(eventId);

  const groupMemberResult: GroupBudgetAndMembersResult = useMemo(() => {
    let groupResult: GroupBudgetAndMembersEntry[] = [];
    let allFetched = true;
    if (groupIds?.length && totalMembersByGroup.allFetched) {
      for (let i = 0; i < groupIds.length; i++) {
        const eventQuery = queryEvent?.[i];
        const budgetBid = totalMembersByGroup.groupResult.find(
          (groupMembers: TotalGroupMembersEntry) => eventQuery?.id === groupMembers.groupId
        );

        const totalMembers = budgetBid?.totalMembers ? budgetBid?.totalMembers : 0;
        groupResult = [
          ...groupResult,
          {
            id: eventQuery?.id,
            memberBudget: eventQuery?.memberBudget,
            totalMembers: totalMembers,
            allocatedBudget: budgetBid && eventQuery ? totalMembers * eventQuery?.memberBudget : 0,
          },
        ];
      }
    } else {
      allFetched = false;
    }
    return {
      allFetched: allFetched,
      groupResult,
    };
  }, [groupIds, totalMembersByGroup, queryEvent]);
  return groupMemberResult;
}

export function useGroupMembersByGroupIdQuery(groupId: string | undefined) {
  return useQuery({
    queryKey: groupMemberKeys.filter({ groupId: groupId }),
    queryFn: () => GroupMemberApi.getAll(groupId || 'undefined'),
    enabled: !!groupId,
  });
}

export function useAddGroupMemberMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ groupId, groupMember }: { groupId: string; groupMember: PostGroupMember }) =>
      GroupMemberApi.addMemberToGroup(groupId, groupMember),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: groupMemberKeys.all }),
  });
}

export function usePatchGroupMemberMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ groupId, groupMember }: { groupId: string; groupMember: PatchGroupMember }) =>
      GroupMemberApi.patchMemberToGroup(groupId, groupMember),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: groupMemberKeys.all }),
  });
}

export function useDeleteGroupMemberMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ groupId, member }: { groupId: string; member: GroupMember }) =>
      GroupMemberApi.deleteGroupMember(groupId, member),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: groupMemberKeys.all });
    },
  });
}
