import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from '../hooks/useAuth';
import { BiddingItem } from '../models/bidding-item.interface';
import { ItemType } from '../models/itemType.interface';
import { QueryKeysMap } from '../models/keys.type';
import { PatchEvent, PostEvent } from '../models/event.interface';
import { StrategicTheme } from '../models/strategicTheme.interface';
import { ValueStream } from '../models/valueStream.interface';
import {
  DerivedQueriesResult,
  MapUseQueryResult,
  useDerivedQueries,
} from '../utils/combine.queries';
import { EventApi } from './api';
import { useBiddingItemsByEventIdQuery } from './bidding-item.queries';
import { useItemTypesByEventIdQuery } from './itemType.queries';
import { useStrategicThemesByEventIdQuery } from './strategicTheme.queries';
import { useValueStreamsByEventIdQuery } from './valueStream.queries';
import { isAxiosError } from 'axios';
import { HTTP_STATUS_TO_NOT_RETRY, MAX_RETRIES } from '../constants/api';

type Filters = {
  // filter is obsolete, but the current QueryKeysMap type needs a filter func.
  createdByUserId?: string;
  joined?: boolean;
};

export const eventsKeys: QueryKeysMap<'events', Filters> = {
  all: ['events'] as const, // all helps with fuzzy matching invalidation of cache
  list: () => [...eventsKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...eventsKeys.list(), { ...filters }] as const,
  detail: () => [...eventsKeys.all, 'detail'] as const,
  byId: (eventId: string) => [...eventsKeys.detail(), eventId] as const,
};

/**
 * GetEventsTypes defines the data types that are the result of the queries passed into `useDerivedQueries`
 * These should be singular and not including the array type (added generically)
 */
type GetEventsTypes = [BiddingItem, ItemType, ValueStream, StrategicTheme];

/**
 * EventItemType is the data type of one item that is returned from the combine function passed into `useDerivedQueries`.
 * Also use a singular type and do not include the array type (added generically)
 */
export interface ManagedBiddingItem extends BiddingItem {
  itemTypeName?: string;
  valueStreamNames?: string[];
  strategicThemeNames?: string[];
  bidAmount?: number;
  bidPercent?: number;
  isFlagged?: boolean;
}

export function useGetEventItems(eventId: string): DerivedQueriesResult<ManagedBiddingItem> {
  const queries: MapUseQueryResult<GetEventsTypes> = [
    useBiddingItemsByEventIdQuery(eventId),
    useItemTypesByEventIdQuery(eventId),
    useValueStreamsByEventIdQuery(eventId),
    useStrategicThemesByEventIdQuery(eventId),
  ];

  const queryResult = useDerivedQueries<GetEventsTypes, ManagedBiddingItem>(
    queries,
    ([budgetItems, itemTypes, valueStreams, strategicThemes]) => {
      const eventItems =
        budgetItems && itemTypes && valueStreams && strategicThemes
          ? budgetItems.map((budgetItem: BiddingItem) => ({
              ...budgetItem,
              itemTypeName: itemTypes.find(
                (itemType: ItemType) => budgetItem.itemTypeId === itemType.id
              )?.value,
              valueStreamNames: valueStreams
                .filter((vs) => budgetItem.valueStreamIds.includes(vs.id))
                .map((vs) => vs.value),
              strategicThemeNames: strategicThemes
                .filter((st) => budgetItem.strategicThemeIds.includes(st.id))
                .map((st) => st.value),
            }))
          : [];
      return eventItems;
    }
  );

  return queryResult;
}

export function useMyEventsQuery() {
  return useQuery({
    queryKey: eventsKeys.list(),
    queryFn: () => EventApi.getAllForUser(),
  });
}

export function useMyJoinedEventsQuery() {
  return useQuery({
    queryKey: eventsKeys.filter({ joined: true }),
    queryFn: () => EventApi.getJoinedForUser(),
  });
}

export function useEventByIdQuery(id: string) {
  const { user } = useAuthContext();
  const userId = user?.id;

  return useQuery({
    queryKey: eventsKeys.byId(id),
    queryFn: () => EventApi.getDetails(id),
    enabled: !!userId && !!id,
    retry: (failureCount, error) => {
      if (failureCount > MAX_RETRIES) {
        return false;
      }

      if (isAxiosError(error) && HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)) {
        console.log(`Aborting retry due to ${error.response?.status} status`);
        return false;
      }
      return true;
    },
  });
}

export function useCreateEventMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (event: PostEvent) => EventApi.createEvent(event),
    // refetch on success
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: eventsKeys.list() });
    },
  });
}

export function useUpdateEventMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (event: PatchEvent) => {
      return EventApi.updateEvent(event);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: eventsKeys.all });
    },
  });
}

export function useDeleteEventMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (eventId: string) => EventApi.deleteEvent(eventId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: eventsKeys.all });
    },
  });
}
