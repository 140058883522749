import { useEventByIdQuery } from '../../../../api/event.queries';
import { useRequiredParam } from '../../../../hooks/useRequiredParam';
import { strategicThemeName } from '../../../../constants/formDefaults';

export function CustomStrategicThemeLabel() {
  const eventId = useRequiredParam('eventId');
  const { data: event } = useEventByIdQuery(eventId);

  return (
    <>
      {event?.customLabelStrategicThemes ? (
        <span>{event.customLabelStrategicThemes}</span>
      ) : (
        <span>{strategicThemeName}</span>
      )}
    </>
  );
}
