import { useEffect, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';

/**
 * Debounces a function call.
 * The callback should read the latest state to ensure correct debouncing behavior.
 *
 * For more information, see
 * https://www.developerway.com/posts/debouncing-in-react
 * @param millis debounce dely in milliseconds
 * @param callback the function to debounce
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const useDebounce = (millis: number, callback: Function) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const ref = useRef<Function>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, millis);
  }, [millis]);

  return debouncedCallback;
};
