import { Anchor, Box, Flex, useMantineTheme } from '@mantine/core';
import {
  IconBrandDeezer,
  IconChartPieFilled,
  IconHomeFilled,
  IconReplaceFilled,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import styles from './footer.module.css';
import { linkToEvent } from '../../../../utils/event-urls';
import {
  linkToBidding,
  linkToGroupAnalysis,
  linkToGroupDetails,
} from '../../../../utils/group-urls';

interface GroupFooterNavProps {
  eventId: string;
  groupId: string;
  isSample: boolean;
  activeNavLink: string;
}

export function GroupFooterNavigation({
  activeNavLink,
  eventId,
  groupId,
  isSample,
}: GroupFooterNavProps) {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const navLinks = [
    {
      label: 'Bidding',
      value: linkToBidding(eventId, groupId, isSample),
      icon: <IconReplaceFilled color={theme.colors.gray[5]} />,
    },
    {
      label: 'Details',
      value: linkToGroupDetails(eventId, groupId, isSample),
      icon: <IconBrandDeezer color={theme.colors.gray[5]} stroke={2} size={34} />,
    },
    {
      label: 'Analysis',
      value: linkToGroupAnalysis(eventId, groupId, isSample),
      icon: <IconChartPieFilled color={theme.colors.gray[5]} />,
    },
  ];

  return (
    <Flex ml="xs" gap={0}>
      <Anchor
        className={styles.navitem}
        component="button"
        type="button"
        mt="xs"
        style={{ marginRight: 'auto' }}
        onClick={() => navigate(linkToEvent(eventId, isSample))}
      >
        <IconHomeFilled size={25} color={theme.colors.gray[5]} />
      </Anchor>
      {navLinks.map((navLink) => (
        <Anchor
          key={navLink.label}
          className={navLink.value === activeNavLink ? styles.navitemActive : styles.navitem}
          component="button"
          type="button"
          mt={7}
          mb={4}
          style={{ marginLeft: 'auto' }}
          onClick={() => navigate(`${navLink.value}`)}
        >
          {navLink.icon}
          <Box display={'inline'}>{navLink.label}</Box>
        </Anchor>
      ))}
    </Flex>
  );
}
