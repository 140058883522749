import { Alert, Anchor, Center, Flex, Group, Text } from '@mantine/core';
import axios, { AxiosError } from 'axios';
import { IconAlertCircle } from '@tabler/icons-react';
import { generateErrorMessage } from './generateErrorMessage';

export type FormAlertProps = {
  // user readable error message that relates to the context, i.e. 'Login failed'
  // if you do not provide this param, the alert will only a single line with the error
  title?: string;

  // the error object, usually coming from a failed promise (i.e. Axios query)
  // type unknown is added for compatibility with useQuery, we do runtime checks to infer the type
  // The component is not rendered when error is null/undefined.
  error?: Error | AxiosError | unknown;
  // If there is interal or external link the error should route to
  link?: React.ReactNode;

  // if defined, show this message for any 400 - 499 response (instead of axios error.message)
  message4xx?: string;

  // if defined, show this message for any 500 - 599 response (instead of axios error.message)
  message5xx?: string;

  // if defined, show the message for the given http status code
  // messageForCode will always take precedence over other custom errors (such as `error4xx`)
  messageForCode?: Record<number, string>;

  // if true, show this alert even if error is falsy
  showWithoutError?: boolean;
};

/**
 * Shows errors in the context of a form.
 * Use it to show errors returned from an API call, or form-errors that do not relate to a single field.
 * (i.e. a combination of values is invalid)
 * The component is not rendered when error is null/undefined.
 */
export function FormAlert(props: FormAlertProps): JSX.Element {
  const { error, title, link } = props;

  const showError: boolean = props.showWithoutError || (error !== undefined && error !== null);
  const message: string = generateErrorMessage(error, props);

  return showError ? (
    <Alert color="error">
      <Center>
        <Group>
          <IconAlertCircle size="1rem" />
          <Text size="md" weight={800} ml={-15}>
            {title}
          </Text>
        </Group>
      </Center>

      {link}
      <Text mt="sm" align="center" fw={500}>
        {message}
      </Text>
      <Flex mt="sm">
        <Text size="xs" align="center" fw={800} pr={5}>
          If the issue persists, please reach out to
        </Text>
        <Anchor
          href={'https://support.scaledagile.com'}
          target="_blank"
          sx={(theme) => ({ color: theme.colors.teal[1] })}
        >
          <Text size="xs" fw={800} align="center">
            https://support.scaledagile.com
          </Text>
        </Anchor>
      </Flex>
    </Alert>
  ) : (
    <></>
  );
}
