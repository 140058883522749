import { Anchor, Text } from '@mantine/core';
import { Link, Params } from 'react-router-dom';
import { useGroupByIdQuery } from '../../../api/group.queries';
import { useRequiredParam } from '../../../hooks/useRequiredParam';
import { linkToEventBidding } from '../../../utils/event-urls';
import { useEventByIdQuery } from '../../../api/event.queries';

interface GroupCrumbProps {
  isLast: boolean;
  params?: Params<string>;
}

export function GroupCrumb({ isLast }: GroupCrumbProps) {
  const eventId = useRequiredParam('eventId');
  const groupId = useRequiredParam('groupId');
  const { data: group } = useGroupByIdQuery(groupId);
  const { data: event } = useEventByIdQuery(eventId);
  return group?.name ? (
    isLast ? (
      <Text>{group.name}</Text>
    ) : (
      <Anchor component={Link} to={linkToEventBidding(eventId, groupId, event?.isSample)}>
        {group.name}
      </Anchor>
    )
  ) : null;
}
