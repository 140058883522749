import { ReactNode, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { JoinEventResult, useJoinEventMemberMutation } from '../../../../api/eventMember.queries';
import { OverlayLoader } from '../../../../components/loaders/overlay-loader/OverlayLoader';
import { useAuthContext } from '../../../../hooks/useAuth';
import { useRequiredParam } from '../../../../hooks/useRequiredParam';
import { Alert, Anchor, Box, Center, Container, Group, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { generateErrorMessage } from '../../../../components/form/form-alert/generateErrorMessage';
import { FormAlert } from '../../../../components/form/form-alert/FormAlert';

interface RequireEventMemberProps {
  children: ReactNode;
}

// This component ensures that the user is joined to the event,
// before rendering any other child components.
export function RequireEventMember({ children }: RequireEventMemberProps): JSX.Element {
  const eventId = useRequiredParam('eventId');
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get('invite') || undefined;
  const { user } = useAuthContext();
  // prevent React strict mode from firing the mutation twice
  const currentRun = useRef('');
  const [joinResult, setJoinResult] = useState<JoinEventResult>();
  const [joinError, setJoinError] = useState<unknown>();

  const { mutateAsync: join } = useJoinEventMemberMutation();

  useEffect(() => {
    async function runMutate(userId: string) {
      join({
        eventId,
        userId: userId,
        inviteId,
      })
        .then((result) => {
          setJoinResult(result);
        })
        .catch((reason: unknown) => {
          setJoinError(reason);
        });
    }

    if (eventId && user?.id && currentRun.current !== `${eventId}-${user.id}`) {
      currentRun.current = `${eventId}-${user.id}`;
      runMutate(user.id);
    }
  }, [join, eventId, inviteId, user?.id]);

  // on correct access, pass thru of content
  if (joinResult !== undefined && joinResult.member !== undefined) {
    return children as JSX.Element;
  }

  // if event does not exist or join prohibited, show styled error
  if (joinResult !== undefined && !joinResult.canJoin) {
    return (
      <Center mt="100px">
        <FormAlert
          title="This event has been restricted or deleted."
          showWithoutError={true}
          link={
            <Center>
              <Anchor component={Link} to={'/'} sx={(theme) => ({ color: theme.colors.teal[1] })}>
                <Text size="sm" fw={800} align="center">
                  Return to Event List
                </Text>
              </Anchor>
            </Center>
          }
        />
      </Center>
    );
  }
  // on any kind of unexpected error, show this message
  if (joinError !== undefined) {
    const message: string = generateErrorMessage(joinError, {});
    return (
      <Center mt={300}>
        <Box w={500}>
          <Alert color="error">
            <Center>
              <Group>
                <IconAlertCircle size="1rem" />
                <Text size="md" weight={800} ml={-15}>
                  Failed to load event
                </Text>
              </Group>
            </Center>

            <Center>
              <Anchor component={Link} to={'/'} sx={(theme) => ({ color: theme.colors.teal[1] })}>
                <Text size="sm" fw={800} align="center">
                  Return to Event List
                </Text>
              </Anchor>
            </Center>
            <Text mt="sm" align="center" fw={500}>
              {message}
            </Text>
          </Alert>
        </Box>
      </Center>
    );
  }

  // default return is loader for fetching data
  return <OverlayLoader title="Joining Event" />;
}
