import { createBrowserRouter, Navigate, Params } from 'react-router-dom';

import Layout from './Layout';

import { RequireAuth } from '../components/RequireAuth';
import { DefaultErrorComponent } from '../components/error-boundary/DefaultErrorComponent';
import Login from './login/Login';
import { EventCrumb } from '../components/layout/components/EventCrumb';
import { GroupCrumb } from '../components/layout/components/GroupCrumb';
import { TextCrumb } from '../components/layout/components/TextCrumb';
import { EventMemberRole } from '../models/eventMember.interface';
import { RequireEventMember } from './events/components/event-member/RequireEventMember';
import { RequireEventRole } from './events/components/event-role/RequireEventRole';
import { RequireGroupMemberOrFacilitator } from './group/bidding/components/RequireGroupMemberOrFacilitator';
import { RequireEventSubscription } from '../components/subscriptions/RequireEventSubscription';
import OAuthPassThrough from '../components/form/login/OAuthPassThrough';

/**
 * Declares all frontend routes
 */
export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        /* We introduce this extra layer of children so that the error element is shown within the Layout component */
        errorElement: <DefaultErrorComponent />,
        children: [
          {
            index: true,
            element: <Navigate to="/events" replace />,
          },
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'oidc/:provider/callback',
            element: <OAuthPassThrough />,
          },
          {
            path: '/events',
            async lazy() {
              const { EventList } = await import('./events/EventList');
              return {
                element: (
                  <RequireAuth>
                    <EventList />
                  </RequireAuth>
                ),
              };
            },
          },
          {
            path: '/account-management',
            async lazy() {
              const { AccountManagement } = await import('./account/AccountManagement');
              return {
                element: (
                  <RequireAuth>
                    <AccountManagement />
                  </RequireAuth>
                ),
              };
            },
          },
          {
            path: '/guest-terms-use',
            async lazy() {
              const { GuestTermsUse } = await import('./login/GuestTermsUse');
              return {
                element: <GuestTermsUse />,
              };
            },
          },
          {
            path: 'events/:eventId',
            async lazy() {
              const { Event } = await import('./events/Event');
              return {
                element: (
                  <RequireAuth>
                    <RequireEventMember>
                      <RequireEventSubscription>
                        <Event />
                      </RequireEventSubscription>
                    </RequireEventMember>
                  </RequireAuth>
                ),
              };
            },
            handle: {
              crumb: (isLast: boolean, params: Params<string>) => (
                <EventCrumb isLast={isLast} params={params} />
              ),
            },
            children: [
              {
                index: true,
                async lazy() {
                  const { EventOverview } = await import('./events/EventOverview');
                  return {
                    element: <EventOverview />,
                  };
                },
              },
              {
                path: 'details',
                async lazy() {
                  const { ManageDetails } = await import('./events/ManageDetails');
                  return {
                    element: (
                      <RequireEventRole role={EventMemberRole.Facilitator}>
                        <ManageDetails />
                      </RequireEventRole>
                    ),
                  };
                },
                handle: {
                  crumb: () => <TextCrumb label="Event Details" />,
                },
              },
              {
                path: 'items',
                async lazy() {
                  const { ManageItems } = await import('./events/ManageItems');
                  return {
                    element: (
                      <RequireEventRole role={EventMemberRole.Facilitator}>
                        <ManageItems />
                      </RequireEventRole>
                    ),
                  };
                },
                handle: {
                  crumb: () => <TextCrumb label="Items" />,
                },
              },
              {
                path: 'people',
                async lazy() {
                  const { PeopleContainer } = await import(
                    './events/manage-people/PeopleContainer'
                  );
                  return {
                    element: (
                      <RequireEventRole role={EventMemberRole.Facilitator}>
                        <PeopleContainer />
                      </RequireEventRole>
                    ),
                  };
                },
                handle: {
                  crumb: () => <TextCrumb label="People" />,
                },
              },
              {
                path: 'analysis',
                async lazy() {
                  const { EventAnalysis } = await import('./analysis/EventAnalysis');
                  return {
                    element: (
                      <RequireEventRole role={EventMemberRole.Facilitator}>
                        <EventAnalysis />
                      </RequireEventRole>
                    ),
                  };
                },
                handle: {
                  crumb: () => <TextCrumb label="Analysis" />,
                },
              },
              {
                path: 'groups/:groupId',
                async lazy() {
                  const { Group } = await import('./group/Group');
                  return {
                    element: (
                      <RequireGroupMemberOrFacilitator>
                        <Group />
                      </RequireGroupMemberOrFacilitator>
                    ),
                  };
                },
                handle: {
                  crumb: (isLast: boolean, params: Params<string>) => (
                    <GroupCrumb isLast={isLast} params={params} />
                  ),
                },
                children: [
                  {
                    index: true,
                    async lazy() {
                      const { Bidding } = await import('./group/bidding/Bidding');
                      return {
                        element: <Bidding />,
                      };
                    },
                  },
                  {
                    path: 'details',
                    async lazy() {
                      const { GroupDetails } = await import('./group/details/GroupDetails');
                      return {
                        element: <GroupDetails />,
                      };
                    },
                  },
                  {
                    path: 'analysis',
                    async lazy() {
                      const { GroupAnalysis } = await import('./group/analysis/GroupAnalysis');
                      return {
                        element: <GroupAnalysis />,
                      };
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
