import { Center, Loader } from '@mantine/core';
import { useIsFetching } from '@tanstack/react-query';

export function DefaultLoadingComponent() {
  const isFetching = useIsFetching();
  return isFetching ? (
    <Center>
      <Loader data-testid="loading" />
    </Center>
  ) : null;
}
