import { Button, Dialog, Text, Group } from '@mantine/core';
import { useCallback } from 'react';

export type AppRefreshDialogProps = {
  opened: boolean;
};

// dialog that is shown when an app refresh is required
export const AppRefreshDialog = ({ opened }: AppRefreshDialogProps): JSX.Element => {
  const onClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Dialog opened={opened} size="lg" radius="md">
      <Text size="sm" mb="xs" fw={600}>
        Update detected!
      </Text>

      <Group align="flex-end">
        <Text size="sm" style={{ flex: 1 }}>
          There is a new version of CoFund available.
        </Text>
        <Button onClick={onClick}>Please Refresh</Button>
      </Group>
    </Dialog>
  );
};
