/* Framework */
import {
  Anchor,
  Badge,
  Box,
  Burger,
  Divider,
  Flex,
  Group,
  Header,
  Image,
  Portal,
  SimpleGrid,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';
/* Internal */
//Data
import { UserDataFromJwt } from '../../hooks/useAuth';
// Components
// Styling
import { useHeadroom } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import cofundLogo from '../../assets/images/svg/cofund_logo.svg';
import studioLogo from '../../assets/images/svg/studio_logo.svg';
import { isGuestUser } from '../../utils/auth-helpers';
import { userAvatarUrl } from '../../utils/avatar-helpers';
import { DefaultLoadingComponent } from '../loaders/DefaultLoadingComponent';
import { UserProfile } from './components/user-profile/UserProfile';
import { useStyles } from './layoutStyles';
import { useGetScreenSize } from '../../hooks/useResponsiveDevices';

interface HeaderProps {
  opened: boolean;
  user: UserDataFromJwt | null;
  setOpened: Dispatch<SetStateAction<boolean>>;
}

const AppHeader = ({ opened, user, setOpened }: HeaderProps) => {
  const { classes } = useStyles();
  const pinned = useHeadroom({ fixedAt: 120 });
  const { smallScreen } = useGetScreenSize();
  const theme = useMantineTheme();

  return (
    <Portal>
      <Box
        sx={(theme) => ({
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          padding: theme.spacing.xs,
          height: rem(50),
          zIndex: 99,
          transform: `translate3d(0, ${pinned ? 0 : rem(-60)}, 0)`,
          transition: 'transform 180ms ease-out',
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        })}
      >
        <Header height={50} px="xs" className={classes.appHeader}>
          <SimpleGrid cols={2}>
            <Flex>
              <Burger
                opened={opened}
                onClick={() => setOpened(!opened)}
                hidden={!smallScreen}
                mt="xs"
                size="md"
              />
              <Anchor hidden={smallScreen} href="https://safe.scaledagile.com/">
                <Image src={studioLogo} width={200} />
              </Anchor>
              <Divider
                hidden={smallScreen}
                orientation="vertical"
                size="sm"
                mt={7}
                mb={7}
              ></Divider>
              <Group mt={2} miw={300} position={smallScreen ? 'center' : 'left'}>
                <Link to="/">
                  <Image src={cofundLogo} width={75} ml="sm" />
                </Link>
                {user && isGuestUser(user) ? (
                  <Badge size="md" style={{ background: theme.colors.sky[3] }}>
                    <Text style={{ color: theme.colors.gray[0] }} weight={800}>
                      Guest
                    </Text>
                  </Badge>
                ) : null}
              </Group>
              <DefaultLoadingComponent />
            </Flex>
            {user && !smallScreen ? (
              <>
                <UserProfile
                  image={userAvatarUrl(
                    user.firstName && user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : user.userName
                  )}
                  userId={user.id}
                  name={
                    user.firstName && user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : user.userName
                  }
                  isGuest={isGuestUser(user)}
                />
              </>
            ) : null}
          </SimpleGrid>
        </Header>
      </Box>
    </Portal>
  );
};

export default AppHeader;
