import { UseQueryResult } from '@tanstack/react-query';

export interface DerivedQueriesResult<T> {
  isLoading: boolean;
  isError: boolean;
  data: Array<T>;
}

/**
 * Maps the base type array of the underlying query data type into the proper type,
 * for instance: UseQueryResult<BiddingItem[]>
 */
export type MapUseQueryResult<T> = {
  [Property in keyof T]: UseQueryResult<Array<T[Property]>> | DerivedQueriesResult<T[Property]>;
};

export type CombineParamType<T> = {
  [Property in keyof T]: Array<T[Property]>;
};

export function useDerivedQueries<QUERY_TYPES extends Array<unknown>, RESULT>(
  queries: MapUseQueryResult<QUERY_TYPES>,
  combine: (q: CombineParamType<QUERY_TYPES>) => Array<RESULT>
): DerivedQueriesResult<RESULT> {
  return {
    isLoading: queries.some((query) => query.isLoading),
    isError: queries.some((query) => query.isError),
    data: combine(
      queries.map((query) => (query.data ? query.data : [])) as CombineParamType<QUERY_TYPES>
    ),
  };
}
