import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { ItemType, PostItemType } from '../models/itemType.interface';
import { QueryKeysMap } from '../models/keys.type';
import { ItemTypeApi } from './api';

type Filters = {
  eventId?: string;
};

export const itemTypeKeys: QueryKeysMap<'itemTypes', Filters> = {
  all: ['itemTypes'] as const,
  list: () => [...itemTypeKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...itemTypeKeys.list(), { ...filters }],
  detail: () => [...itemTypeKeys.all, 'detail'] as const,
  byId: (id: string) => [...itemTypeKeys.detail(), id] as const,
};

export function useItemTypesByEventIdQuery(eventId: string) {
  return useQuery({
    queryKey: itemTypeKeys.filter({ eventId }),
    queryFn: () => ItemTypeApi.getAll(eventId),
  });
}

export function useItemTypeByIdQuery(eventId: string, itemTypeId?: string): ItemType | undefined {
  const { data: itemTypes, isFetched } = useItemTypesByEventIdQuery(eventId);

  const result = useMemo(() => {
    if (isFetched && itemTypeId) {
      return itemTypes?.find((v) => v.id === itemTypeId);
    }
    return undefined;
  }, [itemTypeId, itemTypes, isFetched]);

  return result;
}

export function useCreateItemTypeMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ eventId, itemType }: { eventId: string; itemType: PostItemType }) =>
      ItemTypeApi.createItemType(eventId, itemType),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: itemTypeKeys.all }),
  });
}

export function useUpdateItemTypeMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ eventId, itemType }: { eventId: string; itemType: ItemType }) =>
      ItemTypeApi.updateItemType(eventId, itemType),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: itemTypeKeys.all }),
  });
}

export function useDeleteItemTypeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ eventId, itemTypeId }: { eventId: string; itemTypeId: string }) =>
      ItemTypeApi.deleteItemType(eventId, itemTypeId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: itemTypeKeys.all });
    },
  });
}
