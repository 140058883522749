import { Button, Group, Text } from '@mantine/core';
import { ContextModalProps, closeModal, openContextModal } from '@mantine/modals';
import { useState } from 'react';

import { useCreateEventJoinLinkMutation } from '../../api/event-join-link.queries';
import { InviteParticipantsForm, useInviteParticipantsForm } from './InviteParticipantsForm';
import { EventMemberRole } from '../../models/eventMember.interface';
import { EventJoinLink } from '../../models/event-join-link.interface';

export const inviteParticipantsModalKey = 'inviteParticipants';

export function openInviteParticipantsModal(eventId: string, eventJoinLinks?: EventJoinLink[]) {
  const props: InviteParticipantsModalProps = {
    eventId,
    eventJoinLinks,
  };
  return openContextModal({
    modal: inviteParticipantsModalKey,
    title: 'Invite Participants',
    innerProps: props,
    size: 'lg',
  });
}

export interface InviteParticipantsModalProps extends Record<string, unknown> {
  eventId: string;
  eventJoinLinks?: EventJoinLink[];
}

export function InviteParticipantsModal({
  context,
  id,
  innerProps,
}: ContextModalProps<InviteParticipantsModalProps>): JSX.Element {
  const inviteParticipantsForm = useInviteParticipantsForm();

  const createMutation = useCreateEventJoinLinkMutation();

  const [errorMsg, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    const mutations = inviteParticipantsForm.values.validEmails.map((email) =>
      createMutation.mutateAsync({
        eventId: innerProps.eventId,
        role: EventMemberRole.Participant,
        emailAddress: email,
      })
    );

    Promise.all(mutations).then(
      () => {
        closeModal(id);
      },
      (error) => {
        console.log('error submitting join links', error);

        const msg = 'Can not add participants: ' + error;
        setErrorMessage(msg);
      }
    );
  };

  return (
    <>
      <InviteParticipantsForm
        inviteParticipantsForm={inviteParticipantsForm}
        eventJoinLinks={innerProps.eventJoinLinks}
      />
      {errorMsg.length ? (
        <Text c="error.1" mt="md">
          {errorMsg}
        </Text>
      ) : null}
      <Group position="center" mt="md">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button disabled={!inviteParticipantsForm.values.validEmails.length} onClick={handleSubmit}>
          Add Participants
        </Button>
      </Group>
    </>
  );
}
