import { useMutation, useQuery } from '@tanstack/react-query';
import { Bid } from '../models/bid.interface';
import { BiddingItem, PatchBiddingItem, PostBiddingItem } from '../models/bidding-item.interface';
import { ItemType } from '../models/itemType.interface';
import { QueryKeysMap } from '../models/keys.type';
import { StrategicTheme } from '../models/strategicTheme.interface';
import { ValueStream } from '../models/valueStream.interface';
import {
  DerivedQueriesResult,
  MapUseQueryResult,
  useDerivedQueries,
} from '../utils/combine.queries';
import { BiddingItemApi } from './api';
import {
  AnalysisBidType,
  EnrichedBidType,
  useGroupedBidsForEvent,
  useGroupedBidsForGroup,
} from './bid.queries';
import { useItemTypesByEventIdQuery } from './itemType.queries';
import { ManagedBiddingItem, useGetEventItems } from './event.queries';
import { useStrategicThemesByEventIdQuery } from './strategicTheme.queries';
import { useValueStreamsByEventIdQuery } from './valueStream.queries';

type Filters = {
  eventId: string;
};

/**
 * GetItemTypes defines the data types that are the result of the queries passed into `useDerivedQueries`
 * These should be singular and not including the array type (added generically)
 */
type GetItemTypes = [ManagedBiddingItem, EnrichedBidType];

/**
 * EventAnalysisTypes defines the data types for Event Analysis that are the result of the queries passed into `useDerivedQueries`
 * These should be singular and not including the array type (added generically)
 */
type EventAnalysisTypes = [
  ManagedBiddingItem,
  AnalysisBidType,
  ItemType,
  ValueStream,
  StrategicTheme
];

/**
 * GroupAnalysisTypes defines the data types for Group Analysis that are the result of the queries passed into `useDerivedQueries`
 * These should be singular and not including the array type (added generically)
 */
type GroupAnalysisTypes = [
  ManagedBiddingItem,
  EnrichedBidType,
  ItemType,
  ValueStream,
  StrategicTheme
];

export const biddingItemsKeys: QueryKeysMap<'biddingItems', Filters> = {
  all: ['biddingItems'] as const,
  list: () => [...biddingItemsKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...biddingItemsKeys.list(), { ...filters }] as const,
  detail: () => [...biddingItemsKeys.all, 'detail'] as const,
  byId: (biddingItemId: string) => [...biddingItemsKeys.detail(), biddingItemId] as const,
};

export function useBiddingItemsByEventIdQuery(eventId: string) {
  return useQuery({
    queryKey: biddingItemsKeys.filter({ eventId }),
    queryFn: () => BiddingItemApi.getAll({ eventId }),
    select: (data) => [...data].sort((a, b) => a.name.localeCompare(b.name)),
  });
}

export function useBiddingItemByIdQuery(id: string) {
  return useQuery({
    queryKey: biddingItemsKeys.byId(id),
    queryFn: () => BiddingItemApi.getDetails(id),
    enabled: !!id,
  });
}

/**
 * ManagedItemWithBids is the data type of one item that is returned from the combine function passed into `useDerivedQueries`.
 * Also use a singular type and do not include the array type (added generically)
 */
export type ManagedItemWithBids = ManagedBiddingItem & Partial<EnrichedBidType>;

export function useGetBudgetBiddingItems(
  eventId: string,
  groupId: string
): DerivedQueriesResult<ManagedItemWithBids> {
  const queries: MapUseQueryResult<GetItemTypes> = [
    useGetEventItems(eventId),
    useGroupedBidsForGroup(groupId),
  ];

  const queryResult = useDerivedQueries<GetItemTypes, ManagedItemWithBids>(
    queries,
    ([eventItems, biddingItems]) => {
      const budgetBids =
        eventItems && biddingItems
          ? eventItems.map((item: ManagedBiddingItem) => {
              const budgetBid = biddingItems.find(
                (groupedBid: Bid) => item.id === groupedBid.biddingItemId
              );
              return {
                ...item,
                ...budgetBid,
                bidProgress: budgetBid ? (budgetBid.bidAmount / item.fundingTarget) * 100 : 0,
              };
            })
          : [];

      return budgetBids;
    }
  );

  return queryResult;
}

/**
 * AnalysisItemWithBids is the data type of one item that is returned from the combine function passed into `useDerivedQueries`.
 * Also use a singular type and do not include the array type (added generically)
 */
export type AnalysisItemWithBids = ManagedBiddingItem & Partial<AnalysisBidType>;

export function useGetEventAnalysisItems(
  eventId: string
): DerivedQueriesResult<AnalysisItemWithBids> {
  const queries: MapUseQueryResult<EventAnalysisTypes> = [
    useBiddingItemsByEventIdQuery(eventId),
    useGroupedBidsForEvent(eventId),
    useItemTypesByEventIdQuery(eventId),
    useValueStreamsByEventIdQuery(eventId),
    useStrategicThemesByEventIdQuery(eventId),
  ];

  const queryResult = useDerivedQueries<EventAnalysisTypes, AnalysisItemWithBids>(
    queries,
    ([eventItems, groupedBids, itemTypes, valueStreams, strategicThemes]) => {
      // Determine how many groups have placed a bid,  Combines groups that have placed bids per item, and ensures no duplicates.
      const groupBidsPerItem = groupedBids.map((item) => item.groupIds);
      const groupsWithBid =
        groupBidsPerItem.length >= 1
          ? new Set([...groupBidsPerItem].flatMap((set) => Array.from(set)))
          : new Set();

      const budgetBids =
        eventItems && groupedBids
          ? eventItems.map((item: BiddingItem): ManagedBiddingItem => {
              const budgetBid = groupedBids.find(
                (groupedBid: AnalysisBidType) => item.id === groupedBid.biddingItemId
              );

              return Object.assign({}, item, {
                totalBids: budgetBid ? budgetBid.totalBids : 0,
                bidAmount: budgetBid ? budgetBid.bidAmount / groupsWithBid.size : 0,
                bidPercent: budgetBid
                  ? budgetBid.bidAmount / groupsWithBid.size / item.fundingTarget
                  : 0,
                itemTypeName: itemTypes.find(
                  (itemType: ItemType) => item.itemTypeId === itemType.id
                )?.value,
                valueStreamNames: valueStreams
                  .filter((vs) => item.valueStreamIds.includes(vs.id))
                  .map((vs) => vs.value),
                strategicThemeNames: strategicThemes
                  .filter((st) => item.strategicThemeIds.includes(st.id))
                  .map((st) => st.value),
              });
            })
          : [];

      return budgetBids;
    }
  );

  return queryResult;
}

export function useGetGroupAnalysisItems(
  groupId: string,
  eventId: string
): DerivedQueriesResult<AnalysisItemWithBids> {
  const queries: MapUseQueryResult<GroupAnalysisTypes> = [
    useBiddingItemsByEventIdQuery(eventId),
    useGroupedBidsForGroup(groupId),
    useItemTypesByEventIdQuery(eventId),
    useValueStreamsByEventIdQuery(eventId),
    useStrategicThemesByEventIdQuery(eventId),
  ];

  const queryResult = useDerivedQueries<GroupAnalysisTypes, AnalysisItemWithBids>(
    queries,
    ([eventItems, groupedBids, itemTypes, valueStreams, strategicThemes]) => {
      const budgetBids =
        eventItems && groupedBids
          ? eventItems.map((item: BiddingItem): ManagedBiddingItem => {
              const budgetBid = groupedBids.find(
                (groupedBid: EnrichedBidType) => item.id === groupedBid.biddingItemId
              );

              return Object.assign({}, item, {
                totalBids: budgetBid ? budgetBid.totalBids : 0,
                bidAmount: budgetBid ? budgetBid.bidAmount : 0,
                bidPercent: budgetBid ? budgetBid.bidAmount / item.fundingTarget : 0,
                itemTypeName: itemTypes.find(
                  (itemType: ItemType) => item.itemTypeId === itemType.id
                )?.value,
                valueStreamNames: valueStreams
                  .filter((vs) => item.valueStreamIds.includes(vs.id))
                  .map((vs) => vs.value),
                strategicThemeNames: strategicThemes
                  .filter((st) => item.strategicThemeIds.includes(st.id))
                  .map((st) => st.value),
              });
            })
          : [];

      return budgetBids;
    }
  );

  return queryResult;
}

export function useCreateBiddingItemMutation() {
  return useMutation({
    mutationFn: (biddingItem: PostBiddingItem) => BiddingItemApi.createBiddingItem(biddingItem),
  });
}

export function useUpdateBiddingItemMutation() {
  return useMutation({
    mutationFn: (biddingItem: PatchBiddingItem) => BiddingItemApi.updateBiddingItem(biddingItem),
  });
}

export function useDeleteBiddingItemMutation() {
  return useMutation({
    mutationFn: (biddingItemId: string) => BiddingItemApi.deleteBiddingItem(biddingItemId),
  });
}
