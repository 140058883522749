import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { QueryKeysMap } from '../models/keys.type';
import { PostValueStream, ValueStream } from '../models/valueStream.interface';
import { ValueStreamApi } from './api';

type Filters = {
  eventId?: string;
};

export const valueStreamKeys: QueryKeysMap<'valueStreams', Filters> = {
  all: ['valueStreams'] as const,
  list: () => [...valueStreamKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...valueStreamKeys.list(), { ...filters }],
  detail: () => [...valueStreamKeys.all, 'detail'] as const,
  byId: (id: string) => [...valueStreamKeys.detail(), id] as const,
};

export function useValueStreamsByEventIdQuery(eventId: string) {
  return useQuery({
    queryKey: valueStreamKeys.filter({ eventId }),
    queryFn: () => ValueStreamApi.getAll(eventId),
  });
}

export function useValueStreamByIdQuery(
  eventId: string,
  valueStreamId?: string
): ValueStream | undefined {
  const { data: valueStreams, isFetched } = useValueStreamsByEventIdQuery(eventId);

  const result = useMemo(() => {
    if (isFetched && valueStreamId) {
      return valueStreams?.find((v) => v.id === valueStreamId);
    }
    return undefined;
  }, [valueStreamId, valueStreams, isFetched]);

  return result;
}

export function useCreateValueStreamMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ eventId, valueStream }: { eventId: string; valueStream: PostValueStream }) =>
      ValueStreamApi.createValueStream(eventId, valueStream),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: valueStreamKeys.all }),
  });
}

export function useUpdateValueStreamMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ eventId, valueStream }: { eventId: string; valueStream: ValueStream }) =>
      ValueStreamApi.updateValueStream(eventId, valueStream),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: valueStreamKeys.all }),
  });
}

export function useDeleteValueStreamMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ eventId, valueStreamId }: { eventId: string; valueStreamId: string }) =>
      ValueStreamApi.deleteValueStream(eventId, valueStreamId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: valueStreamKeys.all });
    },
  });
}
