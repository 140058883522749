import { Text, Flex, Box, SimpleGrid, Image, Center, Title } from '@mantine/core';
import { useGetScreenSize } from '../../../hooks/useResponsiveDevices';
import cofundgraphic from '../../../assets/images/svg/SAFe_CoFund.svg';

type CircleProps = {
  label: string;
};

export function ColorCircle({ label }: CircleProps) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.sky[5],
        width: 32,
        height: 32,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        justifyContent: 'center',
      })}
    >
      <Text size="lg" fw={800}>
        {label}
      </Text>
    </Box>
  );
}

export const LoginPromotional = () => {
  const { smallScreen } = useGetScreenSize();
  return (
    <Box pt={10} pb={100} sx={(theme) => ({ background: theme.colors.yellow[0] })}>
      <Center>
        <Title
          my={30}
          mx={10}
          order={smallScreen ? 5 : 2}
          sx={(theme) => ({ background: theme.colors.teal[5] })}
          align="center"
        >
          Gain the confidence to facilitate participatory budgeting and prioritize portfolio
          investments as a team.
        </Title>
      </Center>
      <Flex align={'center'} style={{ maxWidth: 1000, margin: '0 auto' }}>
        <Image src={cofundgraphic} />
      </Flex>
      <SimpleGrid
        cols={2}
        style={{ maxWidth: 1000, margin: '0 auto' }}
        breakpoints={[{ maxWidth: 'xl', cols: 1 }]}
      >
        <Flex>
          <Box mt={35} ml={20}>
            <ColorCircle label={'1'} />
          </Box>
          <Box py="md" px="sm">
            <Title
              order={5}
              fw={800}
              py="xs"
              sx={(theme) => ({ color: theme.colors.sky[5], lineHeight: 1.3 })}
            >
              Real-time facilitation monitoring and bidding scenarios
            </Title>
            <Text>See bidding progress and adjust scenarios based on organizational outcomes.</Text>
          </Box>
        </Flex>

        <Flex>
          <Box mt={35} ml={20}>
            <ColorCircle label={'2'} />
          </Box>
          <Box py="md" px="sm">
            <Title
              order={5}
              fw={800}
              py="xs"
              sx={(theme) => ({ color: theme.colors.sky[5], lineHeight: 1.3 })}
            >
              Connect value streams, strategic themes, and horizons
            </Title>
            <Text>
              Easily incorporate core LPM elements into your decision-making and visualize the
              impact.
            </Text>
          </Box>
        </Flex>
      </SimpleGrid>
    </Box>
  );
};
