import { useMutation, useQuery } from '@tanstack/react-query';
import { PatchEventJoinLink, PostEventJoinLink } from '../models/event-join-link.interface';
import { QueryKeysMap } from '../models/keys.type';
import { EventJoinLinkApi } from './api';

type Filters = {
  eventId: string;
};

export const eventJoinLinksKeys: QueryKeysMap<'eventJoinLinks', Filters> = {
  all: ['eventJoinLinks'] as const,
  list: () => [...eventJoinLinksKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...eventJoinLinksKeys.list(), { ...filters }] as const,
  detail: () => [...eventJoinLinksKeys.all, 'detail'] as const,
  byId: (eventJoinLinkId: string) => [...eventJoinLinksKeys.detail(), eventJoinLinkId] as const,
};

export function useEventJoinLinksByEventIdQuery(eventId: string) {
  return useQuery({
    queryKey: eventJoinLinksKeys.filter({ eventId }),
    queryFn: () => EventJoinLinkApi.getAll({ eventId }),
  });
}

export function useEventJoinLinkByIdQuery(id: string) {
  return useQuery({
    queryKey: eventJoinLinksKeys.byId(id),
    queryFn: () => EventJoinLinkApi.getDetails(id),
    enabled: !!id,
  });
}

export function useCreateEventJoinLinkMutation() {
  return useMutation({
    mutationFn: (eventJoinLink: PostEventJoinLink) =>
      EventJoinLinkApi.createEventJoinLink(eventJoinLink),
  });
}

export function useUpdateEventJoinLinkMutation() {
  return useMutation({
    mutationFn: (eventJoinLink: PatchEventJoinLink) =>
      EventJoinLinkApi.updateEventJoinLink(eventJoinLink),
  });
}

export function useDeleteEventJoinLinkMutation() {
  return useMutation({
    mutationFn: (eventJoinLinkId: string) => EventJoinLinkApi.deleteEventJoinLink(eventJoinLinkId),
  });
}

export function useSendInvitationEmailMutation() {
  return useMutation({
    mutationFn: (eventJoinLinkId: string) => EventJoinLinkApi.sendInvitationEmail(eventJoinLinkId),
  });
}
