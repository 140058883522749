import { createStyles, getBreakpointValue, em } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  appHeader: {
    background: theme.colors.yellow[0],
  },
  logo: {
    width: '200px',
    margin: `0 calc(${theme.spacing.sm} * 1.5) 0 0`,
  },
  studioLogo: {
    scale: '1.35',
    width: '200px',
    margin: `0 calc(${theme.spacing.sm} * 1.2) 0 0`,
  },
  avatarName: {
    paddingTop: theme.spacing.sm,
  },
  link: {
    width: 90,
    height: 90,
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.gray[0],

    '&:hover': {
      border: `1px solid ${theme.colors.sky[1]}`,
    },
  },

  active: {
    '&:hover': {
      border: `1px solid ${theme.colors.sky[4]}`,
      backgroundColor: theme.colors.sky[4],
      color: theme.colors.gray[0],
    },
  },

  linkedHeaderTitle: {
    textDecoration: 'inherit',
    color: 'inherit',
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]: {
      maxWidth: '80%',
    },
  },
  tabsContainer: {
    background: theme.colors.gray[0],
    button: {
      color: theme.colors.teal[3],
      '&:hover': {
        background: theme.colors.gray[0],
        borderColor: theme.colors.teal[1],
      },
      '&[data-active]': {
        color: theme.colors.teal[3],
      },
    },
  },

  tabButtons: {
    button: {
      color: theme.colors.teal[3],
      '&:hover': {
        background: theme.colors.gray[0],
        borderColor: theme.colors.teal[1],
      },
      '&[data-active]': {
        color: theme.colors.teal[3],
      },
    },
  },
  inactiveBreadcrumb: {
    color: theme.colors.gray[6],
  },

  activeBreadcrumb: {
    color: theme.colors.teal[4],
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
  icons: {
    background: theme.colors.teal[2],
  },
  disabledButton: {
    color: theme.colors.gray[5],
    background: theme.colors.gray[2],
    borderColor: theme.colors.gray[2],
    cursor: 'not-allowed',
    '&:hover': {
      color: theme.colors.gray[5],
      background: theme.colors.gray[2],
    },
  },
}));
