import { createAvatar } from '@dicebear/core';
import * as initials from '@dicebear/initials';
import * as shapes from '@dicebear/shapes';

export function userAvatarUrl(name: string): string {
  // https://api.dicebear.com/6.x/initials/svg?seed=${name}&radius=50&backgroundType=gradientLinear&backgroundRotation=-230`
  return createAvatar(initials, {
    seed: name,
    radius: 50,
    backgroundType: ['gradientLinear'],
    backgroundRotation: [-230],
  }).toDataUriSync();
}

export function groupAvatar(name: string): string {
  // https://api.dicebear.com/5.x/shapes/svg?seed=${table.name}
  return createAvatar(shapes, {
    seed: name,
  }).toDataUriSync();
}
