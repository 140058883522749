import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  helpTextList: {
    fontSize: '0.75rem',
    listStyle: 'none',
    marginLeft: 0,
    marginTop: theme.spacing.xs,
    paddingLeft: theme.spacing.xs,
    paddingTop: 0,
  },
  errorText: {
    color: theme.colors.red[1],
  },
  helpText: {
    color: theme.colors.gray[5],
  },
}));
