import { showNotification } from '@mantine/notifications';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MessageData, MessageResource } from '../api/websocket';
import { linkToEvents } from '../utils/event-urls';
import { useAuthContext } from './useAuth';
import { useIsFacilitator } from './useIsFacilitator';

const groupBudgetIncreased = `Someone has been removed from your table! 
This increases your individual budget. Your existing bids are within your new individual budget, 
but feel free to make changes.`;

const groupBudgetDecreased = `Someone has joined your table! This reduces your 
individual budget. Your existing bids are within your new individual budget, 
but feel free to make changes.`;

const participantRemoved =
  'Your table assignment or event role has changed. Your bids have also been removed. Click the event name above to return to the event overview.';

const facilitatorChangedRole = 'A members role has been changed.';

const memberJoinedFacilitator = 'A new member has joined the event';
const welcomeMessage = 'Welcome to the Event!';

export function useUserNotificationManager(eventId: string) {
  const { user } = useAuthContext();
  const { isFacilitator } = useIsFacilitator(eventId);
  const { groupId } = useParams();
  const navigate = useNavigate();

  const showGenericMemberNotification = (message: string) => {
    showNotification({
      title: 'Members Updated',
      message,
      autoClose: true,
    });
  };

  const showBudgetUpdatedNotification = (message: string) => {
    showNotification({
      title: 'Members Updated',
      message,
      autoClose: 5000,
    });
  };

  const showEventUpdatedNotification = (message: string) => {
    showNotification({
      title: 'Event has been Updated!',
      message,
      autoClose: 5000,
    });
  };

  const showEventDeletedNotification = (message: string, onClose?: () => void) => {
    showNotification({
      title: 'Event has been Deleted!',
      message,
      autoClose: 5000,
      onClose,
    });
  };

  return useMemo(() => {
    // Either inline these functions or put them in their own useCallback hooks
    const setNewItemNotification = (message: MessageData) => {
      switch (message.resource) {
        case MessageResource.GROUP_MEMBERS:
          (message.data.userId === user?.id && message.data.groupId === groupId) || isFacilitator
            ? showGenericMemberNotification(
                isFacilitator ? memberJoinedFacilitator : welcomeMessage
              )
            : message.data.groupId === groupId
            ? showBudgetUpdatedNotification(groupBudgetDecreased)
            : null;
          break;
        case MessageResource.BIDDING_ITEMS:
          showEventUpdatedNotification(
            `Item Name ${message.data.name} has been added to the event.`
          );
          break;
        default:
          break;
      }
    };

    const setRemovedItemNotification = (message: MessageData) => {
      switch (message.resource) {
        case MessageResource.GROUP_MEMBERS:
          (message.data.groupId === groupId && message.data.userId === user?.id) || isFacilitator
            ? showGenericMemberNotification(
                isFacilitator ? facilitatorChangedRole : participantRemoved
              )
            : message.data.groupId !== groupId
            ? showBudgetUpdatedNotification(groupBudgetIncreased)
            : null;
          break;
        case MessageResource.BIDDING_ITEMS:
          showEventUpdatedNotification(
            `Item Name ${message.data.name} has been removed from the event.`
          );
          break;

        case MessageResource.EVENTS:
          showEventDeletedNotification(`The event ${message.data.name} has been removed.`, () => {
            // there's sometimes the issue that an old websocket subscription causes a redirect even
            // if the current event is different. Checking against current url prevents this
            if (window.location.href.includes(eventId)) {
              navigate(linkToEvents());
            }
          });
          break;

        default:
          break;
      }
    };

    const setUpdatedItemNotification = (message: MessageData) => {
      switch (message.resource) {
        case MessageResource.EVENTS:
          showEventUpdatedNotification(`Event ${message.data.name} has been updated.`);
          break;
        case MessageResource.BIDDING_ITEMS:
          showEventUpdatedNotification(`Bidding Item ${message.data.name} has been updated.`);
          break;
        default:
          break;
      }
    };

    const setMemberRemovedFromEventNotification = (message: MessageData) => {
      switch (message.resource) {
        case MessageResource.EVENT_MEMBERS:
          showEventDeletedNotification(`The event has been removed.`);
          if (window.location.href.includes(eventId)) {
            navigate(linkToEvents());
          }
          break;
        default:
          break;
      }
    };

    return {
      setNewItemNotification,
      setRemovedItemNotification,
      setMemberRemovedFromEventNotification,
      setUpdatedItemNotification,
    };
  }, [isFacilitator, groupId, user?.id, navigate, eventId]);
}
