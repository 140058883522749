export function linkToBidding(eventId: string, groupId: string, isSample?: boolean): string {
  return isSample
    ? `/events/${eventId}/groups/${groupId}?sampleEvent=true`
    : `/events/${eventId}/groups/${groupId}`;
}

export function linkToGroupDetails(eventId: string, groupId: string, isSample?: boolean): string {
  return isSample
    ? `/events/${eventId}/groups/${groupId}/details?sampleEvent=true`
    : `/events/${eventId}/groups/${groupId}/details`;
}

export function linkToGroupProgress(eventId: string, groupId: string, isSample?: boolean): string {
  return isSample
    ? `/events/${eventId}/groups/${groupId}/details?view=progress&sampleEvent=true`
    : `/events/${eventId}/groups/${groupId}/details?view=progress`;
}

export function linkToGroupAnalysis(eventId: string, groupId: string, isSample?: boolean): string {
  return isSample
    ? `/events/${eventId}/groups/${groupId}/analysis?sampleEvent=true`
    : `/events/${eventId}/groups/${groupId}/analysis`;
}

export function linkToGroupAnalysisCharts(
  eventId: string,
  groupId: string,
  isSample?: boolean
): string {
  return isSample
    ? `/events/${eventId}/groups/${groupId}/analysis?view=charts&sampleEvent=true`
    : `/events/${eventId}/groups/${groupId}/analysis?view=charts`;
}
