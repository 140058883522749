import { Button, Group, SelectItem, Text } from '@mantine/core';
import { ContextModalProps, closeModal, openContextModal } from '@mantine/modals';
import { useState } from 'react';
import { useUpdateEventMemberMutation } from '../../api/eventMember.queries';
import {
  useAddGroupMemberMutation,
  useDeleteGroupMemberMutation,
  usePatchGroupMemberMutation,
} from '../../api/groupMember.queries';
import { roleId } from '../../components/participants/EventJoinLinkTableEntry';
import { EventJoinLink } from '../../models/event-join-link.interface';
import { EventMember, EventMemberRole } from '../../models/eventMember.interface';
import { User } from '../../models/user.interface';
import { ParticipantEditForm, useParticipantEditForm } from './ParticipantEditForm';

export const memberEditModalKey = 'memberEdit';

export function openMemberEditModal(
  eventId: string,
  user: User | undefined,
  member: EventMember,
  eventJoinLink: EventJoinLink | undefined,
  groupChoices: SelectItem[],
  selectedGroupId?: string | null
) {
  const props: MemberEditModalProps = {
    eventId,
    user,
    member,
    eventJoinLink,
    groupChoices: groupChoices,
    selectedGroupId,
  };
  return openContextModal({
    modal: memberEditModalKey,
    title: 'Edit Member',
    innerProps: props,
    size: 'md',
  });
}

export interface MemberEditModalProps extends Record<string, unknown> {
  eventId: string;
  user: User | undefined;
  member: EventMember;
  eventJoinLink?: EventJoinLink;
  groupChoices: SelectItem[];
  selectedGroupId?: string | null;
}

export function MemberEditModal({
  context,
  id,
  innerProps,
}: ContextModalProps<MemberEditModalProps>): JSX.Element {
  const participantEditForm = useParticipantEditForm({
    ...innerProps.member,
    role: innerProps.member.role || EventMemberRole.Participant,
    emailAddress: innerProps.eventJoinLink?.emailAddress,
    name:
      innerProps?.user?.firstName && innerProps?.user?.lastName
        ? `${innerProps.user.firstName} ${innerProps.user.lastName}`
        : innerProps?.user?.userName || '',
    emailSentAtStr: innerProps.eventJoinLink?.emailSentAt
      ? innerProps.eventJoinLink?.emailSentAt
      : '',
    status: 'Joined',
    roleId: roleId(innerProps.member.role || EventMemberRole.Participant),
    selectedGroupId: innerProps.selectedGroupId || '',
  });

  const updateEventMember = useUpdateEventMemberMutation();
  const patchGroupMember = usePatchGroupMemberMutation();
  const deleteGroupMember = useDeleteGroupMemberMutation();
  const addGroupMember = useAddGroupMemberMutation();

  const [errorMsg, setErrorMessage] = useState('');

  const handleGroupChange = async (
    previous: string | null | undefined,
    newGroupId: string | null
  ) => {
    if (!previous && newGroupId) {
      addGroupMember.mutateAsync({
        groupId: newGroupId as string,
        groupMember: {
          userId: innerProps.member.userId,
        },
      });
    }
    if (previous && newGroupId && previous !== newGroupId) {
      patchGroupMember.mutateAsync({
        groupId: previous as string,
        groupMember: {
          id: previous,
          userId: innerProps.member.userId,
          groupId: newGroupId as string,
        },
      });
    }
    if (previous && !newGroupId) {
      deleteGroupMember.mutateAsync({
        groupId: previous as string,
        member: {
          id: previous,
          userId: innerProps.member.userId,
          groupId: newGroupId as string,
          isMember: false,
        },
      });
    }
  };

  const handleRoleChange = (currentRole: EventMemberRole, selectedRole: EventMemberRole) => {
    // The confirmation modal to change role to facilitator is triggered in the form
    if (currentRole !== selectedRole) {
      updateEventMember.mutateAsync({
        eventId: innerProps.eventId,
        eventMember: {
          id: innerProps.member.id,
          userId: innerProps.member.userId,
          role: selectedRole,
        },
      });
    }
  };

  const handleSubmit = async () => {
    Promise.all([
      handleRoleChange(
        innerProps.member.role || EventMemberRole.Participant,
        participantEditForm.values.role
      ),
      handleGroupChange(
        innerProps.selectedGroupId,
        participantEditForm.values.selectedGroupId || null
      ),
    ]).then(
      () => {
        closeModal(id);
      },
      (error) => {
        const msg = 'Can not update member: ' + error;
        setErrorMessage(msg);
      }
    );
  };

  return (
    <>
      <ParticipantEditForm
        inviteParticipantEditForm={participantEditForm}
        groupChoices={innerProps.groupChoices}
        confirmRoleChange
      />
      {errorMsg.length ? (
        <Text c="error.1" mt="md">
          {errorMsg}
        </Text>
      ) : null}
      <Group position="center" mt="md">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Group>
    </>
  );
}
