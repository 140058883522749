import {
  Alert,
  Box,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  SegmentedControl,
  Space,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps, openContextModal } from '@mantine/modals';
import { useEventByIdQuery, useUpdateEventMutation } from '../../api/event.queries';
import { PatchEvent } from '../../models/event.interface';
import { IconAlertCircle } from '@tabler/icons-react';

export const adminControlsModalKey = 'adminEventControls';

export function openAdminControlsModal(eventId: string) {
  const props: AdminControlsModalValues = {
    eventId: eventId,
  };
  return openContextModal({
    modal: adminControlsModalKey,
    title: 'Event Controls',
    innerProps: props,
    size: 'lg',
  });
}

interface AdminControlsModalValues {
  eventId: string;
}

export function AdminControlsModal({
  innerProps,
}: ContextModalProps<AdminControlsModalValues>): JSX.Element {
  const updateEventMutation = useUpdateEventMutation();
  const { data: event } = useEventByIdQuery(innerProps.eventId);
  const theme = useMantineTheme();

  const updateParticipantGroupSelect = async (newValue: boolean) => {
    const patchEvent: PatchEvent = {
      allowParticipantGroupSelect: newValue,
      id: innerProps.eventId,
    };
    await updateEventMutation.mutateAsync(patchEvent);
  };

  const updateEventAccess = async (newValue: boolean) => {
    const patchEvent: PatchEvent = {
      allowUrlJoin: newValue,
      id: innerProps.eventId,
    };
    await updateEventMutation.mutateAsync(patchEvent);
  };

  const updateBiddingStatus = async (newValue: boolean) => {
    const patchEvent: PatchEvent = {
      allowBidding: newValue,
      id: innerProps.eventId,
    };
    await updateEventMutation.mutateAsync(patchEvent);
  };

  return (
    <>
      <Stack spacing="sm" pb="xl">
        {event?.isSample ? (
          <Card shadow="xs" p="xs">
            <Alert icon={<IconAlertCircle size="1rem" />} color="warning">
              Event controls are disabled for sample events.
            </Alert>
          </Card>
        ) : null}
        <Text align="left" size="lg" weight={900}>
          Access Settings
        </Text>
        <Divider variant="dashed" mb="sm" />
        <Flex>
          <Stack spacing="0" style={{ flex: '2 1 auto' }} pr={90}>
            <Text size="sm" weight={600}>
              Allow Bidding
            </Text>
            <Text size="xs" w="100%">
              Toggle on to allow participants to bid. Toggle off to pause all bidding.
            </Text>
          </Stack>
          <Switch
            checked={event?.allowBidding}
            disabled={event?.isSample}
            size="md"
            color={theme.colors.green[2]}
            style={{ flex: '1 1 auto' }}
            onChange={(value) => updateBiddingStatus(value.target.checked)}
            px="lg"
            mt="sm"
          ></Switch>
        </Flex>
        <Flex mt="lg">
          <Stack spacing="0" style={{ flex: '2 1 auto' }} pr="xl">
            <Text size="sm" weight={600}>
              Allow Participants to Self-Assign to Tables
            </Text>
            <Text size="xs">
              Toggle on to allow participants to choose their own tables. This setting only applies
              for events with more than one table.
            </Text>
          </Stack>
          <Switch
            checked={event?.allowParticipantGroupSelect}
            disabled={event?.isSample}
            size="md"
            color={theme.colors.green[2]}
            style={{ flex: '1 1 auto' }}
            onChange={(value) => updateParticipantGroupSelect(value.target.checked)}
            px="lg"
            mt="sm"
          ></Switch>
        </Flex>
        <Flex mt="lg">
          <Stack spacing="0" style={{ flex: '2 1 auto' }} pr="xl">
            <Text size="sm" weight={600}>
              Allow Join by Event Link
            </Text>
            <Text size="xs">
              Toggle on to allow anyone with the event link to join. Toggle off to restrict access
              only to attendees listed in the People tab.
            </Text>
          </Stack>
          <Switch
            checked={event?.allowUrlJoin}
            disabled={event?.isSample}
            size="md"
            color={theme.colors.green[2]}
            style={{ flex: '1 1 auto' }}
            onChange={(value) => updateEventAccess(value.target.checked)}
            px="lg"
            mt="sm"
          ></Switch>
        </Flex>
      </Stack>
    </>
  );
}
