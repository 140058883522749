/* eslint-disable react/prop-types */
import { Input, Text } from '@mantine/core';
import { RichTextEditor, RichTextEditorStylesNames } from '@mantine/tiptap';
import { JSONContent } from '@tiptap/core';
import { useEditor } from '@tiptap/react';
import { editorExtensions } from './editorConfig';
import { sanitizeEditorJson } from './sanitize-utils';

interface RichTextInputProps {
  title?: string;
  placeholder?: string;
  classNames?: Partial<Record<RichTextEditorStylesNames, string>>;
  error?: any;
  value: JSONContent | null | undefined;
  disabled?: boolean;
  onChange: (val: JSONContent) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export function RichTextInput({
  title,
  placeholder = '',
  classNames,
  disabled,
  error,
  value,
  onChange: handleChange,
  onBlur: handleBlur,
  onFocus: handleFocus,
}: RichTextInputProps) {
  const editor = useEditor({
    extensions: editorExtensions({ contentEditable: true, placeholder, maxTextLen: 10000 }),
    content: value,
    onUpdate: (props) => {
      const json = props.editor.getJSON();
      const hasChanged = sanitizeEditorJson(json);
      handleChange(json);
      if (hasChanged) {
        // write back into editor if a changed occured
        props.editor.commands.setContent(json);
      }
    },
    onFocus: () => handleFocus?.(),
    onBlur: () => handleBlur?.(),
  });

  /*
   * Reset editor when there is a new or empty item
   */
  !value && editor ? editor.commands.clearContent() : null;

  return (
    <>
      <Text size="sm">{title}</Text>
      <Input.Wrapper
        error={error}
        mt={-15}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
      >
        <RichTextEditor
          editor={editor}
          placeholder="Click to add description"
          classNames={classNames}
        >
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.Italic disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.Underline disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.Strikethrough disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.ClearFormatting disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.Highlight disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.BulletList disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.OrderedList disabled={disabled} opacity={disabled ? 0.5 : 1} />
            </RichTextEditor.ControlsGroup>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link disabled={disabled} opacity={disabled ? 0.5 : 1} />
              <RichTextEditor.Unlink disabled={disabled} opacity={disabled ? 0.5 : 1} />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content />
        </RichTextEditor>
      </Input.Wrapper>
    </>
  );
}
