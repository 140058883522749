/* Framework */
import {
  MultiSelect,
  NumberInput,
  Select,
  SimpleGrid,
  Stack,
  TextInput,
  useMantineTheme,
} from '@mantine/core';

/* Internal */
// Data
import { useForm } from '@mantine/form';
import { UseFormReturnType } from '@mantine/form/lib/types';
import { eventHorizons } from '../../../constants/formDefaults';
import { RichTextInput } from '../../editor/RichTextInput';
//Styling
import { JSONContent } from '@tiptap/core';
import { useItemTypesByEventIdQuery } from '../../../api/itemType.queries';
import { useStrategicThemesByEventIdQuery } from '../../../api/strategicTheme.queries';
import { useValueStreamsByEventIdQuery } from '../../../api/valueStream.queries';
import { useSmallEditorStyles } from '../../editor/editorStyles';
import { useMemo } from 'react';
import { formatNumberInput, parseNumberInput } from '../../../constants/numberInputFormat';
import { CustomValueStreamLabel } from '../../../pages/events/components/custom-labels/CustomValueSteamLabel';
import { CustomStrategicThemeLabel } from '../../../pages/events/components/custom-labels/CustomStrategicThemeLabel';
import { useCustomValueStream } from '../../../hooks/useCustomValueStream';
import { useCustomStrategicTheme } from '../../../hooks/useCustomStrategicTheme';

// values used for this component, decoupled from actual API fields
export interface BiddingItemFormValues {
  name: string;
  description: JSONContent | null;
  fundingTarget: number;
  horizon: string;
  itemTypeId: string;
  valueStreamIds: string[] | undefined;
  strategicThemeIds: string[] | undefined;
}

// empty item, useful for create flow
export const emptyItemFormValue: BiddingItemFormValues = {
  name: '',
  description: null,
  fundingTarget: 0,
  horizon: '',
  itemTypeId: '',
  valueStreamIds: undefined,
  strategicThemeIds: undefined,
};

export const useBiddingItemForm = (initialValues: BiddingItemFormValues) =>
  useForm({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: initialValues,
    validate: {
      name: (v) => (v?.trim().length > 0 ? null : 'Please add a item name'),
      fundingTarget: (v) => (v ? null : 'Please add a item estimated budget'),
      itemTypeId: (v) => (v && v.trim().length > 0 ? null : 'Please selected an item type'),
    },
  });

interface BiddingItemFormProps {
  eventId: string;
  itemForm: UseFormReturnType<BiddingItemFormValues>;
}

type SelectType = {
  value: string;
  label: string;
};

/**
 * A form to create/edit a single bidding item.
 * Reused across create, edit and also event wizard flow.
 *
 * Use `useBiddingItemForm` in your component and pass it into this component.
 */
export function BiddingItemForm({ itemForm, eventId }: BiddingItemFormProps): JSX.Element {
  const editorStyles = useSmallEditorStyles();
  const theme = useMantineTheme();
  const { data: itemTypes } = useItemTypesByEventIdQuery(eventId);
  const { data: valueStreams } = useValueStreamsByEventIdQuery(eventId);
  const { data: strategicThemes } = useStrategicThemesByEventIdQuery(eventId);

  const customValueStreamLabel = useCustomValueStream(eventId);
  const customStrategicThemeLabel = useCustomStrategicTheme(eventId);

  const itemTypesSelect: SelectType[] = useMemo(() => {
    return itemTypes?.map((v) => ({ value: v.id, label: v.value })) ?? [];
  }, [itemTypes]);

  const valueStreamsSelect: SelectType[] = useMemo(() => {
    return valueStreams?.map((v) => ({ value: v.id, label: v.value })) ?? [];
  }, [valueStreams]);

  const strategicThemesSelect: SelectType[] = useMemo(() => {
    return strategicThemes?.map((v) => ({ value: v.id, label: v.value })) ?? [];
  }, [strategicThemes]);

  return (
    <>
      <Stack spacing="md" style={{ width: '100%' }}>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 'sm' }]}>
          <TextInput
            placeholder="Enter Name"
            {...itemForm.getInputProps('name')}
            label="Item Name"
            style={{ flex: 1 }}
          />
          <NumberInput
            placeholder="Cost Estimate"
            parser={parseNumberInput}
            formatter={formatNumberInput}
            label="Item Cost"
            {...itemForm.getInputProps('fundingTarget')}
            style={{ flex: 1 }}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 'sm' }]}>
          <Select
            data={itemTypesSelect}
            placeholder="Select Item Type"
            label="Item Type"
            {...itemForm.getInputProps('itemTypeId')}
            style={{ flex: 1 }}
          />
          <Select
            data={eventHorizons}
            clearable
            placeholder="Select Item Horizon"
            label="Horizon"
            {...itemForm.getInputProps('horizon')}
            style={{ flex: 1 }}
          />
        </SimpleGrid>
        <Stack>
          <MultiSelect
            data={strategicThemesSelect}
            placeholder={`Select ${customStrategicThemeLabel}`}
            label={<CustomStrategicThemeLabel />}
            clearable
            {...itemForm.getInputProps('strategicThemeIds')}
          />
          <MultiSelect
            data={valueStreamsSelect}
            placeholder={`Select ${customValueStreamLabel}`}
            label={<CustomValueStreamLabel />}
            clearable
            {...itemForm.getInputProps('valueStreamIds')}
          />
        </Stack>
        <RichTextInput
          placeholder="Click to add description"
          title="Item Description"
          classNames={editorStyles}
          {...itemForm.getInputProps('description')}
        />
      </Stack>
    </>
  );
}
