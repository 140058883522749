import { getLocale } from './i18n-utils';

export enum DateFormat {
  Default = 'default',
  DateAndTime = 'date-and-time',
}

const DateFormatOptions: Record<DateFormat, Intl.DateTimeFormatOptions | undefined> = {
  [DateFormat.Default]: undefined,
  [DateFormat.DateAndTime]: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
};

export function intoDate(maybeDate: Date | string): Date {
  return typeof maybeDate === 'string' ? new Date(maybeDate) : maybeDate;
}

/**
 * default: for en-US: MM/DD/YYYY
 * Add more options when needed,
 * see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 */
export interface FormattedDateProps {
  date: Date | string;
  dateFormat?: DateFormat;
}

export function formatDate({
  date: maybeDate,
  dateFormat = DateFormat.Default,
}: FormattedDateProps): string {
  if (!maybeDate) {
    return '';
  }
  const date: Date = intoDate(maybeDate);
  return new Intl.DateTimeFormat(getLocale(), DateFormatOptions[dateFormat]).format(date);
}

export function FormattedDate(props: FormattedDateProps): JSX.Element {
  return <span>{formatDate(props)}</span>;
}
