import { createFormContext } from '@mantine/form';
import { ParseError } from 'papaparse';
import { PostBiddingItem } from '../../models/bidding-item.interface';
import { EventItem } from '../../models/item.interface';
import { ValidatedRawCsvData } from './CsvFileParser';

export function labelToLower(label: string): string {
  return label.trim().toLowerCase();
}

export interface TDragonFruitFieldNames {
  NAME: string;
  DESC: string;
  COST_EST: string;
  ITEM_TYPE: string;
  STRATEGIC_THEMES: string;
  VALUE_STREAMS: string;
  HORIZON: string;
}

export type DragonFruitFieldType = {
  name: string;
  mandatory?: boolean;
};

export type FailureBiddingItem = Partial<PostBiddingItem> & {
  row: number;
  errors?: string[];
};

export interface CsvFieldMapping {
  dfField: string;
  dfFieldLower: string;
  csvField?: string;
  csvFieldLower?: string;
  mandatory?: boolean;
}

export interface ItemUploadFormValues {
  eventId: string;
  delimiter: string;
  rawCsvData: ValidatedRawCsvData[] | undefined;
  csvHeaders: string[] | undefined;
  eventItems: EventItem[] | undefined;
  parseErrors: ParseError[] | undefined;
  mandatoryFieldsMapped: boolean;
  csvFieldMapping: CsvFieldMapping[] | undefined;
  postBiddingItems: PostBiddingItem[] | undefined;
  failureBiddingItems: Array<FailureBiddingItem> | undefined;
}

// You can give context variables any name
export const [ItemUploadFormProvider, useItemUploadFormContext, useItemUploadForm] =
  createFormContext<ItemUploadFormValues>();
