import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { Event } from '../models/event.interface';
import { EventGroup } from '../models/group.interface';
import { BiddingItem } from '../models/bidding-item.interface';
import { Bid } from '../models/bid.interface';
import { GroupMember } from '../models/groupMember.interface';
import { EventMember } from '../models/eventMember.interface';
import { ItemType } from '../models/itemType.interface';
import { ValueStream } from '../models/valueStream.interface';
import { StrategicTheme } from '../models/strategicTheme.interface';
import * as Sentry from '@sentry/react';
import { EventJoinLink } from '../models/event-join-link.interface';
import { useMemo } from 'react';

type Resource =
  | { resource: 'events'; data: Event }
  | { resource: 'groups'; data: EventGroup }
  | { resource: 'biddingItems'; data: BiddingItem }
  | { resource: 'bids'; data: Bid }
  | { resource: 'groupMembers'; data: GroupMember }
  | { resource: 'eventMembers'; data: EventMember }
  | { resource: 'itemTypes'; data: ItemType }
  | { resource: 'valueStreams'; data: ValueStream }
  | { resource: 'strategicThemes'; data: StrategicTheme }
  | { resource: 'eventJoinLinks'; data: EventJoinLink };

function getDetailQueryKey(resource: string, id: string) {
  return [resource, 'detail', id];
}

function getListQueryKey(resource: string) {
  return [resource, 'list'];
}

function isObject(object: unknown) {
  return object != null && typeof object === 'object';
}

// returns true if all properties of filter are matching the values of data
// if the filter is an empty object, returns true
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function doesFilterMatchData(filter: Record<string, any>, data: Record<string, any>): boolean {
  for (const key in filter) {
    const filterValue = filter[key];
    const dataValue = data[key];

    const areObjects = isObject(filterValue) && isObject(dataValue);

    // if both the value and the data are objects, we need to recurse
    if (areObjects && !doesFilterMatchData(filterValue, dataValue)) {
      return false;
    }

    // otherwise compare values
    if (!areObjects && filterValue !== dataValue) {
      return false;
    }
  }
  return true;
}

function buildQueryCacheManager(queryClient: QueryClient) {
  function addCachedData({
    resource,
    data,
    onComplete,
  }: Resource & { onComplete?: (newData: Resource['data']) => void }): void {
    const listQueryKey = getListQueryKey(resource);
    const detailQueryKey = getDetailQueryKey(resource, data.id);

    // add the data to the detail cache
    queryClient.setQueryData(detailQueryKey, data);

    const updateFn = (old: unknown) => {
      if (Array.isArray(old)) {
        const existing = old.find((el) => el.id === data.id);
        if (existing === undefined) {
          // if the element is truly new, add it to the end of the array
          return [...old, data];
        } else {
          // in certain cases, the element may exist already, update it
          const update = (entity: Record<string, unknown>) => {
            return entity.id === data.id ? data : entity;
          };
          return old.map(update);
        }
      } else if (old) {
        return old;
      } else {
        Sentry.captureMessage(
          `Race condition detected for resource: ${resource}, and key: ${listQueryKey}. Refetching queries`,
          { level: 'warning' }
        );
        console.warn(
          `Race condition detected for resource: ${resource}, and key: ${listQueryKey}. Refetching queries`
        );
        setTimeout(() => {
          queryClient.invalidateQueries();
        }, 500);
      }
    };

    // get ALL list-queries for this resource
    // some of them are filtered, one of them can be the "all" list
    const queriesForRecord = queryClient.getQueriesData({ queryKey: listQueryKey });

    queriesForRecord.forEach((query) => {
      // for each query
      const queryKey = query[0];

      // check what the filter looks like
      if (queryKey.length === 2) {
        // if it has two elements, it is the "unfiltered" list, we just append to it
        queryClient.setQueryData(queryKey, updateFn);
      } else if (queryKey.length === 3) {
        // 3 elements represent a filtered query
        if (queryKey[2] === null || typeof queryKey[2] !== 'object') {
          console.warn(
            "query cache manager, don't know what to do with queryKey, invalid type",
            queryKey
          );
        } else {
          // valid filter, check if this object matches it
          const filter: object = queryKey[2];

          const hit = doesFilterMatchData(filter, data);

          if (hit) {
            queryClient.setQueryData(queryKey, updateFn);
          }
        }
      } else {
        // not sure what to do here, warn out
        console.warn(
          "query cache manager, don't know what to do with queryKey, more than 3 elements",
          queryKey
        );
      }
    });

    if (onComplete) onComplete(data);
  }

  function updateCachedData({
    resource,
    data,
    onComplete,
  }: Resource & {
    onComplete?: (oldData: unknown, newData: Resource['data']) => void;
  }): void {
    let oldData;

    // update the data in the cache and save the old data for later
    queryClient.setQueryData(getDetailQueryKey(resource, data.id), (old: unknown) => {
      oldData = old;
      return data;
    });

    // update the data where it appears in any lists
    // note that `old` is sometimes undefined
    queryClient.setQueriesData({ queryKey: getListQueryKey(resource) }, (old: unknown) => {
      const update = (entity: Record<string, unknown>) => {
        return entity.id === data.id ? data : entity;
      };
      if (old) {
        return Array.isArray(old) ? old.map(update) : update(old as Record<string, unknown>);
      }
    });

    if (onComplete) onComplete(oldData, data);
  }

  function removeCachedData({
    resource,
    data,
    onComplete,
  }: Resource & { onComplete?: (oldData: unknown) => void }): void {
    const oldData = queryClient.getQueryData(getDetailQueryKey(resource, data.id));
    // remove the data from the cache
    queryClient.removeQueries({
      queryKey: getDetailQueryKey(resource, data.id),
    });

    // remove the data from any lists in the cache
    queryClient.setQueriesData({ queryKey: getListQueryKey(resource) }, (old: unknown) => {
      if (!Array.isArray(old)) {
        return undefined;
      }
      return [...old.filter((item) => item.id !== data.id)];
    });

    if (onComplete) onComplete(oldData);
  }

  return { addCachedData, updateCachedData, removeCachedData };
}

/*
 * This hook manages the application's  tanstack-query query cache for certain resources and exposes
 * useful functions for calling code.
 *
 * It exposes three functions:
 * - `addCachedData`
 * - `updateCachedData`
 * - `removeCachedData`
 *
 * These functions are used to modify the query cache directly for message processing over websockets.
 * _For the vast majority of cases, prefer to use the `useQuery` and `useMutation` hooks provided by tanstack-query!_
 *
 * It leverages tanstack-query's internal cache structure using composite keys based on the
 * API resources so that there is a unique entry for a given resource value
 * in both a `detail` cache entry and many `list` cache entries.
 * The list elements are duplicated and flattened into the cache structure.
 * This permits React Query to use fuzzy matching while selecting resources from this cache.
 *
 */
export function useQueryCacheManager() {
  const queryClient = useQueryClient();
  const manager = useMemo(() => buildQueryCacheManager(queryClient), [queryClient]);

  return manager;
}
