import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useLocalStorage } from '@mantine/hooks';
import { useVerify } from '../api/sessions.queries';

export type UserDataFromJwt = {
  readonly exp: number;
  readonly iat: number;
  readonly id: string;
  readonly provider: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string | null;
  readonly userName: string;
  readonly pendoVisitorId: string;
  readonly pendoAccountId: string;
  readonly roles: string[];
};

export type AuthContextType = {
  user: UserDataFromJwt | null;
  token: string;
  setToken: (jwt: string) => void;
  removeToken: () => void;
  isValidating: boolean;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const { user, token, handleTokenChange, isFetching } = useProvideAuth();

  // object to expose to children
  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        setToken: handleTokenChange,
        removeToken: () => handleTokenChange(null),
        isValidating: isFetching,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useProvideAuth() {
  // Use localStorage to track the jwt token
  const [token, setToken, removeToken] = useLocalStorage({ key: 'token' });
  const [user, setUser] = useState<UserDataFromJwt | null>(null);
  const { isSuccess, isFetching, isError } = useVerify(token);

  // function to expose to internal state behavior of auth provider
  const handleTokenChange = (jwt: string | null) => {
    if (!jwt) {
      removeToken();
      setUser(null);
    } else {
      // If the token is there, decode it and pass to user state
      setToken(jwt);
      const decodedToken: UserDataFromJwt = jwt_decode(jwt);
      setUser(decodedToken);
    }
  };

  // after mount
  useEffect(() => {
    if (isSuccess && token && !user) {
      // set the initial user data from the verified token from local storage
      const initialDecodedToken = jwt_decode(token) as UserDataFromJwt;
      setUser(initialDecodedToken);
    } else if (isError) {
      // the token is bad remove from local storage
      removeToken();
      setUser(null);
    }
  }, [user, isSuccess, isError, token, removeToken]);

  return { user, handleTokenChange, token, isFetching };
}

// Hook that creates auth object and handles state
export function useAuthContext() {
  const authContext = useContext(AuthContext);

  if (authContext) {
    return authContext;
  }

  throw new Error(`useAuthContext must be used within a AuthProvider`);
}
