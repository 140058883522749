/* Framework */
import { Select, SelectItem, Stack, Text, TextInput } from '@mantine/core';

/* Internal */
// Data
import { useForm } from '@mantine/form';
import { UseFormReturnType } from '@mantine/form/lib/types';
import { useState } from 'react';
import { roleId, selectableRoles } from '../../components/participants/EventJoinLinkTableEntry';
import { EventMemberRole } from '../../models/eventMember.interface';
import { useStyles } from './participantStyles';
//Styling

export interface ParticipantEditFormValues {
  groupId?: string | null;
  role: EventMemberRole;
  emailAddress?: string;
  emailSentAt?: string;
  hasUserJoined?: boolean;

  // derived values
  name?: string;
  emailSentAtStr: string;
  status: string;
  roleId: string;
  selectedGroupId: string;
}

export const useParticipantEditForm = (initialValues: ParticipantEditFormValues) =>
  useForm<ParticipantEditFormValues>({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues,
  });

interface ParticipantEditFormProps {
  inviteParticipantEditForm: UseFormReturnType<ParticipantEditFormValues>;
  groupChoices: SelectItem[];
  confirmRoleChange?: boolean;
}

/**
 * A form to edit a single EventJoinLink
 * Note that if a user has joined we will have a member record and editing the record will
 * use a different form
 */
export function ParticipantEditForm({
  inviteParticipantEditForm,
  groupChoices,
  confirmRoleChange,
}: ParticipantEditFormProps): JSX.Element {
  const { classes } = useStyles();
  const [warningRoleChange, setWarningRoleChange] = useState('');

  const handleRoleChange = (newValue: string) => {
    let newRoleId = inviteParticipantEditForm.values.roleId || roleId(EventMemberRole.Participant);
    let newRole = inviteParticipantEditForm.values.role || EventMemberRole.Participant;
    let newGroupId = inviteParticipantEditForm.values.groupId || null;
    switch (newValue) {
      case roleId(EventMemberRole.Facilitator):
        newRoleId = roleId(EventMemberRole.Facilitator);
        newRole = EventMemberRole.Facilitator;
        // remove groupId if changing to a facilitator
        newGroupId = null;
        break;

      case roleId(EventMemberRole.Participant):
        newRoleId = roleId(EventMemberRole.Participant);
        newRole = EventMemberRole.Participant;
        break;

      default:
    }

    if (
      confirmRoleChange &&
      newRole !== inviteParticipantEditForm.values.role &&
      newRole === EventMemberRole.Facilitator
    ) {
      setWarningRoleChange(
        'Changing this user to a facilitator will remove all bids they have made.'
      );
    }

    inviteParticipantEditForm.setValues({
      roleId: newRoleId,
      role: newRole,
      groupId: newGroupId,
      selectedGroupId: newGroupId || '',
    });
  };

  return (
    <>
      <Stack spacing="md" style={{ width: '100%' }}>
        {inviteParticipantEditForm.values.name && (
          <TextInput
            label="Name"
            disabled
            {...inviteParticipantEditForm.getInputProps('name')}
            style={{ flex: 1 }}
          />
        )}
        {inviteParticipantEditForm.values.emailAddress && (
          <TextInput
            label="Email Address"
            disabled
            {...inviteParticipantEditForm.getInputProps('emailAddress')}
            style={{ flex: 1 }}
          />
        )}
        <TextInput
          label="Invite Sent"
          disabled
          {...inviteParticipantEditForm.getInputProps('emailSentAtStr')}
          style={{ flex: 1 }}
        />
        <TextInput label="Status" disabled {...inviteParticipantEditForm.getInputProps('status')} />
        <Select
          label="Role"
          data={selectableRoles}
          placeholder="Select Role"
          {...{
            ...inviteParticipantEditForm.getInputProps('roleId'),
            onChange: handleRoleChange,
          }}
          style={{ flex: 1 }}
        />
        {warningRoleChange.length ? <Text c="warning.2">{warningRoleChange}</Text> : null}
        <Select
          label="Table"
          disabled={inviteParticipantEditForm.values.role === EventMemberRole.Facilitator}
          data={groupChoices}
          {...inviteParticipantEditForm.getInputProps('selectedGroupId')}
          style={{ flex: 1 }}
        />
      </Stack>
    </>
  );
}
