import { ActionIcon, Box, Card, Center, Group, Text, useMantineTheme } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import styles from '../eventModal.module.css';
import { EventTemplateType, EventType } from '../NewEventModal';

import { Dispatch, SetStateAction } from 'react';

type EventCreationCardProps = {
  event: EventTemplateType;
  selectedEvent?: string;
  enabled?: boolean;
  setSelectedEvent: Dispatch<SetStateAction<string | undefined>>;
};

export function EventCreationCard({
  event,
  selectedEvent,
  enabled,
  setSelectedEvent,
}: EventCreationCardProps): JSX.Element {
  const theme = useMantineTheme();

  const eventDescription = (eventType: EventType) => {
    switch (true) {
      case eventType === EventType.CUSTOM:
        return enabled
          ? `Fully customize an event with your own items and event details.`
          : `Contact your Scaled Agile Account Manager to create events with your own data.`;
      case eventType === EventType.TTC:
        return enabled
          ? `Create an event with Terrific Transport Corporation items and details.`
          : `Sign in with SAFe Studio to create demo events.`;
      case eventType === EventType.RENOVATION:
        return enabled
          ? `Create an event with home renovation items and details.`
          : `Sign in with SAFe Studio to create demo events.`;
      default:
        break;
    }
  };
  return (
    <>
      <Box>
        <Card
          shadow={selectedEvent === event.id ? 'md' : ''}
          padding="sm"
          radius="md"
          h={250}
          withBorder
          style={{
            cursor: enabled ? 'pointer' : 'not-allowed',
            borderColor: selectedEvent === event.id ? theme.colors.teal[1] : theme.colors.gray[4],
          }}
          className={selectedEvent !== event.id && enabled ? styles.eventCard : ''}
          onClick={() => (enabled ? setSelectedEvent(event.id) : null)}
        >
          <Card.Section>
            <Box
              h={100}
              w="100%"
              style={{ background: enabled ? '' : theme.colors.gray[2] }}
              className={
                selectedEvent === event.id ? styles.selectedBackground : styles.cardBackground
              }
            >
              <Center pt="xs">{event.icon}</Center>
            </Box>
          </Card.Section>

          <Group spacing="space-between" mt="md" mb="xs">
            <Text fw={800} size="sm" c={enabled ? '' : theme.colors.gray[5]}>
              {' '}
              {event.name}
            </Text>
          </Group>

          <Text size="xs" c={enabled ? 'dimmed' : theme.colors.gray[5]}>
            {eventDescription(event.id)}
          </Text>
        </Card>
        {selectedEvent === event.id && (
          <Box pos="relative">
            <Center>
              <ActionIcon
                radius="50%"
                variant="subtle"
                aria-label="Event Creation"
                size="30"
                mx="2"
                style={{
                  backgroundColor: theme.colors.gray[0],
                  color: theme.colors.gray[5],
                  border: `1px solid ${theme.colors.teal[1]}`,
                  padding: 8,
                  position: 'absolute',
                  top: -22,
                }}
              >
                <IconCheck size={20} stroke={3} style={{ color: theme.colors.sky[5] }} />
              </ActionIcon>
            </Center>
          </Box>
        )}
      </Box>
    </>
  );
}
