// Transform the error of an API call to a human readable error message.

import axios, { AxiosError } from 'axios';
import { FormAlertProps } from './FormAlert';

export function generateErrorMessage(
  error: Error | AxiosError | unknown,
  props: FormAlertProps
): string {
  if (error === undefined || error === null) {
    return '';
  } else if (axios.isAxiosError(error)) {
    // axios error
    // https://axios-http.com/docs/handling_errors
    if (error.response) {
      // The request was made and the server responded with a status code != 2xx
      const httpCode = error.response.status;

      // message "for code" takes presedence
      const customMessageforCode = props.messageForCode?.[httpCode];
      if (customMessageforCode !== undefined) {
        return customMessageforCode;
      }

      // check 4xx custom message
      if (props.message5xx !== undefined && httpCode >= 400 && httpCode < 500) {
        return props.message5xx;
      }

      // check 5xx custom message
      if (props.message5xx !== undefined && httpCode >= 500 && httpCode < 600) {
        return props.message5xx;
      }

      // fallback, default behavior
      return error.message;
    } else if (error.request) {
      // The request was made but no response was received
      return error.message;
    } else {
      // other error setting up the request
      return error.message;
    }
  } else if (error instanceof Error) {
    // regular error, i.e. from a promise
    return error.message;
  } else if (typeof error === 'string') {
    // error is a string
    return error;
  } else {
    // unknown type
    return 'Unknown Error: ' + typeof error;
  }
}
