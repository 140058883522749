/* Framework */
import { createEmotionCache, MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import rtlPlugin from 'stylis-plugin-rtl';

import { Notifications } from '@mantine/notifications';
import { RouterProvider } from 'react-router-dom';

// Data
import { AxiosInterceptor } from './api/api';
import { AuthProvider } from './hooks/useAuth';
import { router } from './pages/routes';
import { pineappleTheme } from './themes';
import { useEffect, useState } from 'react';
import { getLocale } from './components/layout/components/i18n/i18n-utils';
import { IntlProvider } from 'react-intl';
import { AppRefreshProvider } from './hooks/useAppRefresh';

const languages = [{ lang: 'en', rtl: false }];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      throwOnError: true,
    },
  },
});

/**
 * Top level application component with global providers and routing
 */
const App = () => {
  const [messages, setMessages] = useState<any>();
  const [rtl, setRtl] = useState(false);

  // Just do this once
  useEffect(() => {
    async function loadLocaleData(locale: string): Promise<[{ lang: string; rtl: boolean }, any]> {
      const lang = locale.substring(0, 2).toLowerCase();

      // Check if we find the language in our map, load english otherwise
      let langDesc = languages.find((l) => l.lang === lang);
      if (!langDesc) {
        langDesc = {
          lang: 'en',
          rtl: false,
        };
      }

      /**
       * The used message bundle can also be dynamically loaded
       */
      const m = await import(`./compiled-lang/${langDesc.lang}.json`);
      return [langDesc, m.default];
    }
    async function loadMessages() {
      const [langDesc, messages] = await loadLocaleData(getLocale());
      setMessages(messages);
      setRtl(langDesc.rtl);
    }
    loadMessages();
  }, []);

  const rtlCache = createEmotionCache({
    key: 'mantine-rtl',
    stylisPlugins: [rtlPlugin],
  });

  const dirTheme = pineappleTheme;
  if (rtl) {
    dirTheme.dir = 'rtl';
  } else {
    dirTheme.dir = 'ltr';
  }

  return (
    <MantineProvider
      theme={dirTheme}
      withGlobalStyles
      withNormalizeCSS
      emotionCache={rtl ? rtlCache : undefined}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthProvider>
          <Notifications />
          <AppRefreshProvider>
            <AxiosInterceptor>
              {messages ? (
                <IntlProvider messages={messages} defaultLocale="en" locale={getLocale()}>
                  <RouterProvider router={router} />
                </IntlProvider>
              ) : null}
            </AxiosInterceptor>
          </AppRefreshProvider>
        </AuthProvider>
      </QueryClientProvider>
    </MantineProvider>
  );
};

export default App;
