import { useQuery } from '@tanstack/react-query';
import { QueryKeysMap } from '../models/keys.type';
import { EventJoinLinkApi } from './api';

type Filters = Record<string, never>;

export const inviteLinksKeys: QueryKeysMap<'inviteLinks', Filters> = {
  all: ['inviteLinks'] as const,
  list: () => [...inviteLinksKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...inviteLinksKeys.list(), { ...filters }] as const,
  detail: () => [...inviteLinksKeys.all, 'detail'] as const,
  byId: (eventJoinLinkId: string) => [...inviteLinksKeys.detail(), eventJoinLinkId] as const,
};

export function useInviteLinkByIdQuery(id: string) {
  return useQuery({
    queryKey: inviteLinksKeys.byId(id),
    queryFn: () => EventJoinLinkApi.getInviteLink(id),
    enabled: !!id,
  });
}
