import { generateJsonFromString } from '../components/editor/json-content-conversions';
import { PostBiddingItem } from '../models/bidding-item.interface';
import { PostEvent } from '../models/event.interface';
import { PostGroup } from '../models/group.interface';

type DemoEvent = {
  settings: Omit<PostEvent, 'createdByUserId'>;
  groups: Omit<PostGroup, 'eventId'>[];
  items: Array<
    Omit<
      PostBiddingItem,
      'eventId' | 'createdByUserId' | 'itemTypeId' | 'valueStreamIds' | 'strategicThemeIds'
    > & {
      itemType: string;
      valueStreams: Array<string>;
      strategicThemes: Array<string>;
    }
  >;
};

export const DefaultItemTypes = {
  epic: 'Epic',
  solution: 'Solution',
};

export const ttcMetaData = {
  strategicThemes: {
    1: '1: 3x Commercial Revenue',
    2: '2: Navigation Sensor Quality',
    3: '3: Higher Autonomous Driving Levels',
    4: '4: Intelligent Delivery Platform',
  },
  horizons: {
    1: '1 - Investing + Extracting',
    2: '2 - Emerging',
    3: '3 - Evaluating',
    4: 'H4',
    5: 'H5',
  },
  valueStreams: {
    commercial: 'Commercial',
    transport: 'Transport',
  },
  itemTypes: DefaultItemTypes,
};

export const homeRenoMetaData = {
  strategicThemes: {
    1: 'Improve quality of living',
    2: 'Reduce monthly costs',
    3: 'Improve house function and resale',
  },
  horizons: {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
  },
  valueStreams: {
    interactive: 'Interactive',
    supportive: 'Supportive',
  },
  itemTypes: DefaultItemTypes,
};

export const terrificTransportCorp: DemoEvent = {
  settings: {
    name: 'Terrific Transport Corporation',
    currencyCode: 'EUR',
    isCustomCurrency: false,
    fundingCycle: 'Next 6 Months',
    budget: 6_000_000,
    isDemo: false,
    isSample: false,
    allowUrlJoin: true,
    allowBidding: true,
    description: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: 'Welcome!',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Learn how your bidding input on the items can factor into funding and/or prioritization decisions through participatory budgeting. ',
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'hardBreak',
            },
            {
              text: 'Business Context',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'You and your colleagues work at Terrific Transport Corporation (TTC) and have been invited to a Participatory Budgeting event to influence the funding allocation decisions for the next budget cycle. Remember that the event budget, which is the portfolio budget for the upcoming funding cycle, is insufficient to cover all the desired epics and solution operating costs.',
              type: 'text',
            },
          ],
        },

        {
          type: 'bulletList',
          content: [
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'Focus on balancing the needs of the current business state and the future business state to ensure success.',
                      type: 'text',
                    },
                  ],
                },
              ],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'Put yourself in the shoes of funding decision-makers - if you were an executive, an Architect, or a Solution/Product Manager, how would you fund each initiative?  ',
                      type: 'text',
                    },
                  ],
                },
              ],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'Remember that Solutions should be funded before Epics and that Epics shouldn’t be funded for Solutions that have lost funding. Discuss and negotiate with your tablemates on how to allocate your individual budget toward funding both types of initiatives.',
                      type: 'text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Themes',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Theme 1:',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: ' Increase revenue for the new Value Stream for Autonomous Commercial Vehicles by 300% within 18 months by improving the delivery platform capabilities, including image recognition systems for rapid data analysis',
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Theme 2',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: ': Build and deploy the iNaviGATOR app to improve the quality of sensors, navigation, and edge detection capabilities of all Autonomous Public Transportation Vehicles by 85%',
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Theme 3:',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: ' Achieve higher autonomous driving levels for 80% of all Public Transportation Vehicles',
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Theme 4',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              text: ': Build and integrate the world’s largest interactive platform for intelligent delivery to speed up the development of Commercial and Public Transportation Autonomous Vehicles',
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Please review any other documents or information shared with you in preparation for this activity.',
              type: 'text',
            },
          ],
        },
      ],
    },
  },
  groups: Array(2)
    .fill({ name: '' })
    .map((_, index) => ({ name: `Table ${index + 1}` })),
  items: [
    {
      name: 'Autonomous Commercial Delivery via vans',
      fundingTarget: 2_000_000,
      itemType: 'Solution',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: [],
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Extend to more languages',
      fundingTarget: 100_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: new Array(ttcMetaData.strategicThemes['1']),
      description: generateJsonFromString('Extend the delivery services to other languages'),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Gamification for deliveries',
      fundingTarget: 500_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: new Array(ttcMetaData.strategicThemes['1']),
      description: generateJsonFromString(
        'Provide gamification for ordering process to encourage orders'
      ),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Drone delivery platform',
      fundingTarget: 900_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: new Array(ttcMetaData.strategicThemes['1']),
      description: generateJsonFromString(
        'Provide a capability for delivery of groceries with drones'
      ),
      horizon: ttcMetaData.horizons['3'],
    },
    {
      name: 'Support Apple Pay',
      fundingTarget: 100_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: new Array(ttcMetaData.strategicThemes['1']),
      description: generateJsonFromString('Support Apple Pay as a payment method for delivery'),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Build an Alexa service',
      fundingTarget: 200_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: new Array(ttcMetaData.strategicThemes['1']),
      description: generateJsonFromString('Allow voice ordering through Alexa- capable devices'),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Local Farm delivery of perishable goods',
      fundingTarget: 100_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: new Array(ttcMetaData.strategicThemes['1']),
      description: generateJsonFromString(
        'Test the ability to deliver perishable goods within a certain time and distance range'
      ),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Image recognition system',
      fundingTarget: 700_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.commercial),
      strategicThemes: new Array(ttcMetaData.strategicThemes['1']),
      description: generateJsonFromString(
        'Improve the advanced image control and recognition systems to interpret sensory information for identifying appropriate navigation paths with 90%'
      ),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Autonomous Public Transport via Buses ',
      fundingTarget: 1_000_000,
      itemType: 'Solution',
      valueStreams: new Array(ttcMetaData.valueStreams.transport),
      strategicThemes: [],
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Move to autonomous Level 5',
      fundingTarget: 600_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.transport),
      strategicThemes: new Array(ttcMetaData.strategicThemes['3']),
      description: generateJsonFromString(
        'Enable Level 5 autonomy which will allow deliveries for government customers in off road conditions, opening a huge market for the company'
      ),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Upgrade sensors',
      fundingTarget: 500_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.transport),
      strategicThemes: new Array(ttcMetaData.strategicThemes['2']),
      description: generateJsonFromString(
        'Upgrade the sensors of the vehicle to ensure faster recognition of dynamic road situations'
      ),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Enhance navigation',
      fundingTarget: 300_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.transport),
      strategicThemes: new Array(ttcMetaData.strategicThemes['2']),
      description: generateJsonFromString('Enhance navigation capabilities to shorten drive time'),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Edge Detection capability',
      fundingTarget: 800_000,
      itemType: 'Epic',
      valueStreams: new Array(ttcMetaData.valueStreams.transport),
      strategicThemes: new Array(ttcMetaData.strategicThemes['2']),
      description: generateJsonFromString(
        'Improve the image recognition system to enable edge detection in low light and low contrast settings'
      ),
      horizon: ttcMetaData.horizons['2'],
    },
    {
      name: 'Delivery platform partnership',
      fundingTarget: 700_000,
      itemType: 'Epic',
      valueStreams: [ttcMetaData.valueStreams.transport, ttcMetaData.valueStreams.commercial],
      strategicThemes: new Array(ttcMetaData.strategicThemes['4']),
      description: generateJsonFromString(
        'Partner with a company that has a delivery platform. Note: If you fund ’Delivery Platform Partnership you cannot fund ‘Delivery Platform Development’ and cannot fund ‘Fleet Management Integration’'
      ),
      horizon: ttcMetaData.horizons['3'],
    },
    {
      name: 'Delivery platform development',
      fundingTarget: 800_000,
      itemType: 'Epic',
      valueStreams: [ttcMetaData.valueStreams.transport, ttcMetaData.valueStreams.commercial],
      strategicThemes: new Array(ttcMetaData.strategicThemes['4']),
      description: generateJsonFromString(
        'Build an in-house delivery platform to support all delivery needs. Note: If you fund ’Delivery Platform Development you cannot fund ‘Delivery Platform Partnership’'
      ),
      horizon: ttcMetaData.horizons['3'],
    },
    {
      name: 'Fleet management delivery platform integration',
      fundingTarget: 200_000,
      itemType: 'Epic',
      valueStreams: [ttcMetaData.valueStreams.transport, ttcMetaData.valueStreams.commercial],
      strategicThemes: new Array(ttcMetaData.strategicThemes['4']),
      description: generateJsonFromString(
        'Integrate the new delivery platform with the fleet management suite. Note: You can only fund this if ‘Delivery platform development’ is funded'
      ),
      horizon: ttcMetaData.horizons['3'],
    },
    {
      name: 'Continuous Delivery Pipeline',
      fundingTarget: 300_000,
      itemType: 'Epic',
      valueStreams: [ttcMetaData.valueStreams.transport, ttcMetaData.valueStreams.commercial],
      strategicThemes: new Array(ttcMetaData.strategicThemes['4']),
      description: generateJsonFromString('Allow Release on Demand on new features'),
      horizon: ttcMetaData.horizons['3'],
    },
  ],
};

export const homeRenovation: DemoEvent = {
  settings: {
    name: 'Home Renovation',
    currencyCode: 'EUR',
    isCustomCurrency: false,
    fundingCycle: 'Next 6 Months',
    budget: 45_000,
    isDemo: false,
    isSample: false,
    allowUrlJoin: true,
    allowBidding: true,
    description: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: 'Welcome!',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Using a home renovation as a lighthearted example, learn how investment prioritization with input from a variety of stakeholders can provide helpful insights and drive decision-making conversations.',
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'hardBreak',
            },
            {
              text: 'Business Context',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'You and your fellow participants are members of a household and have saved up enough money to make some investments in the property. ',
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              text: 'You have been invited by the head of the household to influence the prioritization and allocation decision. The event budget, which is the available renovation budget you have saved up, is insufficient to cover all the desired epics and solutions.',
              type: 'text',
            },
          ],
        },
        {
          type: 'paragraph',
        },
        {
          type: 'bulletList',
          content: [
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'You plan to live in this house for three more years, then sell at a profit. Housing values in your area have been growing steadily and are projected to continue to grow.',
                      type: 'text',
                    },
                  ],
                },
              ],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'Focus on balancing the needs of the next three years with the desire to make a profit when selling.',
                      type: 'text',
                    },
                  ],
                },
              ],
            },
            {
              type: 'listItem',
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: 'Put yourself in the shoes of the decision-makers - if you were the head of household, how would you fund each initiative?',
                      type: 'text',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'paragraph',
          content: [
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Themes',
              type: 'text',
              marks: [
                {
                  type: 'bold',
                },
              ],
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Theme 1: Improve your quality of living in the house during the three years you remain in the house–and for the buyer who owns it next',
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Theme 2: Reduce monthly costs during the three years you remain in the house, knowing that cost efficiency will also appeal to potential buyers in the future sales process as well',
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'Strategic Theme 3: Improve house function and resale to make the space more functional while you live there and also give the best chance at a profitable sale in three years',
              type: 'text',
            },
          ],
        },
      ],
    },
  },
  groups: Array(2)
    .fill({ name: '' })
    .map((_, index) => ({ name: `Table ${index + 1}` })),
  items: [
    {
      name: 'Add a half bath',
      fundingTarget: 5_000,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.interactive],
      strategicThemes: [homeRenoMetaData.strategicThemes['1']],
      description: generateJsonFromString(
        'The house has one full bathroom (toilet/lavatory), which has been updated in the last 5 years. It has a large vanity and a bathtub/shower combination and is located convenient to the bedrooms, but not the living/dining rooms. The half bath will be small--just a toilet and sink--but located close to the living/dining rooms for guest convenience.'
      ),
      horizon: homeRenoMetaData.horizons['1'],
    },
    {
      name: 'Replace the old refrigerator and stove with more energy-efficient models',
      fundingTarget: 3_000,
      itemType: 'Epic',
      valueStreams: [
        homeRenoMetaData.valueStreams.interactive,
        homeRenoMetaData.valueStreams.supportive,
      ],
      strategicThemes: [homeRenoMetaData.strategicThemes['2']],
      description: generateJsonFromString(
        'Replacing the existing refrigerator and stove will save 40% on monthly utility costs.'
      ),
      horizon: homeRenoMetaData.horizons['1'],
    },
    {
      name: 'Modernize the heating and cooling system',
      fundingTarget: 15_000,
      itemType: 'Solution',
      valueStreams: [
        homeRenoMetaData.valueStreams.interactive,
        homeRenoMetaData.valueStreams.supportive,
      ],
      strategicThemes: [
        homeRenoMetaData.strategicThemes['1'],
        homeRenoMetaData.strategicThemes['2'],
      ],
      description: generateJsonFromString(
        'Modern heating and cooling will save 25% on monthly utility costs. The cooling system will also not require placing cooling units in the windows during the warm months anymore.'
      ),
      horizon: homeRenoMetaData.horizons['2'],
    },
    {
      name: 'Finish the basement living room',
      fundingTarget: 10_000,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.interactive],
      strategicThemes: [
        homeRenoMetaData.strategicThemes['1'],
        homeRenoMetaData.strategicThemes['3'],
      ],
      description: generateJsonFromString(
        'The basement has enough floorspace and ceiling height to become legally finished square footage. It is twice as large as the main floor living room, which is only big enough for the family to use at the same time, and have more space for party guests would make it easier to have parties.'
      ),
      horizon: homeRenoMetaData.horizons['1'],
    },
    {
      name: 'Improve the lighting in main floor living room',
      fundingTarget: 1_000,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.supportive],
      strategicThemes: [],
      description: generateJsonFromString(
        'There is one ceiling light in the main floor living room that only supports low-wattage bulbs, so floor and table lamps are required to provide enough light. If the extra floor and table lamps were replaced with better ceiling lighting, there would be approximately 10% more space for potential seating in the room.'
      ),
      horizon: homeRenoMetaData.horizons['1'],
    },
    {
      name: 'Replace dilapidated garage door and add a remote garage door opener',
      fundingTarget: 7_000,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.interactive],
      strategicThemes: [
        homeRenoMetaData.strategicThemes['3'],
        homeRenoMetaData.strategicThemes['1'],
      ],
      description: generateJsonFromString(
        `The existing garage door has to be opened using both hands. It is very heavy and doesn't move very smoothly. A newer door with an automatic opener would save time when leaving home and returning, and eliminate the risk of muscle strain from the manual lift.`
      ),
      horizon: homeRenoMetaData.horizons['2'],
    },
    {
      name: 'Add a back deck with a new back door',
      fundingTarget: 18_000,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.interactive],
      strategicThemes: [homeRenoMetaData.strategicThemes['3']],
      description: generateJsonFromString(
        'The backyard (back garden) is a nice flat space, but small. Adding an elevated deck with stairs to the yard (garden) and a new back door to access the deck from the house increases the usable outdoor living space by 25%.'
      ),
      horizon: homeRenoMetaData.horizons['2'],
    },
    {
      name: 'Upgrade the electrical wiring to modern standards and enable more outlets',
      fundingTarget: 10_000,
      itemType: 'Solution',
      valueStreams: [homeRenoMetaData.valueStreams.supportive],
      strategicThemes: [homeRenoMetaData.strategicThemes['3']],
      description: generateJsonFromString(
        `The electrical appliances, outlets, and fixtures in the house work without issue, but the owners knew when they purchased the home that the wiring doesn't meet modern standards. The occupants have to unplug an appliance or electronic in order to plug in another because there are not enough outlets for all their appliances and electronics. More outlets cannot be added without upgrading the electrical system.`
      ),
      horizon: homeRenoMetaData.horizons['1'],
    },
    {
      name: 'Remove unused landline telephone jacks and cabling',
      fundingTarget: 500,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.supportive],
      strategicThemes: [],
      description: generateJsonFromString(
        'The house still has telephone jacks and cabling, in addition to the jacks and cables used for the internet. The occupants do not use the phone jacks and cabling, and for future resale, buyers in this market prefer not to see telephone jacks anymore.'
      ),
      horizon: homeRenoMetaData.horizons['0'],
    },
    {
      name: 'Add more electrical outlets',
      fundingTarget: 2_500,
      itemType: 'Epic',
      valueStreams: [
        homeRenoMetaData.valueStreams.supportive,
        homeRenoMetaData.valueStreams.interactive,
      ],
      strategicThemes: [homeRenoMetaData.strategicThemes['3']],
      description: generateJsonFromString(
        'By increasing the number of electrical outlets (sockets) in the house about 25%, there will be room for all necessary electrical items to stay plugged in continuously. Dependency: Must have Solution "Upgrade the electrical wiring to modern standards and enable more outlets" to make this effort possible.'
      ),
      horizon: homeRenoMetaData.horizons['1'],
    },
    {
      name: 'Explore a home golf driving range in the backyard',
      fundingTarget: 250,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.interactive],
      strategicThemes: [homeRenoMetaData.strategicThemes['1']],
      description: generateJsonFromString(
        'Hire an architect to design a physical driving range with net for installing in the backyard (back garden). The design needs to provide a way to practice golf from home,  a sustainable solution within household budget, and avoiding damage to neighboring properties. Dimensions need to support challenging shots, structure needs to withstand any severe local weather, and space requirements need to allow for pets and children to enjoy the rest of the backyard (back garden).'
      ),
      horizon: homeRenoMetaData.horizons['3'],
    },
    {
      name: 'Explore a home digital golf driving range indoors',
      fundingTarget: 250,
      itemType: 'Epic',
      valueStreams: [homeRenoMetaData.valueStreams.interactive],
      strategicThemes: [homeRenoMetaData.strategicThemes['1']],
      description: generateJsonFromString(
        'Work with a consultant at the AV shop to provide equipment list and layout/setup instructions for a digital indoor driving range. The design needs to provide a way to practice golf from home and  a sustainable solution within household budget. Dimensions need to work with the existing layout and equipment requirements need to allow for the family to enjoy other digital activities without a lot of hassle (e.g., videogames, streaming services).'
      ),
      horizon: homeRenoMetaData.horizons['3'],
    },
  ],
};
