export function linkToEvents(): string {
  return `/events`;
}

export function linkToEvent(eventId: string, isSample?: boolean): string {
  return isSample ? `/events/${eventId}?sampleEvent=true` : `/events/${eventId}`;
}

export function linkToEventEditing(eventId: string): string {
  return `/events/${eventId}?editOverview=true`;
}

export function linkToManageDetails(eventId: string, isSample?: boolean): string {
  return isSample ? `/events/${eventId}/details?sampleEvent=true` : `/events/${eventId}/details`;
}

export function linkToManageItems(eventId: string, isSample?: boolean): string {
  return isSample ? `/events/${eventId}/items?sampleEvent=true` : `/events/${eventId}/items`;
}

export function linkToManagePeople(eventId: string, isSample?: boolean): string {
  return isSample ? `/events/${eventId}/people?sampleEvent=true` : `/events/${eventId}/people`;
}

export function linkToManageTables(eventId: string, isSample?: boolean): string {
  return isSample
    ? `/events/${eventId}/people?view=tables&sampleEvent=true`
    : `/events/${eventId}/people?view=tables`;
}

export function linkToEventAnalysis(eventId: string, isSample?: boolean): string {
  return isSample ? `/events/${eventId}/analysis?sampleEvent=true` : `/events/${eventId}/analysis`;
}

export function linkToEventAnalysisCharts(eventId: string, isSample?: boolean): string {
  return isSample
    ? `/events/${eventId}/analysis?view=charts&sampleEvent=true`
    : `/events/${eventId}/analysis?view=charts`;
}

export function linkToEventBidding(eventId: string, groupId: string, isSample?: boolean): string {
  return isSample
    ? `/events/${eventId}/groups/${groupId}?sampleEvent=true`
    : `/events/${eventId}/groups/${groupId}`;
}

export function linkToCreatedEvents(): string {
  return `/events?type=created`;
}

export function linkToJoinedEvents(): string {
  return `/events`;
}
