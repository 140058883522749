import { Button, Text } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useInviteLinkByIdQuery } from '../../api/invite-link.queries';
import { EventJoinLink } from '../../models/event-join-link.interface';

interface CopyInviteLinkButtonProps {
  eventJoinLink: EventJoinLink;
}

export function CopyInviteLinkButton({
  eventJoinLink,
}: CopyInviteLinkButtonProps): JSX.Element | null {
  const clipboard = useClipboard({ timeout: 750 });
  const { data: inviteLink, isFetched } = useInviteLinkByIdQuery(eventJoinLink.id);

  return isFetched ? (
    <Button variant="subtle" size="xs" onClick={() => clipboard.copy(inviteLink?.url)}>
      <Text align="center">{clipboard.copied ? 'Copied invite' : 'Copy invite'}</Text>
    </Button>
  ) : null;
}
