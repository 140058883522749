import { useMemo } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { em, getBreakpointValue, useMantineTheme } from '@mantine/core';

export type UseScreenResolution = {
  smallScreen: boolean;
  mediumScreen: boolean;
  largeScreen: boolean;
};

export function useGetScreenSize(): UseScreenResolution {
  const theme = useMantineTheme();
  const smallScreen = useMediaQuery(
    `(max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`
  );
  const mediumScreen = useMediaQuery(
    `(max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`
  );
  const largeScreen = useMediaQuery(
    `(max-width: ${em(getBreakpointValue(theme.breakpoints.lg) - 1)})`
  );

  const value = useMemo(() => {
    return {
      smallScreen,
      mediumScreen,
      largeScreen,
    };
  }, [smallScreen, mediumScreen, largeScreen]);

  return value;
}

export function useGetContentOffeset(): { offSet: number } {
  const theme = useMantineTheme();
  const base = 200;
  const responsiveBase = 130;
  const smallScreen = useMediaQuery(
    `(max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`
  );

  const offSet = smallScreen ? responsiveBase : base;

  const value = useMemo(() => {
    return {
      offSet: !smallScreen ? offSet + 20 : offSet,
    };
  }, [offSet, smallScreen]);

  return value;
}
