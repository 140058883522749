import { Button, Checkbox, Group, Radio, Stack, Text } from '@mantine/core';
import { ContextModalProps, closeModal, openContextModal } from '@mantine/modals';
import { useState } from 'react';

import { useForm } from '@mantine/form';
import { useSendInvitationEmailMutation } from '../../api/event-join-link.queries';
import { EventJoinLink } from '../../models/event-join-link.interface';
import dayjs from 'dayjs';
import { useStyles } from './participantStyles';

export const sendInvitationEmailModalKey = 'sendInvitationEmail';

export function openSendInvitationEmailModal(eventJoinLinks: EventJoinLink[]) {
  const props: SendInvitationEmailModalProps = {
    eventJoinLinks,
  };
  return openContextModal({
    modal: sendInvitationEmailModalKey,
    title: 'Send Multiple Invitations',
    innerProps: props,
    size: 'md',
  });
}

export interface SendInvitationEmailFormValues {
  sendInvitationsTo: Array<'notInvited' | 'notJoined'>;
}

export interface SendInvitationEmailModalProps extends Record<string, unknown> {
  eventJoinLinks: EventJoinLink[];
}

export function SendInvitationEmailModal({
  context,
  id,
  innerProps,
}: ContextModalProps<SendInvitationEmailModalProps>): JSX.Element {
  const { classes } = useStyles();

  const sendInvitation = useSendInvitationEmailMutation();

  const [errorMsg, setErrorMessage] = useState('');

  const form = useForm<SendInvitationEmailFormValues>({
    initialValues: {
      sendInvitationsTo: ['notInvited'],
    },
  });

  const handleSubmit = async () => {
    const eligibleToSend = innerProps.eventJoinLinks.filter((l) => {
      if (l.hasUserJoined) {
        return false;
      }
      if (!l.emailSentAt && form.values.sendInvitationsTo.includes('notInvited')) {
        return true;
      }
      if (form.values.sendInvitationsTo.includes('notJoined')) {
        if (!l.emailSentAt) {
          // we exclude those that never received an email from this group
          return false;
        }

        return dayjs(l.emailSentAt).add(2, 'hours').isBefore(dayjs());
      }
      return false;
    });

    if (!eligibleToSend.length) {
      closeModal(id);
      return;
    }

    const sentInvitations = eligibleToSend.map((l) => sendInvitation.mutateAsync(l.id));

    Promise.all(sentInvitations).then(
      () => {
        closeModal(id);
      },
      (error) => {
        console.log('error sending invitation emails', error);

        const msg = 'Can not send invitation emails: ' + error;
        setErrorMessage(msg);
      }
    );
  };

  return (
    <>
      <Stack spacing={0} style={{ width: '100%' }}>
        <Text size="sm" mb="sm">
          To whom would you like to send invitation emails?
        </Text>
        <Checkbox.Group {...form.getInputProps('sendInvitationsTo')}>
          <Group mt="xs" spacing="xs">
            <Checkbox mb={0} label="People who haven't been invited yet" value="notInvited" />
            <Text size="xs" mt={0} mb="xs" ml={'2.3rem'}>
              Invitation emails will be sent to people who have not yet received an invitation
              email.
            </Text>
            <Checkbox label="People who have been invited but not joined" value="notJoined" />
            <Text size="xs" mt={0} mb="xs" ml={'2.3rem'}>
              Invitation emails will be sent to people who received an invitation email over 2 hours
              ago and have not yet joined.
            </Text>
          </Group>
        </Checkbox.Group>
      </Stack>
      {errorMsg.length ? (
        <Text c="error.1" mt="md">
          {errorMsg}
        </Text>
      ) : null}
      <Group position="center" mt="md">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={!form.values.sendInvitationsTo.length}>
          Send Invitations
        </Button>
      </Group>
    </>
  );
}
