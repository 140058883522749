import { Avatar, rem, Stack, Text, useMantineTheme } from '@mantine/core';
import { Dropzone, DropzoneProps, FileWithPath } from '@mantine/dropzone';
import { IconCloudUpload, IconUpload, IconX } from '@tabler/icons-react';

interface ImportFileDropArea extends Partial<DropzoneProps> {
  onFileDropped: (f: FileWithPath) => void;
  loading?: boolean;
}

export function ImportFileDropArea(props: ImportFileDropArea) {
  const mimeTypes = ['text/csv'];
  const { onFileDropped, loading = false, ...dropZoneProps } = props;
  const theme = useMantineTheme();
  return (
    <Dropzone
      name="import-file-input"
      loading={loading}
      onDrop={(files) => files?.length > 0 && onFileDropped(files[0])}
      onReject={(files) => console.log('rejected files', files)}
      maxSize={3 * 1024 ** 2}
      accept={mimeTypes}
      multiple={false}
      {...dropZoneProps}
    >
      <Stack
        align="center"
        justify="center"
        spacing="xl"
        style={{ minHeight: rem(220), pointerEvents: 'none' }}
      >
        <Dropzone.Accept>
          <IconUpload
            size="3.2rem"
            stroke={1.5}
            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            size="3.2rem"
            stroke={1.5}
            color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Avatar bg="dark" color="black" radius="50%" size="6rem">
            <IconCloudUpload size="3.2rem" stroke={2.5} />
          </Avatar>
        </Dropzone.Idle>

        <Stack align="center" spacing="xs">
          <Text size="1.25rem" inline>
            Select a CSV file to upload
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            or drag and drop here
          </Text>
        </Stack>
      </Stack>
    </Dropzone>
  );
}
