/* Framework */
import { AppShell } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

/* Internal */
// Data
const footerlinks = [
  {
    externalLink: 'https://go.scaledagile.com/Email-Preferences.html',
    label: 'Get News',
  },
  {
    externalLink: 'https://scaledagile.com/privacy-policy/',
    label: 'Privacy Policy',
  },
  {
    externalLink: 'https://scaledagile.com/code-of-conduct/',
    label: 'Code of Conduct',
  },
  {
    externalLink: 'https://scaledagile.com/ccpa-privacy-rights-request/',
    label: 'California Consumer Rights',
  },
  {
    externalLink: 'https://scaledagile.com/remote-training-policy/',
    label: 'Remote Training Policy',
  },
  {
    externalLink: 'https://support.scaledagile.com/s/?language=en_US',
    label: 'Support',
  },
  {
    internalLink: './guest-terms-use',
    label: 'Guest Terms of Use',
  },
];

//components
import { ModalsProvider } from '@mantine/modals';
import { AppFooter } from '../components/layout/footer/AppFooter';
import AppHeader from '../components/layout/AppHeader';
import {
  AdminControlsModal,
  adminControlsModalKey,
} from '../dialogs/event-creation/AdminControlsModal';
import { BiddingItemEditModal, biddingItemEditModalKey } from '../dialogs/BiddingItemEditModal';
import {
  InviteParticipantEditModal,
  inviteParticipantEditModalKey,
} from '../dialogs/participants/InviteParticipantEditModal';
import {
  InviteParticipantsModal,
  inviteParticipantsModalKey,
} from '../dialogs/participants/InviteParticipantsModal';
import { ItemUploadWizard, itemUploadModalKey } from '../dialogs/item-upload/ItemUploadWizard';
import { useAuthContext } from '../hooks/useAuth';
import { connectToPendo } from '../utils/pendo-connector';
import ScrollToTop from '../utils/scroll-to-top';
import { MemberEditModal, memberEditModalKey } from '../dialogs/participants/MemberEditModal';
import * as Sentry from '@sentry/react';
import {
  SendInvitationEmailModal,
  sendInvitationEmailModalKey,
} from '../dialogs/participants/SendInvitationEmailModal';
import { useAppRefreshOnRouteChange } from '../hooks/useAppRefresh';
import { SharedStateProvider } from '../hooks/useSharedState';
import {
  NewEventModal,
  newEventModalKey,
} from '../dialogs/event-creation/new-event-modal/NewEventModal';
import { LeftNavBar } from './navigation/LeftNavBar';

// ModalsProvider has to live within the BrowserRouter. To move the modals around the app's main component seems to be the only
// way if we want to go with a data base route definition.
const modals = {
  [adminControlsModalKey]: AdminControlsModal,
  [newEventModalKey]: NewEventModal,
  [biddingItemEditModalKey]: BiddingItemEditModal,
  [itemUploadModalKey]: ItemUploadWizard,
  [inviteParticipantsModalKey]: InviteParticipantsModal,
  [inviteParticipantEditModalKey]: InviteParticipantEditModal,
  [memberEditModalKey]: MemberEditModal,
  [sendInvitationEmailModalKey]: SendInvitationEmailModal,
};

/**
 * top-level component used by router to provide the app-shell with header
 * Note: It wraps the PageHeader in an ErrorBoundary to swallow any errors occuring in the header
 * which would not be caught by the route's `errorElement`
 */
const Layout = () => {
  const auth = useAuthContext();
  const [opened, setOpened] = useState(false);

  // Will check version differences and reload on route change if there is a difference
  // this hook is required to live inside react-router to use useLocation()
  useAppRefreshOnRouteChange();

  useEffect(() => {
    if (auth.user) {
      connectToPendo(auth.user);
      Sentry.setUser({ id: auth.user.id, ip_address: '{{auto}}' });
    }
  }, [auth.user]);

  return (
    <>
      <ScrollToTop />
      <SharedStateProvider>
        <ModalsProvider modals={modals}>
          <AppShell
            padding={0}
            styles={(theme) => ({
              main: {
                backgroundColor: theme.colors.gray[1],
                minHeight: `calc(100vh - (var(--mantine-header-height, 0px)))`,
              },
            })}
            fixed
            header={<AppHeader opened={opened} setOpened={setOpened} user={auth.user}></AppHeader>}
            footer={<AppFooter links={footerlinks} />}
            navbar={<LeftNavBar opened={opened} setOpened={setOpened} user={auth.user} />}
          >
            <Outlet />
          </AppShell>
        </ModalsProvider>
      </SharedStateProvider>
    </>
  );
};

export default Layout;
