import { Button, Center } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';
import { useEventSubscription } from '../../api/websocket';
import { useRequiredParam } from '../../hooks/useRequiredParam';
import EmptyState from '../loaders/empty-state/EmptyState';
import { useDebounce } from '../../hooks/useDebounce';

// enable websocket subscription for a single event and show error page if disconnected
export function RequireEventSubscription({ children }: { children: ReactNode }) {
  const eventId = useRequiredParam('eventId');
  const opened = useEventSubscription(eventId);
  const [showDisconnectedMsg, setShowDisconnectedMsg] = useState(false);

  // use a debounce so that the disconnected message only shows after
  // being disconnected for more than 5 seconds.
  // in most cases, the user is unaware of temporary connection issues
  const checkDisconnectedDebounced = useDebounce(5000, () => {
    const isDisconnected = !opened;

    if (showDisconnectedMsg !== isDisconnected) {
      setShowDisconnectedMsg(isDisconnected);
    }
  });

  useEffect(() => {
    // call the function whenever "opened" changes
    checkDisconnectedDebounced();
  }, [checkDisconnectedDebounced, opened]);

  const primaryAction = (
    <Button mt="xl" onClick={() => window.location.reload()}>
      Refresh Page
    </Button>
  );

  if (!opened && showDisconnectedMsg) {
    return (
      <>
        <Center mt={'15%'}>
          <EmptyState
            title={'There is a temporary connection issue. Please wait or refresh the page.'}
            primaryAction={primaryAction}
          />
        </Center>
      </>
    );
  } else {
    return <>{children}</>;
  }
}
