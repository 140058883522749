import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { QueryKeysMap } from '../models/keys.type';
import { PostStrategicTheme, StrategicTheme } from '../models/strategicTheme.interface';
import { StrategicThemeApi } from './api';

type Filters = {
  eventId?: string;
};

export const strategicThemeKeys: QueryKeysMap<'strategicThemes', Filters> = {
  all: ['strategicThemes'] as const,
  list: () => [...strategicThemeKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...strategicThemeKeys.list(), { ...filters }],
  detail: () => [...strategicThemeKeys.all, 'detail'] as const,
  byId: (id: string) => [...strategicThemeKeys.detail(), id] as const,
};

export function useStrategicThemesByEventIdQuery(eventId: string) {
  return useQuery({
    queryKey: strategicThemeKeys.filter({ eventId }),
    queryFn: () => StrategicThemeApi.getAll(eventId),
  });
}

export function useStrategicThemeByIdQuery(
  eventId: string,
  strategicThemeId?: string
): StrategicTheme | undefined {
  const { data: strategicThemes, isFetched } = useStrategicThemesByEventIdQuery(eventId);

  const result = useMemo(() => {
    if (isFetched && strategicThemeId) {
      return strategicThemes?.find((v) => v.id === strategicThemeId);
    }
    return undefined;
  }, [strategicThemeId, strategicThemes, isFetched]);

  return result;
}

export function useCreateStrategicThemeMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      eventId,
      strategicTheme,
    }: {
      eventId: string;
      strategicTheme: PostStrategicTheme;
    }) => StrategicThemeApi.createStrategicTheme(eventId, strategicTheme),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: strategicThemeKeys.all }),
  });
}

export function useUpdateStrategicThemeMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      eventId,
      strategicTheme,
    }: {
      eventId: string;
      strategicTheme: StrategicTheme;
    }) => StrategicThemeApi.updateStrategicTheme(eventId, strategicTheme),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: strategicThemeKeys.all }),
  });
}

export function useDeleteStrategicThemeMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ eventId, strategicThemeId }: { eventId: string; strategicThemeId: string }) =>
      StrategicThemeApi.deleteStrategicTheme(eventId, strategicThemeId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: strategicThemeKeys.all });
    },
  });
}
