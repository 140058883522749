import { Button, Center } from '@mantine/core';
import axios, { AxiosError } from 'axios';
import { useRouteError } from 'react-router-dom';
import { FormAlert } from '../form/form-alert/FormAlert';
import * as Sentry from '@sentry/react';

function renderErrorShell(inner: JSX.Element): JSX.Element {
  return <Center mt={'15%'}>{inner}</Center>;
}

function renderAxiosError(error: AxiosError): JSX.Element {
  Sentry.captureException(error);
  return renderErrorShell(
    <FormAlert
      title="There was an unexpected error."
      link={
        <Center>
          <Button mt="sm" onClick={() => location.reload()}>
            Click Here to Refresh
          </Button>
        </Center>
      }
      error={error}
    />
  );
}

export function DefaultErrorComponent() {
  const error = useRouteError();
  const isAxiosError = error && axios.isAxiosError(error);

  if (isAxiosError) {
    return renderAxiosError(error);
  } else {
    Sentry.captureException(error);
    return renderErrorShell(
      <FormAlert
        title="There was an unexpected error."
        link={
          <Center>
            <Button mt="sm" onClick={() => location.reload()}>
              Click Here to Refresh
            </Button>
          </Center>
        }
        error={error}
      />
    );
  }
}
