import { createStyles, em, getBreakpointValue, rem } from '@mantine/core';

import ringPattern from '../../assets/images/svg/tree_ring.svg';
export const useStyles = createStyles((theme) => ({
  overviewContainer: {
    padding: 20,
  },
  overviewWelcome: {
    backgroundColor: theme.colors.teal[0],
    backgroundImage: `url(${ringPattern})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
  },
  eventNameInput: {
    width: '50%',
  },
  addEvent: {
    marginRight: theme.spacing.sm,
  },
  tableHeader: {
    position: 'sticky',
    background: theme.colors.gray[0],
    boxShadow: `0 4px 2px -2px ${theme.fn.rgba(theme.colors.gray[5], 0.15)}`,
    transition: 'top 180ms ease-out',
    zIndex: 3,
    color: theme.colors.gray[4],
  },
  tableCell: {
    color: theme.colors.gray[5],
  },
  hideCellMedium: {
    color: theme.colors.gray[5],
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.md) - 1)})`]: {
      display: 'none',
    },
  },
  hideCellLarge: {
    color: theme.colors.gray[5],
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.lg) - 1)})`]: {
      display: 'none',
    },
  },
  deleteButton: {
    color: theme.colors.warning[2],
    '&:hover': {
      background: 'white',
      color: theme.colors.error[0],
    },
  },
  editIcon: {
    cursor: 'pointer',
    color: theme.colors.teal[1],
    '&:hover': {
      color: theme.colors.teal[2],
      transform: 'scale(1.1)',
    },
  },
  deleteIcon: {
    cursor: 'pointer',
    color: theme.colors.warning[2],
    '&:hover': {
      color: theme.colors.error[1],
      transform: 'scale(1.1)',
    },
  },
  iconOff: {
    color: theme.colors.gray[5],
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  iconButtonIcon: {
    color: theme.colors.sky[5],
  },
  iconButtonText: {
    color: theme.colors.sky[5],
    marginLeft: theme.spacing.xs,
  },
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },

  mutedText: {
    color: theme.colors.gray[5],
  },
}));
