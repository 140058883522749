import { Button, Group, Text } from '@mantine/core';
import { closeModal, ContextModalProps, openConfirmModal, openContextModal } from '@mantine/modals';
import { useState } from 'react';

import {
  useCreateBiddingItemMutation,
  useUpdateBiddingItemMutation,
} from '../api/bidding-item.queries';
import {
  BiddingItemForm,
  BiddingItemFormValues,
  emptyItemFormValue,
  useBiddingItemForm,
} from '../components/bidding/bidding-items/BiddingItemForm';
import { useAuthContext } from '../hooks/useAuth';
import { BiddingItem } from '../models/bidding-item.interface';
import { BidApi } from '../api/api';

export const biddingItemEditModalKey = 'biddingItemEdit';

export function openBiddingItemEditModal(item: BiddingItem) {
  const props: BiddingItemEditModalProps = {
    createItem: false,
    editItem: item,
    eventId: item.eventId,
  };
  return openContextModal({
    modal: biddingItemEditModalKey,
    title: 'Edit Item',
    innerProps: props,
    size: 'lg',
  });
}

export function openBiddingItemCreateModal(eventId: string) {
  const props: BiddingItemEditModalProps = {
    createItem: true,
    editItem: null,
    eventId,
  };
  return openContextModal({
    modal: biddingItemEditModalKey,
    title: 'Add Item',
    innerProps: props,
    size: 'lg',
  });
}

export interface BiddingItemEditModalProps extends Record<string, unknown> {
  createItem: boolean;
  editItem: BiddingItem | null;
  eventId: string;
}

export function formValuesFromItem(bi: BiddingItem | null): BiddingItemFormValues {
  return {
    name: bi?.name || '',
    description: bi?.description || null,
    fundingTarget: bi?.fundingTarget || 0,
    horizon: bi?.horizon || '',
    itemTypeId: bi?.itemTypeId || '',
    valueStreamIds: bi && bi.valueStreamIds.length > 0 ? bi.valueStreamIds : [],
    strategicThemeIds: bi && bi.strategicThemeIds.length > 0 ? bi.strategicThemeIds : [],
  };
}

export function BiddingItemEditModal({
  context,
  id,
  innerProps,
}: ContextModalProps<BiddingItemEditModalProps>): JSX.Element {
  const initialValues = innerProps.createItem
    ? emptyItemFormValue
    : formValuesFromItem(innerProps.editItem);

  const itemForm = useBiddingItemForm(initialValues);

  const submitLabel = innerProps.createItem ? 'Create Item' : 'Update Item';

  const auth = useAuthContext(); // TODO: remove this, when createdByUserId does not need to be sent to server
  const createMutation = useCreateBiddingItemMutation();
  const updateMutation = useUpdateBiddingItemMutation();

  const [errorMsg, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    if (innerProps.createItem) {
      createMutation
        .mutateAsync({
          ...itemForm.values,
          valueStreamIds: itemForm.values.valueStreamIds ? itemForm.values.valueStreamIds : [],
          strategicThemeIds: itemForm.values.strategicThemeIds
            ? itemForm.values.strategicThemeIds
            : [],
          eventId: innerProps.eventId,
        })
        .then(
          () => {
            closeModal(id);
          },
          (error) => {
            const msg = 'Can not create item. ' + error;
            setErrorMessage(msg);
          }
        );
    } else if (innerProps.editItem !== null) {
      const itemId = innerProps.editItem.id;
      const bids = await BidApi.getAll({
        biddingItemId: itemId,
      });
      const updateItem = () => {
        updateMutation
          .mutateAsync({
            ...itemForm.values,
            valueStreamIds: itemForm.values.valueStreamIds ? itemForm.values.valueStreamIds : [],
            strategicThemeIds: itemForm.values.strategicThemeIds
              ? itemForm.values.strategicThemeIds
              : [],
            id: itemId,
          })
          .then(
            () => {
              closeModal(id);
            },
            (error) => {
              const msg = 'Can not update item. ' + error;
              setErrorMessage(msg);
            }
          );
      };
      if (bids?.length && itemForm.values.fundingTarget !== innerProps.editItem.fundingTarget) {
        openConfirmModal({
          title: 'Confirm Item Update',
          children:
            itemForm.values.fundingTarget < innerProps.editItem.fundingTarget ? (
              <Text size="sm">
                This item has bids placed upon it. Reducing the cost will reduce participants&apos;
                bids. Would you like to proceed?
              </Text>
            ) : (
              <Text size="sm">
                This item has bids placed upon it. Increasing the cost will cause the item&apos;s
                percent funded to change. Would you like to proceed?
              </Text>
            ),
          labels: { confirm: 'Update Item', cancel: 'Cancel' },
          onConfirm: () => updateItem(),
        });
      } else {
        updateItem();
      }
    } else {
      setErrorMessage('Invalid data condition, can not update item.');
    }
  };

  return (
    <>
      <BiddingItemForm itemForm={itemForm} eventId={innerProps.eventId} />
      {errorMsg.length ? (
        <Text c="error.1" mt="md">
          {errorMsg}
        </Text>
      ) : null}
      <Group position="center" mt="md">
        <Button variant="default" onClick={() => context.closeModal(id)}>
          Cancel
        </Button>
        <Button disabled={itemForm.isValid() === false} onClick={handleSubmit}>
          {submitLabel}
        </Button>
      </Group>
    </>
  );
}
