import { UserDataFromJwt } from '../hooks/useAuth';

/**
 * This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
 * This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
 * Call this function in your authentication promise handler or callback when your visitor and account id values are available
 * Please use Strings, Numbers, or Bools for value types.
 */
export function connectToPendo(user: UserDataFromJwt) {
  const pendoId = user.pendoVisitorId || `cofund-user-${user.id}`;
  const accountId = user.pendoAccountId || `safe-cofund`;
  console.debug(
    `Should we connect user to pendo as ${pendoId} with accountId ${accountId}: ${
      import.meta.env.VITE_PENDO_ENABLED
    }`,
    import.meta.env,
    user
  );

  // If the VITE_PENDO_ENABLED is not set in the environment we default to enabling pendo
  // To disable it, specifically set the flag to false, either in the build, in compose, or the environment
  if (
    import.meta.env.VITE_PENDO_ENABLED?.toLowerCase() == null ||
    import.meta.env.VITE_PENDO_ENABLED?.toLowerCase() === 'true'
  ) {
    (window as any).pendo?.initialize({
      visitor: {
        id: pendoId, // Required if user is logged in, default creates anonymous ID
      },
      account: {
        id: accountId,
      },
    });
  }
}
