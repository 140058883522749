import { useParams } from 'react-router-dom';

/**
 * returns a type-safe string from `useParams`, or throws an error
 */
export function useRequiredParam(name: string): string {
  const params = useParams();
  const value = params[name];

  if (value === undefined) {
    throw new Error(
      `component can not find required parameter '${name}' - make sure it is specified on the route`
    );
  } else {
    return value;
  }
}
