export const EnvNames = {
  DEV: 'dev',
  PREVIEW: 'preview',
  PRODUCTION: 'production',
};

/**
 * Returns a lowercase environment label to be used in tooling/monitoring
 * based on the hostname of the application
 * @param hostname
 */
export function envFromHostname(hostname: string): string {
  switch (hostname) {
    case 'cofund.scaledagile.com':
      return EnvNames.PRODUCTION;

    case 'cofund-preview.scaledagile.com':
      return EnvNames.PREVIEW;

    case 'cofund-dev.scaledagile.com':
      return EnvNames.DEV;

    default:
      return 'localhost';
  }
}
