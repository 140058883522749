/**
 * Remove any potentially malicious links.
 * Currently this removes any link `href` with "javascript" in it.
 */
export function sanitizeEditorJson(jsonObj: Record<string | number, any>): boolean {
  let hasChanged = false;
  if (jsonObj !== null && typeof jsonObj === 'object') {
    Object.entries(jsonObj).forEach(([key, value]) => {
      if (key === 'marks') {
        hasChanged = hasChanged || sanitizeMarks(value);
      } else {
        hasChanged = hasChanged || sanitizeEditorJson(value);
      }
    });
  }
  return hasChanged;
}

function sanitizeMarks(marks: Array<Record<string | number, any>>): boolean {
  let hasChanged = false;
  if (marks !== null && typeof marks === 'object') {
    // Iterate backwards, so removing an element will not cause problems with array len
    for (let i = marks.length - 1; i >= 0; i--) {
      const value = marks[i];
      if (
        value?.type === 'link' &&
        (value?.attrs?.href as string)?.toLowerCase()?.includes('javascript')
      ) {
        // bad link, remove whole mark entry
        marks.splice(i, 1);
        hasChanged = true;
      }
    }
  }
  return hasChanged;
}
