export const valueStreamName = 'Value Streams';
export const strategicThemeName = 'Strategic Themes';

export const scenarioOptions = [
  { value: 'bidAmount', label: 'Avg Amount Bid' },
  { value: 'bidPercent', label: 'Avg Percent of Cost' },
  { value: 'totalBids', label: 'Popularity' },
];

export const defaultAnalysisSort = [
  {
    id: 'bidPercent',
    desc: true,
  },
  {
    id: 'bidAmount',
    desc: true,
  },
  {
    id: 'totalBids',
    desc: true,
  },
];

export const defaultNumberGroups = 1;

export const numberEventGroups = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

export const eventHorizons = [
  { value: '0 - Retiring', label: '0 - Retiring' },
  { value: '1 - Investing + Extracting', label: '1 - Investing + Extracting' },
  { value: '2 - Emerging', label: '2 - Emerging' },
  { value: '3 - Evaluating', label: '3 - Evaluating' },
];

export const customUnits = [
  { value: 'Points', label: 'Points' },
  { value: 'Story Points', label: 'Story Points' },
  { value: 'Units', label: 'Units' },
  { value: 'Votes', label: 'Votes' },
];

export const currencies = [
  { label: 'ADP - Andorran Peseta', value: 'ADP', group: 'Currencies' },
  { label: 'AED - United Arab Emirates Dirham', value: 'AED', group: 'Currencies' },
  { label: 'AFA - Afghan Afghani (1927–2002)', value: 'AFA', group: 'Currencies' },
  { label: 'AFN - Afghan Afghani', value: 'AFN', group: 'Currencies' },
  { label: 'ALL - Albanian Lek', value: 'ALL', group: 'Currencies' },
  { label: 'AMD - Armenian Dram', value: 'AMD', group: 'Currencies' },
  { label: 'ANG - Netherlands Antillean Guilder', value: 'ANG', group: 'Currencies' },
  { label: 'AOA - Angolan Kwanza', value: 'AOA', group: 'Currencies' },
  { label: 'ARS - Argentine Peso', value: 'ARS', group: 'Currencies' },
  { label: 'ATS - Austrian Schilling', value: 'ATS', group: 'Currencies' },
  { label: 'AUD - Australian Dollar', value: 'AUD', group: 'Currencies' },
  { label: 'AWG - Aruban Florin', value: 'AWG', group: 'Currencies' },
  { label: 'AYM - AYM', value: 'AYM', group: 'Currencies' },
  { label: 'AZM - Azerbaijani Manat (1993–2006)', value: 'AZM', group: 'Currencies' },
  { label: 'AZN - Azerbaijani Manat', value: 'AZN', group: 'Currencies' },
  { label: 'BAM - Bosnia-Herzegovina Convertible Mark', value: 'BAM', group: 'Currencies' },
  { label: 'BBD - Barbadian Dollar', value: 'BBD', group: 'Currencies' },
  { label: 'BDT - Bangladeshi Taka', value: 'BDT', group: 'Currencies' },
  { label: 'BEF - Belgian Franc', value: 'BEF', group: 'Currencies' },
  { label: 'BGL - Bulgarian Hard Lev', value: 'BGL', group: 'Currencies' },
  { label: 'BGN - Bulgarian Lev', value: 'BGN', group: 'Currencies' },
  { label: 'BHD - Bahraini Dinar', value: 'BHD', group: 'Currencies' },
  { label: 'BIF - Burundian Franc', value: 'BIF', group: 'Currencies' },
  { label: 'BMD - Bermudan Dollar', value: 'BMD', group: 'Currencies' },
  { label: 'BND - Brunei Dollar', value: 'BND', group: 'Currencies' },
  { label: 'BOB - Bolivian Boliviano', value: 'BOB', group: 'Currencies' },
  { label: 'BOV - Bolivian Mvdol', value: 'BOV', group: 'Currencies' },
  { label: 'BRL - Brazilian Real', value: 'BRL', group: 'Currencies' },
  { label: 'BSD - Bahamian Dollar', value: 'BSD', group: 'Currencies' },
  { label: 'BTN - Bhutanese Ngultrum', value: 'BTN', group: 'Currencies' },
  { label: 'BWP - Botswanan Pula', value: 'BWP', group: 'Currencies' },
  { label: 'BYB - Belarusian Ruble (1994–1999)', value: 'BYB' },
  { label: 'BYN - Belarusian Ruble', value: 'BYN', group: 'Currencies' },
  { label: 'BYR - Belarusian Ruble (2000–2016)', value: 'BYR', group: 'Currencies' },
  { label: 'BZD - Belize Dollar', value: 'BZD' },
  { label: 'CAD - Canadian Dollar', value: 'CAD' },
  { label: 'CDF - Congolese Franc', value: 'CDF' },
  { label: 'CHE - WIR Euro', value: 'CHE' },
  { label: 'CHF - Swiss Franc', value: 'CHF' },
  { label: 'CHW - WIR Franc', value: 'CHW' },
  { label: 'CLF - Chilean Unit of Account (UF)', value: 'CLF' },
  { label: 'CLP - Chilean Peso', value: 'CLP' },
  { label: 'CNY - Chinese Yuan', value: 'CNY' },
  { label: 'COP - Colombian Peso', value: 'COP' },
  { label: 'COU - Colombian Real Value Unit', value: 'COU' },
  { label: 'CRC - Costa Rican Colón', value: 'CRC' },
  { label: 'CSD - Serbian Dinar (2002–2006)', value: 'CSD' },
  { label: 'CUC - Cuban Convertible Peso', value: 'CUC' },
  { label: 'CUP - Cuban Peso', value: 'CUP' },
  { label: 'CVE - Cape Verdean Escudo', value: 'CVE' },
  { label: 'CYP - Cypriot Pound', value: 'CYP' },
  { label: 'CZK - Czech Koruna', value: 'CZK' },
  { label: 'DEM - German Mark', value: 'DEM' },
  { label: 'DJF - Djiboutian Franc', value: 'DJF' },
  { label: 'DKK - Danish Krone', value: 'DKK' },
  { label: 'DOP - Dominican Peso', value: 'DOP' },
  { label: 'DZD - Algerian Dinar', value: 'DZD' },
  { label: 'EEK - Estonian Kroon', value: 'EEK' },
  { label: 'EGP - Egyptian Pound', value: 'EGP' },
  { label: 'ERN - Eritrean Nakfa', value: 'ERN' },
  { label: 'ESP - Spanish Peseta', value: 'ESP' },
  { label: 'ETB - Ethiopian Birr', value: 'ETB' },
  { label: 'EUR - Euro', value: 'EUR' },
  { label: 'FIM - Finnish Markka', value: 'FIM' },
  { label: 'FJD - Fijian Dollar', value: 'FJD' },
  { label: 'FKP - Falkland Islands Pound', value: 'FKP' },
  { label: 'FRF - French Franc', value: 'FRF' },
  { label: 'GBP - British Pound', value: 'GBP' },
  { label: 'GEL - Georgian Lari', value: 'GEL' },
  { label: 'GHC - Ghanaian Cedi (1979–2007)', value: 'GHC' },
  { label: 'GHS - Ghanaian Cedi', value: 'GHS' },
  { label: 'GIP - Gibraltar Pound', value: 'GIP' },
  { label: 'GMD - Gambian Dalasi', value: 'GMD' },
  { label: 'GNF - Guinean Franc', value: 'GNF' },
  { label: 'GRD - Greek Drachma', value: 'GRD' },
  { label: 'GTQ - Guatemalan Quetzal', value: 'GTQ' },
  { label: 'GWP - Guinea-Bissau Peso', value: 'GWP' },
  { label: 'GYD - Guyanaese Dollar', value: 'GYD' },
  { label: 'HKD - Hong Kong Dollar', value: 'HKD' },
  { label: 'HNL - Honduran Lempira', value: 'HNL' },
  { label: 'HRK - Croatian Kuna', value: 'HRK' },
  { label: 'HTG - Haitian Gourde', value: 'HTG' },
  { label: 'HUF - Hungarian Forint', value: 'HUF' },
  { label: 'IDR - Indonesian Rupiah', value: 'IDR' },
  { label: 'IEP - Irish Pound', value: 'IEP' },
  { label: 'ILS - Israeli New Shekel', value: 'ILS' },
  { label: 'INR - Indian Rupee', value: 'INR' },
  { label: 'IQD - Iraqi Dinar', value: 'IQD' },
  { label: 'IRR - Iranian Rial', value: 'IRR' },
  { label: 'ISK - Icelandic Króna', value: 'ISK' },
  { label: 'ITL - Italian Lira', value: 'ITL' },
  { label: 'JMD - Jamaican Dollar', value: 'JMD' },
  { label: 'JOD - Jordanian Dinar', value: 'JOD' },
  { label: 'JPY - Japanese Yen', value: 'JPY' },
  { label: 'KES - Kenyan Shilling', value: 'KES' },
  { label: 'KGS - Kyrgystani Som', value: 'KGS' },
  { label: 'KHR - Cambodian Riel', value: 'KHR' },
  { label: 'KMF - Comorian Franc', value: 'KMF' },
  { label: 'KPW - North Korean Won', value: 'KPW' },
  { label: 'KRW - South Korean Won', value: 'KRW' },
  { label: 'KWD - Kuwaiti Dinar', value: 'KWD' },
  { label: 'KYD - Cayman Islands Dollar', value: 'KYD' },
  { label: 'KZT - Kazakhstani Tenge', value: 'KZT' },
  { label: 'LAK - Laotian Kip', value: 'LAK' },
  { label: 'LBP - Lebanese Pound', value: 'LBP' },
  { label: 'LKR - Sri Lankan Rupee', value: 'LKR' },
  { label: 'LRD - Liberian Dollar', value: 'LRD' },
  { label: 'LSL - Lesotho Loti', value: 'LSL' },
  { label: 'LTL - Lithuanian Litas', value: 'LTL' },
  { label: 'LUF - Luxembourgian Franc', value: 'LUF' },
  { label: 'LVL - Latvian Lats', value: 'LVL' },
  { label: 'LYD - Libyan Dinar', value: 'LYD' },
  { label: 'MAD - Moroccan Dirham', value: 'MAD' },
  { label: 'MDL - Moldovan Leu', value: 'MDL' },
  { label: 'MGA - Malagasy Ariary', value: 'MGA' },
  { label: 'MGF - Malagasy Franc', value: 'MGF' },
  { label: 'MKD - Macedonian Denar', value: 'MKD' },
  { label: 'MMK - Myanmar Kyat', value: 'MMK' },
  { label: 'MNT - Mongolian Tugrik', value: 'MNT' },
  { label: 'MOP - Macanese Pataca', value: 'MOP' },
  { label: 'MRO - Mauritanian Ouguiya (1973–2017)', value: 'MRO' },
  { label: 'MRU - Mauritanian Ouguiya', value: 'MRU' },
  { label: 'MTL - Maltese Lira', value: 'MTL' },
  { label: 'MUR - Mauritian Rupee', value: 'MUR' },
  { label: 'MVR - Maldivian Rufiyaa', value: 'MVR' },
  { label: 'MWK - Malawian Kwacha', value: 'MWK' },
  { label: 'MXN - Mexican Peso', value: 'MXN' },
  { label: 'MXV - Mexican Investment Unit', value: 'MXV' },
  { label: 'MYR - Malaysian Ringgit', value: 'MYR' },
  { label: 'MZM - Mozambican Metical (1980–2006)', value: 'MZM' },
  { label: 'MZN - Mozambican Metical', value: 'MZN' },
  { label: 'NAD - Namibian Dollar', value: 'NAD' },
  { label: 'NGN - Nigerian Naira', value: 'NGN' },
  { label: 'NIO - Nicaraguan Córdoba', value: 'NIO' },
  { label: 'NLG - Dutch Guilder', value: 'NLG' },
  { label: 'NOK - Norwegian Krone', value: 'NOK' },
  { label: 'NPR - Nepalese Rupee', value: 'NPR' },
  { label: 'NZD - New Zealand Dollar', value: 'NZD' },
  { label: 'OMR - Omani Rial', value: 'OMR' },
  { label: 'PAB - Panamanian Balboa', value: 'PAB' },
  { label: 'PEN - Peruvian Sol', value: 'PEN' },
  { label: 'PGK - Papua New Guinean Kina', value: 'PGK' },
  { label: 'PHP - Philippine Piso', value: 'PHP' },
  { label: 'PKR - Pakistani Rupee', value: 'PKR' },
  { label: 'PLN - Polish Zloty', value: 'PLN' },
  { label: 'PTE - Portuguese Escudo', value: 'PTE' },
  { label: 'PYG - Paraguayan Guarani', value: 'PYG' },
  { label: 'QAR - Qatari Rial', value: 'QAR' },
  { label: 'ROL - Romanian Leu (1952–2006)', value: 'ROL' },
  { label: 'RON - Romanian Leu', value: 'RON' },
  { label: 'RSD - Serbian Dinar', value: 'RSD' },
  { label: 'RUB - Russian Ruble', value: 'RUB' },
  { label: 'RUR - Russian Ruble (1991–1998)', value: 'RUR' },
  { label: 'RWF - Rwandan Franc', value: 'RWF' },
  { label: 'SAR - Saudi Riyal', value: 'SAR' },
  { label: 'SBD - Solomon Islands Dollar', value: 'SBD' },
  { label: 'SCR - Seychellois Rupee', value: 'SCR' },
  { label: 'SDD - Sudanese Dinar (1992–2007)', value: 'SDD' },
  { label: 'SDG - Sudanese Pound', value: 'SDG' },
  { label: 'SEK - Swedish Krona', value: 'SEK' },
  { label: 'SGD - Singapore Dollar', value: 'SGD' },
  { label: 'SHP - St. Helena Pound', value: 'SHP' },
  { label: 'SIT - Slovenian Tolar', value: 'SIT' },
  { label: 'SKK - Slovak Koruna', value: 'SKK' },
  { label: 'SLE - Sierra Leonean Leone', value: 'SLE' },
  { label: 'SLL - Sierra Leonean Leone', value: 'SLL' },
  { label: 'SOS - Somali Shilling', value: 'SOS' },
  { label: 'SRD - Surinamese Dollar', value: 'SRD' },
  { label: 'SRG - Surinamese Guilder', value: 'SRG' },
  { label: 'SSP - South Sudanese Pound', value: 'SSP' },
  { label: 'STD - São Tomé & Príncipe Dobra (1977–2017)', value: 'STD' },
  { label: 'STN - São Tomé & Príncipe Dobra', value: 'STN' },
  { label: 'SVC - Salvadoran Colón', value: 'SVC' },
  { label: 'SYP - Syrian Pound', value: 'SYP' },
  { label: 'SZL - Swazi Lilangeni', value: 'SZL' },
  { label: 'THB - Thai Baht', value: 'THB' },
  { label: 'TJS - Tajikistani Somoni', value: 'TJS' },
  { label: 'TMM - Turkmenistani Manat (1993–2009)', value: 'TMM' },
  { label: 'TMT - Turkmenistani Manat', value: 'TMT' },
  { label: 'TND - Tunisian Dinar', value: 'TND' },
  { label: 'TOP - Tongan Paʻanga', value: 'TOP' },
  { label: 'TPE - Timorese Escudo', value: 'TPE' },
  { label: 'TRL - Turkish Lira (1922–2005)', value: 'TRL' },
  { label: 'TRY - Turkish Lira', value: 'TRY' },
  { label: 'TTD - Trinidad & Tobago Dollar', value: 'TTD' },
  { label: 'TWD - New Taiwan Dollar', value: 'TWD' },
  { label: 'TZS - Tanzanian Shilling', value: 'TZS' },
  { label: 'UAH - Ukrainian Hryvnia', value: 'UAH' },
  { label: 'UGX - Ugandan Shilling', value: 'UGX' },
  { label: 'USD - US Dollar', value: 'USD' },
  { label: 'USN - US Dollar (Next day)', value: 'USN' },
  { label: 'USS - US Dollar (Same day)', value: 'USS' },
  { label: 'UYI - Uruguayan Peso (Indexed Units)', value: 'UYI' },
  { label: 'UYU - Uruguayan Peso', value: 'UYU' },
  { label: 'UZS - Uzbekistani Som', value: 'UZS' },
  { label: 'VEB - Venezuelan Bolívar (1871–2008)', value: 'VEB' },
  { label: 'VED - Venezuelan Bolívar Soberano', value: 'VED' },
  { label: 'VEF - Venezuelan Bolívar (2008–2018)', value: 'VEF' },
  { label: 'VES - Venezuelan Bolívar', value: 'VES' },
  { label: 'VND - Vietnamese Dong', value: 'VND' },
  { label: 'VUV - Vanuatu Vatu', value: 'VUV' },
  { label: 'WST - Samoan Tala', value: 'WST' },
  { label: 'XAF - Central African CFA Franc', value: 'XAF' },
  { label: 'XAG - Silver', value: 'XAG' },
  { label: 'XAU - Gold', value: 'XAU' },
  { label: 'XBA - European Composite Unit', value: 'XBA' },
  { label: 'XBB - European Monetary Unit', value: 'XBB' },
  { label: 'XBC - European Unit of Account (XBC)', value: 'XBC' },
  { label: 'XBD - European Unit of Account (XBD)', value: 'XBD' },
  { label: 'XCD - East Caribbean Dollar', value: 'XCD' },
  { label: 'XDR - Special Drawing Rights', value: 'XDR' },
  { label: 'XFO - French Gold Franc', value: 'XFO' },
  { label: 'XFU - French UIC-Franc', value: 'XFU' },
  { label: 'XOF - West African CFA Franc', value: 'XOF' },
  { label: 'XPD - Palladium', value: 'XPD' },
  { label: 'XPF - CFP Franc', value: 'XPF' },
  { label: 'XPT - Platinum', value: 'XPT' },
  { label: 'XSU - Sucre', value: 'XSU' },
  { label: 'XTS - Testing Currency Code', value: 'XTS' },
  { label: 'XUA - ADB Unit of Account', value: 'XUA' },
  { label: 'YER - Yemeni Rial', value: 'YER' },
  { label: 'YUM - Yugoslavian New Dinar (1994–2002)', value: 'YUM' },
  { label: 'ZAR - South African Rand', value: 'ZAR' },
  { label: 'ZMK - Zambian Kwacha (1968–2012)', value: 'ZMK' },
  { label: 'ZMW - Zambian Kwacha', value: 'ZMW' },
  { label: 'ZWD - Zimbabwean Dollar (1980–2008)', value: 'ZWD' },
  { label: 'ZWL - Zimbabwean Dollar (2009)', value: 'ZWL' },
  { label: 'ZWN - ZWN', value: 'ZWN' },
  { label: 'ZWR - Zimbabwean Dollar (2008)', value: 'ZWR' },
];
