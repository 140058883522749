import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCodeCallback } from '../../../api/sessions.queries';
import {
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
} from '@mantine/core';
import { FormAlert } from '../form-alert/FormAlert';

type NestedLoginDataProps = {
  code: string;
  state: string;
  provider: string;
};

function NestedLoginData({ code, state, provider }: NestedLoginDataProps) {
  const { isSuccess, isPending, isError, error } = useCodeCallback({ code, state, provider });

  if (isError) {
    <Modal opened={true} onClose={close} title="Authentication">
      <Group position="center">
        <Button>Open modal</Button>
      </Group>
    </Modal>;
  }
  if (isPending) {
    return <Loader />;
  } else if (isError) {
    return (
      <Center mt={300}>
        <Box w={500}>
          <FormAlert
            title="There was an unexpected error."
            link={
              <Center>
                <Button component="a" href={`/oauth2/${provider}/login`}>
                  Please Try Again
                </Button>
              </Center>
            }
            error={error}
          />
        </Box>
      </Center>
    );
  } else {
    return <>Success</>;
  }
}

export default function OAuthPassThrough() {
  const navigate = useNavigate();
  const locationSearch = useLocation().search;
  const params = new URLSearchParams(locationSearch);
  const { provider } = useParams();
  const code = params.get('code') ? params.get('code') : '';
  const state = params.get('state') ? params.get('state') : '';

  if (!code || !state || !provider) {
    navigate('/login');
    return (
      <Center>
        <Alert>
          <Stack>
            <div>
              There was an error logging you in, please reach out to Scaled Agile Customer Success
              for assistance.
            </div>
            <div>
              INVALID PARAMS: CODE:{code}, STATE: {state}, PROVIDER: {provider}
            </div>
          </Stack>
        </Alert>
      </Center>
    );
  } else {
    return <NestedLoginData code={code} state={state} provider={provider} />;
  }
}
