import { JSONContent } from '@tiptap/react';
import { useCallback, useEffect, useState } from 'react';
import { useCreateBiddingItemMutation } from '../../api/bidding-item.queries';
import { useCreateItemTypeMutation, useItemTypesByEventIdQuery } from '../../api/itemType.queries';
import {
  useCreateStrategicThemeMutation,
  useStrategicThemesByEventIdQuery,
} from '../../api/strategicTheme.queries';
import {
  useCreateValueStreamMutation,
  useValueStreamsByEventIdQuery,
} from '../../api/valueStream.queries';
import { generateJsonFromString } from '../../components/editor/json-content-conversions';
import { eventHorizons } from '../../constants/formDefaults';
import { PostBiddingItem } from '../../models/bidding-item.interface';
import { ItemType } from '../../models/itemType.interface';
import { StrategicTheme } from '../../models/strategicTheme.interface';
import { ValueStream } from '../../models/valueStream.interface';
import { ValidatedRawCsvData } from './CsvFileParser';
import {
  CsvFieldMapping,
  FailureBiddingItem,
  ItemUploadFormValues,
  TDragonFruitFieldNames,
  labelToLower,
} from './ItemUploadFormContext';

function dfNameToCsvName(
  csvFieldMapping: CsvFieldMapping[] | undefined,
  dfName: string
): string | undefined {
  return csvFieldMapping?.find((it) => dfName === it.dfField)?.csvField;
}

function extractField(
  csvRec: ValidatedRawCsvData,
  dfName: string,
  csvFieldMapping: CsvFieldMapping[] | undefined
): string | undefined {
  const csvName = dfNameToCsvName(csvFieldMapping, dfName);
  return csvName ? csvRec.csvData[csvName] : undefined;
}

function extractNumber(
  csvRec: ValidatedRawCsvData,
  dfName: string,
  csvFieldMapping: CsvFieldMapping[] | undefined
): number | undefined {
  const value = extractField(csvRec, dfName, csvFieldMapping);
  return value ? parseInt(value, 10) : undefined;
}

function extractJSONContent(
  csvRec: ValidatedRawCsvData,
  dfName: string,
  csvFieldMapping: CsvFieldMapping[] | undefined
): JSONContent | undefined {
  const content = extractField(csvRec, dfName, csvFieldMapping);

  if (content) {
    return generateJsonFromString(content);
  } else {
    return undefined;
  }
}

/**
 * Takes a list of row objects as extracted from CSV and returns a unique list of items of one particular property
 * Only extracts from valid records
 */
function extractFromAll(
  rawCsvData: ValidatedRawCsvData[] | undefined,
  csvFieldMapping: CsvFieldMapping[] | undefined,
  dfName: string,
  flatmapFunc?: (v: string) => string[]
): string[] {
  const csvName = dfNameToCsvName(csvFieldMapping, dfName);
  if (!csvName) {
    // no mapping found, means we don't have that value
    return [];
  }
  const strList: string[] = (
    rawCsvData?.map((csv) => (csv.isValid ? csv.csvData[csvName] : undefined)) || []
  ).filter((v) => !!v) as string[];

  // If a mapFunc is defined, we flatmap the string into a string[]
  const finalList = strList.flatMap((v) => flatmapFunc?.(v) ?? [v]);
  return [...new Set(finalList)]; // unique-ify and remove undefined
}

/**
 * Takes a string value from csv and splits it into an array.
 * Used for VS / ST
 */
function parseValueIntoList(v: string, delim: string): string[] {
  if (!v) {
    return [];
  }
  return v.split(delim).map((it) => it.trim());
}

function findNewValues(csvValues: string[], existing: string[] | undefined): string[] {
  const existingLower = existing?.map((it) => it.trim().toLowerCase());
  return csvValues?.filter((v) => {
    const csvLower = v.trim().toLowerCase();
    return !existingLower?.some((exst) => exst === csvLower);
  });
}

function findItemTypeId(
  itemTypeValue: string | undefined,
  allItemTypes: ItemType[]
): string | undefined {
  const v = itemTypeValue && labelToLower(itemTypeValue);
  const itemType = allItemTypes.find((it) => labelToLower(it.value) === v);
  return itemType ? itemType.id : undefined;
}

function findValueStreamIds(
  valueStreamValue: string | undefined,
  allValueStreams: ValueStream[],
  delim: string
): Array<string> {
  const v = valueStreamValue && labelToLower(valueStreamValue);
  const parsed = v ? parseValueIntoList(v, delim) : [];
  const valueStreams: ValueStream[] = allValueStreams.filter((it) =>
    parsed.includes(labelToLower(it.value))
  );
  return valueStreams?.map((v) => v.id);
}

function findStrategicThemeIds(
  strategicThemeValue: string | undefined,
  allStrategicThemes: StrategicTheme[],
  delim: string
): Array<string> {
  const v = strategicThemeValue && labelToLower(strategicThemeValue);
  const parsed = v ? parseValueIntoList(v, delim) : [];
  const strategicThemes: StrategicTheme[] = allStrategicThemes.filter((it) =>
    parsed.includes(labelToLower(it.value))
  );
  return strategicThemes?.map((v) => v.id);
}

function findHorizon(
  horizonValue: string | undefined,
  allHorizonValues: string[]
): string | undefined {
  // if no value, skip the remaining checks
  if (!horizonValue) {
    return horizonValue;
  } else {
    // strip special characters and spaces since they are non-identifying
    const v = labelToLower(horizonValue).replace('-', '').replace('+', '').replace(' ', '');
    // map to a known horizon value with a contains/includes check
    return allHorizonValues.find((it) =>
      labelToLower(it).replace('-', '').replace('+', '').replace(' ', '').includes(v)
    );
  }
}

interface BiddingItemsQueueEntry {
  values: ItemUploadFormValues;
  onSuccess?: (
    postBiddingItems: PostBiddingItem[],
    failureBiddingItems: Array<FailureBiddingItem>
  ) => void;
}
/**
 * - creates missing IT/VS/ST
 * - creates bidding items
 * - uploads bidding items in parallel (parallelicity is configurable)
 *
 *
 */
export function useUploadBiddingItems(eventId: string, csvFieldNames: TDragonFruitFieldNames) {
  const { data: itemTypes, isFetched: isItemTypesFetched } = useItemTypesByEventIdQuery(eventId);
  const { data: valueStreams, isFetched: isValueStreamsFetched } =
    useValueStreamsByEventIdQuery(eventId);
  const { data: strategicThemes, isFetched: isStrategicThemesFetched } =
    useStrategicThemesByEventIdQuery(eventId);
  const createItemType = useCreateItemTypeMutation();
  const createValueStream = useCreateValueStreamMutation();
  const createStrategicTheme = useCreateStrategicThemeMutation();
  const createItem = useCreateBiddingItemMutation();
  const [loading, setLoading] = useState(false);
  const [processBiddingItemsQueue, setProcessBiddingItemsQueue] = useState<
    BiddingItemsQueueEntry[]
  >([]);
  const [processingInProgress, setProcessingInProgress] = useState(false);
  const horizonValues = eventHorizons.map((h) => h.value);

  function uploadBiddingItems(
    values: ItemUploadFormValues,
    onSuccess: (
      postBiddingItems: PostBiddingItem[],
      failureBiddingItems: Array<FailureBiddingItem>
    ) => void
  ): void {
    setProcessBiddingItemsQueue((q) => [...(q || []), { values, onSuccess }]);
  }

  const doUploadBiddingItems = useCallback(
    async (
      values: ItemUploadFormValues
    ): Promise<[PostBiddingItem[], Array<FailureBiddingItem>]> => {
      const createNewItemTypes = async (): Promise<ItemType[]> => {
        const newItemTypes = findNewValues(
          extractFromAll(values.rawCsvData, values.csvFieldMapping, csvFieldNames.ITEM_TYPE),
          itemTypes?.map((it) => it.value)
        );

        return Promise.all(
          newItemTypes.map((itemType) => {
            return createItemType.mutateAsync({
              eventId,
              itemType: {
                value: itemType,
              },
            });
          })
        );
      };

      const createNewValueStreams = async (): Promise<ItemType[]> => {
        const newValueStreams = findNewValues(
          extractFromAll(
            values.rawCsvData,
            values.csvFieldMapping,
            csvFieldNames.VALUE_STREAMS,
            (v) => parseValueIntoList(v, values.delimiter)
          ),
          valueStreams?.map((it) => it.value)
        );

        return Promise.all(
          newValueStreams.map((valueStream) => {
            return createValueStream.mutateAsync({
              eventId,
              valueStream: {
                value: valueStream,
              },
            });
          })
        );
      };

      const createNewStrategicThemes = async (): Promise<ItemType[]> => {
        const newStrategicThemes = findNewValues(
          extractFromAll(
            values.rawCsvData,
            values.csvFieldMapping,
            csvFieldNames.STRATEGIC_THEMES,
            (v) => parseValueIntoList(v, values.delimiter)
          ),
          strategicThemes?.map((it) => it.value)
        );

        return Promise.all(
          newStrategicThemes.map((strategicTheme) => {
            return createStrategicTheme.mutateAsync({
              eventId,
              strategicTheme: {
                value: strategicTheme,
              },
            });
          })
        );
      };

      setLoading(true);

      const allItemTypes: ItemType[] = [...(itemTypes || []), ...(await createNewItemTypes())];
      const allValueStreams: ValueStream[] = [
        ...(valueStreams || []),
        ...(await createNewValueStreams()),
      ];
      const allStrategicThemes: StrategicTheme[] = [
        ...(strategicThemes || []),
        ...(await createNewStrategicThemes()),
      ];

      const buildBiddingItems = (): [PostBiddingItem[], Array<FailureBiddingItem>] => {
        const items: PostBiddingItem[] = [];
        const failures: Array<FailureBiddingItem> = [];
        const parsedValues = values.rawCsvData?.filter((csvData) => csvData.isValid) ?? []; // remove records that did not parse
        for (const csvData of parsedValues) {
          const partialItem: FailureBiddingItem = {
            row: csvData.row,
            eventId: eventId,
            horizon: findHorizon(
              extractField(csvData, csvFieldNames.HORIZON, values.csvFieldMapping),
              horizonValues
            ),
            valueStreamIds: findValueStreamIds(
              extractField(csvData, csvFieldNames.VALUE_STREAMS, values.csvFieldMapping),
              allValueStreams,
              values.delimiter
            ),
            strategicThemeIds: findStrategicThemeIds(
              extractField(csvData, csvFieldNames.STRATEGIC_THEMES, values.csvFieldMapping),
              allStrategicThemes,
              values.delimiter
            ),
            description: extractJSONContent(csvData, csvFieldNames.DESC, values.csvFieldMapping),
          };

          const name = extractField(csvData, csvFieldNames.NAME, values.csvFieldMapping);
          if (!name) {
            partialItem.errors = [
              ...(partialItem.errors || []),
              `No name defined for record "${csvData.row + 1}"`,
            ];
          }
          partialItem.name = name;

          const fundingTarget = extractNumber(
            csvData,
            csvFieldNames.COST_EST,
            values.csvFieldMapping
          );
          if (!fundingTarget) {
            partialItem.errors = [
              ...(partialItem.errors || []),
              `No cost estimate defined for record "${name}"`,
            ];
          }
          partialItem.fundingTarget = fundingTarget;

          const itemTypeId = findItemTypeId(
            extractField(csvData, csvFieldNames.ITEM_TYPE, values.csvFieldMapping),
            allItemTypes
          );
          if (!itemTypeId) {
            partialItem.errors = [
              ...(partialItem.errors || []),
              `No item type defined for record "${name}"`,
            ];
          }
          partialItem.itemTypeId = itemTypeId;

          if (partialItem.errors) {
            failures.push(partialItem);
          } else {
            items.push(partialItem as PostBiddingItem);
          }
        }

        return [items, failures];
      };

      const [newItems, failureItems] = buildBiddingItems();

      setLoading(false);
      return [newItems, failureItems];
    },
    [
      itemTypes,
      valueStreams,
      strategicThemes,
      createItemType,
      eventId,
      createValueStream,
      createStrategicTheme,
      horizonValues,
      csvFieldNames,
    ]
  );

  const continueItemUpload = useCallback(
    async (postBiddingItems: PostBiddingItem[] | undefined): Promise<boolean> => {
      setLoading(true);
      const newItems = postBiddingItems;
      if (newItems) {
        try {
          await Promise.all(
            newItems.map((item) => {
              return createItem.mutateAsync(item);
            })
          );
          setLoading(false);
          return true;
        } catch (error) {
          console.error('received error during import', error);
        }
      }
      setLoading(false);
      return false;
    },
    [createItem]
  );

  useEffect(() => {
    async function startProcessing() {
      setProcessingInProgress(true);
      const queueEntry = processBiddingItemsQueue[processBiddingItemsQueue.length - 1];
      const [newItems, failureItems] = await doUploadBiddingItems(queueEntry.values);
      setProcessingInProgress(false);
      setProcessBiddingItemsQueue([
        ...processBiddingItemsQueue.filter((v, idx) => idx < processBiddingItemsQueue.length - 1),
      ]);
      queueEntry?.onSuccess?.(newItems, failureItems);
    }
    if (
      isItemTypesFetched &&
      isValueStreamsFetched &&
      isStrategicThemesFetched &&
      !processingInProgress &&
      processBiddingItemsQueue?.length
    ) {
      startProcessing();
    }
  }, [
    processBiddingItemsQueue,
    doUploadBiddingItems,
    processingInProgress,
    isItemTypesFetched,
    isValueStreamsFetched,
    isStrategicThemesFetched,
  ]);

  return {
    uploadBiddingItems,
    continueItemUpload,
    loading,
  };
}
