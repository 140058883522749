import { Anchor, Text } from '@mantine/core';
import { Link, Params } from 'react-router-dom';
import { useEventByIdQuery } from '../../../api/event.queries';
import { useRequiredParam } from '../../../hooks/useRequiredParam';
import { linkToEvent } from '../../../utils/event-urls';
import { useStyles } from '../layoutStyles';

interface EventCrumbProps {
  isLast: boolean;
  params?: Params<string>;
}

export function EventCrumb({ isLast }: EventCrumbProps) {
  const { classes } = useStyles();
  const eventId = useRequiredParam('eventId');
  const { data: event } = useEventByIdQuery(eventId);

  return event?.name ? (
    isLast ? (
      <Text className={classes.inactiveBreadcrumb}>{event.name}</Text>
    ) : (
      <Anchor
        component={Link}
        to={linkToEvent(eventId, event?.isSample)}
        className={classes.activeBreadcrumb}
      >
        {event.name}
      </Anchor>
    )
  ) : null;
}
