import { Button, Group, Text } from '@mantine/core';
import { ContextModalProps, openContextModal } from '@mantine/modals';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PostBiddingItem } from '../../models/bidding-item.interface';
import { linkToManageItems } from '../../utils/event-urls';
import { AssignCsvFieldsForm } from './AssignCsvFieldsForm';
import { CsvParseResult } from './CsvFileParser';
import { ImportFileForm } from './ImportFileForm';
import { ItemUploadErrorForm } from './ItemUploadErrorForm';
import {
  FailureBiddingItem,
  ItemUploadFormProvider,
  TDragonFruitFieldNames,
  useItemUploadForm,
} from './ItemUploadFormContext';
import { useUploadBiddingItems } from './useUploadBiddingItems';
import { useStyles } from './wizardStyles';
import { useEventByIdQuery } from '../../api/event.queries';
import { useCustomValueStream } from '../../hooks/useCustomValueStream';
import { useCustomStrategicTheme } from '../../hooks/useCustomStrategicTheme';

export const itemUploadModalKey = 'itemUploadWizard';

export function openItemUploadWizardModal(eventId: string) {
  return openContextModal({
    withCloseButton: false,
    modal: itemUploadModalKey,
    centered: true,
    title: null,
    size: 'xl',
    innerProps: {
      eventId,
    },
  });
}

export interface ItemUploadProps extends Record<string, unknown> {
  eventId: string;
}

enum ItemUploadWizardStep {
  UPLOAD = 0,
  ASSIGN = 1,
  ERRORS = 2,
}

export function ItemUploadWizard({
  innerProps,
  context,
  id,
}: ContextModalProps<ItemUploadProps>): JSX.Element {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(ItemUploadWizardStep.UPLOAD);

  const customValueStreamLabel = useCustomValueStream(innerProps.eventId);
  const customStrategicThemeLabel = useCustomStrategicTheme(innerProps.eventId);

  const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));
  const nextStep = () =>
    setActiveStep((current) => (current < ItemUploadWizardStep.ERRORS ? current + 1 : current));

  const DragonFruitFieldNames: TDragonFruitFieldNames = {
    NAME: 'Item Name',
    DESC: 'Description',
    COST_EST: 'Estimated Cost',
    ITEM_TYPE: 'Item Type',
    STRATEGIC_THEMES: customStrategicThemeLabel,
    VALUE_STREAMS: customValueStreamLabel,
    HORIZON: 'Horizon',
  };

  const form = useItemUploadForm({
    validateInputOnChange: true,
    validateInputOnBlur: true,
    initialValues: {
      eventId: innerProps.eventId,
      delimiter: ',',
      rawCsvData: undefined,
      csvHeaders: undefined,
      eventItems: undefined,
      parseErrors: undefined,
      mandatoryFieldsMapped: false,
      csvFieldMapping: undefined,
      postBiddingItems: undefined,
      failureBiddingItems: undefined,
    },
  });

  const uploadHook = useUploadBiddingItems(form.values.eventId, DragonFruitFieldNames);

  const createCsvBiddingItems = () => {
    uploadHook.uploadBiddingItems(
      form.values,
      (postBiddingItems: PostBiddingItem[], failureBiddingItems: Array<FailureBiddingItem>) => {
        console.log('uploadBiddingItems finished with ', postBiddingItems, failureBiddingItems);
        form.setValues({ postBiddingItems, failureBiddingItems });
        if (failureBiddingItems?.length || form.values.parseErrors?.length) {
          nextStep();
        } else {
          uploadHook.continueItemUpload(postBiddingItems).then(() => {
            context.closeModal(id);
            navigate(linkToManageItems(form.values.eventId));
          });
        }
      }
    );
  };

  const continueItemUpload = async () => {
    await uploadHook.continueItemUpload(form.values.postBiddingItems);
    context.closeModal(id);
    navigate(linkToManageItems(form.values.eventId));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    switch (true) {
      case activeStep === ItemUploadWizardStep.ASSIGN:
        createCsvBiddingItems();
        break;
      case activeStep === ItemUploadWizardStep.ERRORS:
        continueItemUpload();
        break;
      default:
        break;
    }
  };

  const validateStep = () => {
    switch (true) {
      case activeStep === ItemUploadWizardStep.UPLOAD && !form.isValid('itemUpload'):
        return true;
      case activeStep === ItemUploadWizardStep.ASSIGN:
        return !form.values.mandatoryFieldsMapped;
      default:
        return false;
    }
  };

  const handleFileImported = (result: CsvParseResult) => {
    form.setValues({
      rawCsvData: result.data,
      csvHeaders: result.headers,
      parseErrors: result.errors,
      delimiter: result.delimiter,
    });

    nextStep();
  };

  return (
    <>
      <ItemUploadFormProvider form={form}>
        <Group mb="sm">
          <Text className={classes.modalTitle}>Import Items</Text>
        </Group>
        <form onSubmit={handleSubmit}>
          {activeStep === ItemUploadWizardStep.UPLOAD && (
            <ImportFileForm onComplete={handleFileImported} />
          )}
          {activeStep === ItemUploadWizardStep.ASSIGN && (
            <AssignCsvFieldsForm
              loading={uploadHook.loading}
              csvFieldNames={DragonFruitFieldNames}
            />
          )}
          {activeStep === ItemUploadWizardStep.ERRORS && (
            <ItemUploadErrorForm loading={uploadHook.loading} />
          )}
          <Group position="center" mt="xl">
            {activeStep === ItemUploadWizardStep.ASSIGN && (
              <Button variant="default" size="xl" onClick={prevStep}>
                Back
              </Button>
            )}
            {activeStep === ItemUploadWizardStep.ERRORS && (
              <Button
                variant="default"
                size="xl"
                onClick={() => setActiveStep(ItemUploadWizardStep.UPLOAD)}
              >
                Start Over
              </Button>
            )}
            {activeStep === ItemUploadWizardStep.ASSIGN && (
              <Button size="xl" type="submit" disabled={validateStep()}>
                Continue to Event Management
              </Button>
            )}
            {activeStep === ItemUploadWizardStep.ERRORS && (
              <Button size="xl" type="submit" disabled={validateStep()}>
                Continue With Import
              </Button>
            )}
          </Group>
        </form>
      </ItemUploadFormProvider>
    </>
  );
}
