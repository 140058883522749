import { Box, Divider, Group, Navbar, Stack, Text, Title, UnstyledButton } from '@mantine/core';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useLogout } from '../../api/sessions.queries';
import { IconBadge, IconListDetails, IconLogout, IconTools } from '@tabler/icons-react';
import { useGetScreenSize } from '../../hooks/useResponsiveDevices';
import { Dispatch, SetStateAction } from 'react';
import { UserDataFromJwt } from '../../hooks/useAuth';

type LeftNavBarProps = {
  opened: boolean;
  user: UserDataFromJwt | null;
  setOpened: Dispatch<SetStateAction<boolean>>;
};

export const LeftNavBar = ({ opened, user, setOpened }: LeftNavBarProps) => {
  const logout = useLogout();
  const { smallScreen } = useGetScreenSize();

  const handleLogout = async () => {
    setOpened(!opened);
    const { redirect } = await logout.mutateAsync();
    Sentry.setUser({ ip_address: '{{auto}}' });
    (window as any).location = redirect ?? '/';
  };

  const hideNavBar = !smallScreen || !opened;

  return (
    <Box hidden={hideNavBar}>
      <Navbar p="md" width={{ sm: opened ? 100 : 0 }}>
        <Navbar.Section>
          <></>
        </Navbar.Section>
        <Navbar.Section>
          <Stack spacing="sm">
            <UnstyledButton component="a" href="https://safe.scaledagile.com/">
              <Group>
                <IconBadge /> <Text>Safe Studio</Text>
              </Group>
            </UnstyledButton>

            <Divider />
            {user && (
              <>
                <UnstyledButton component={Link} to="/" onClick={() => setOpened(!opened)}>
                  <Group>
                    <IconListDetails /> <Text>Event List</Text>
                  </Group>
                </UnstyledButton>
                <Divider />
                <UnstyledButton
                  component={Link}
                  to="/account-management"
                  onClick={() => setOpened(!opened)}
                >
                  <Group>
                    <IconTools /> <Text>Account Management </Text>
                  </Group>
                </UnstyledButton>
                <Divider />
                <UnstyledButton component="button" onClick={handleLogout}>
                  <Group>
                    <IconLogout /> <Text>Logout </Text>
                  </Group>
                </UnstyledButton>
                <Divider />
              </>
            )}
          </Stack>
        </Navbar.Section>
      </Navbar>
    </Box>
  );
};
