import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  modalTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  tableHeader: {
    color: theme.colors.gray[4],
  },
  tableCell: {
    color: theme.colors.gray[5],
  },
  mandatoryField: {
    color: theme.colors.error[1],
  },
  uploadSuccessful: {
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  matchError: {
    textAlign: 'center',
    fontWeight: 700,
  },
  matchHint: {
    marginTop: theme.spacing.xs,
    textAlign: 'center',
    fontWeight: 400,
  },
  matchErrorDetail: {
    color: theme.colors.error[1],
  },
}));
