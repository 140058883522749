import { Text, Box, SimpleGrid, Title } from '@mantine/core';

export const LoginStatements = () => {
  return (
    <SimpleGrid
      cols={3}
      breakpoints={[
        { maxWidth: 'md', cols: 2 },
        { maxWidth: 'sm', cols: 1 },
      ]}
      py={50}
      sx={(theme) => ({ background: theme.colors.gray[0] })}
    >
      <Box px={50}>
        <Title
          order={5}
          fw={800}
          align="center"
          py="xs"
          sx={(theme) => ({ color: theme.colors.sky[5] })}
        >
          Make better investment decisions
        </Title>
        <Text align="center" size="md">
          Visualize the connections between important Lean Portfolio Management (LPM) elements,
          including value streams, strategic themes, and investment horizons.
        </Text>
      </Box>
      <Box
        px={50}
        sx={(theme) => ({
          borderRight: `1px solid ${theme.colors.gray[4]}`,
          borderLeft: `1px solid ${theme.colors.gray[4]}`,
        })}
      >
        <Title
          order={5}
          fw={800}
          align="center"
          py="xs"
          sx={(theme) => ({ color: theme.colors.sky[5] })}
        >
          Analyze voting in real time
        </Title>
        <Text align="center" size="md">
          By connecting key LPM elements and real-time voting, leaders can better understand the
          true impact of their investment decisions across the portfolio.
        </Text>
      </Box>
      <Box px={50}>
        <Title
          order={5}
          fw={800}
          align="center"
          py="xs"
          sx={(theme) => ({ color: theme.colors.sky[5] })}
        >
          Cultivate collaboration and inclusivity
        </Title>
        <Text align="center" size="md">
          Participatory budgeting enables collaboration between many stakeholders, which visualizes
          more diverse viewpoints when making investment decisions.
        </Text>
      </Box>
    </SimpleGrid>
  );
};
