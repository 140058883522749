import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { ApiConstants, ApiEndpoints } from '../constants/api';
import { useAuthContext } from '../hooks/useAuth';
import { useQueryCacheManager } from '../hooks/useQueryCacheManager';
import { useUserNotificationManager } from '../hooks/userNotificationManager';
import { Bid } from '../models/bid.interface';
import { BiddingItem } from '../models/bidding-item.interface';
import { EventJoinLink } from '../models/event-join-link.interface';
import { Event } from '../models/event.interface';
import { EventMember } from '../models/eventMember.interface';
import { EventGroup } from '../models/group.interface';
import { GroupMember } from '../models/groupMember.interface';
import { ItemType } from '../models/itemType.interface';
import { StrategicTheme } from '../models/strategicTheme.interface';
import { ValueStream } from '../models/valueStream.interface';
import { groupsKeys } from './group.queries';
import { useWebsocket } from './websocket-managed';

export enum MessageResource {
  EVENTS = 'events',
  GROUPS = 'groups',
  BIDDING_ITEMS = 'biddingItems',
  BIDS = 'bids',
  GROUP_MEMBERS = 'groupMembers',
  EVENT_MEMBERS = 'eventMembers',
  ITEM_TYPES = 'itemTypes',
  VALUE_STREAMS = 'valueStreams',
  STRATEGIC_THEMES = 'strategicThemes',
  EVENT_JOIN_LINKS = 'eventJoinLinks',
}

export type MessageData = {
  action: 'new' | 'update' | 'delete' | 'error';
} & (
  | { resource: MessageResource.EVENTS; data: Event }
  | { resource: MessageResource.GROUPS; data: EventGroup }
  | { resource: MessageResource.BIDDING_ITEMS; data: BiddingItem }
  | { resource: MessageResource.BIDS; data: Bid }
  | { resource: MessageResource.GROUP_MEMBERS; data: GroupMember }
  | { resource: MessageResource.EVENT_MEMBERS; data: EventMember }
  | { resource: MessageResource.ITEM_TYPES; data: ItemType }
  | { resource: MessageResource.VALUE_STREAMS; data: ValueStream }
  | { resource: MessageResource.STRATEGIC_THEMES; data: StrategicTheme }
  | { resource: MessageResource.EVENT_JOIN_LINKS; data: EventJoinLink }
);

/**
 * called each time a connection attempt is made (i.e. when reconnecting)
 * As described in https://stackoverflow.com/a/26123316/7143 , WS connections do not allow authorization headers,
 * however authentication is possible via cookie, query param or the 'sec-websocket-protocol' header which is set if
 * the protocol param is present in the WS creation.
 */
function createWebsocket(eventId: string): WebSocket {
  const host = window.location.host;
  const protocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
  const connectionString = `${protocol}//${host}${ApiConstants.BASE_URL}${ApiEndpoints.WEBSOCKET}${ApiEndpoints.EVENTS}/${eventId}`;
  return new WebSocket(connectionString);
}

/**
 * Some resource updates require an update of dependent resources.
 * When an event is updated we invalidate all group queries to force their update.
 */
function updateDependentResources(queryClient: QueryClient, message: MessageData) {
  if (message.resource === MessageResource.EVENTS) {
    queryClient.invalidateQueries({ queryKey: groupsKeys.all });
  }
}

export const useEventSubscription = (eventId: string): boolean => {
  const queryClient = useQueryClient();
  const queryCacheManager = useQueryCacheManager();
  const auth = useAuthContext();
  const notificationManager = useUserNotificationManager(eventId);

  const messageHandler = useCallback(
    (event: MessageEvent) => {
      const message: MessageData = JSON.parse(event.data);
      console.log('websocket message', { message });
      switch (message.action) {
        case 'error':
          console.error('websocket message error', message);
          break;
        case 'delete':
          queryCacheManager.removeCachedData({
            ...message,
            onComplete: () => {
              if (
                message.resource === MessageResource.EVENT_MEMBERS &&
                message.data.userId === auth.user?.id
              ) {
                notificationManager.setMemberRemovedFromEventNotification(message);
              } else {
                notificationManager.setRemovedItemNotification(message);
              }
            },
          });
          break;
        case 'new': {
          queryCacheManager.addCachedData({
            ...message,
            onComplete: () => notificationManager.setNewItemNotification(message),
          });
          break;
        }
        case 'update':
          // check if the data has been soft-deleted, which is also a update msg
          if ((message.data as any)?.deletedAt) {
            // treat as it has been deleted. Deleted data should not receive any other updates
            queryCacheManager.removeCachedData({
              ...message,
              onComplete: () => notificationManager.setRemovedItemNotification(message),
            });
          } else {
            queryCacheManager.updateCachedData({
              ...message,
              onComplete: () => {
                updateDependentResources(queryClient, message);
                notificationManager.setUpdatedItemNotification(message);
              },
            });
          }

          break;
        default: {
          const _exhaustiveCheck: never = message.action;
          return _exhaustiveCheck;
        }
      }
    },
    [notificationManager, queryCacheManager, queryClient]
  );

  const userId: string | undefined = auth.user?.id;

  const { subscribe, unsubscribe, socketOpen } = useWebsocket(messageHandler);

  useEffect(() => {
    if (!userId) {
      console.error('useEventSubscription not creating a websocket connection, userId not defined');
      return;
    }
    subscribe(() => createWebsocket(eventId));

    return () => {
      unsubscribe();
    };
  }, [eventId, userId, subscribe, unsubscribe]);

  return socketOpen;
};
