import { Loader, Stack, Table, Text } from '@mantine/core';
import { useItemUploadFormContext } from './ItemUploadFormContext';
import { useStyles } from './wizardStyles';

interface CsvError {
  row: number;
  message: string;
}

interface ItemUploadErrorFormProps {
  loading?: boolean;
}

const MaxErrorMsgCnt = 10;

export function ItemUploadErrorForm({ loading }: ItemUploadErrorFormProps): JSX.Element {
  const { classes } = useStyles();
  const form = useItemUploadFormContext();

  const errorCount =
    (form.values.parseErrors?.length || 0) + (form.values.failureBiddingItems?.length || 0);

  const rmngMessages = errorCount - MaxErrorMsgCnt;

  const errors: CsvError[] = [
    ...(form.values.parseErrors || []).map((parseError) => ({
      row: parseError.row + 1,
      message: parseError.message,
    })),
    ...(form.values.failureBiddingItems || []).map((failureItem) => ({
      row: failureItem.row + 1,
      message: failureItem.errors?.join(', ') ?? 'Unknown error',
    })),
  ].sort((a, b) => a.row - b.row);

  return loading ? (
    <Stack align="center" justify="center" mb="md">
      <Loader size="200px" />
    </Stack>
  ) : (
    <>
      <Stack align="center" justify="center" spacing={0} mb="md">
        {loading && <Loader />}
        <Text className={classes.uploadSuccessful}>
          There was an issue uploading some of your items
        </Text>

        <Text className={classes.matchError}>
          There were{' '}
          <span className={classes.matchErrorDetail}>
            {errorCount}
            {errorCount === 1 ? ' item' : ' items'}
          </span>{' '}
          that we could not import.
        </Text>
        <Text className={classes.matchHint}>Below is a list of the items we could not import.</Text>
      </Stack>
      <Stack spacing="lg" style={{ width: '100%' }}>
        <Table verticalSpacing="xs">
          <thead className={classes.tableHeader}>
            <tr>
              <th>Spreadsheet Row</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {errors.slice(0, MaxErrorMsgCnt).map((error, idx) => (
              // use better key
              <tr key={idx}>
                <td className={classes.tableCell}>
                  <span>{error.row}</span>
                </td>
                <td className={classes.tableCell}>{error.message}</td>
              </tr>
            ))}
            {rmngMessages > 0 ? (
              <tr>
                <td colSpan={2} className={classes.tableCell}>
                  ...plus {rmngMessages} more rows
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </Stack>
    </>
  );
}
