import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuth';

export function RequireAuth({ children }: { children: ReactNode }): JSX.Element {
  const { user, isValidating } = useAuthContext();
  const location = useLocation();

  // always show content when user is defined
  // makes sure that components stay mounted while user is re-verified
  // (i.e. queryClient.invalidateQueries is called)
  if (user) {
    return <>{children}</>;
  } else {
    // do not redirect, while user validation is happening
    if (isValidating) {
      return <></>;
    } else {
      return (
        <Navigate
          to="/login"
          replace
          state={{ path: location.pathname, search: location.search }}
        />
      );
    }
  }
}
