import { UserDataFromJwt } from '../hooks/useAuth';

export enum AuthorizationRoles {
  Reader = 'cofund:reader',
  Creator = 'cofund:creator',
}

export function canCreateDemoEvent(roles?: string[]): boolean {
  return roles?.includes(AuthorizationRoles.Reader) ?? false;
}

export function canCreateCustomEvent(roles?: string[]): boolean {
  return roles?.includes(AuthorizationRoles.Creator) ?? false;
}

export function isGuestUser(user: UserDataFromJwt): boolean {
  return user.provider === 'guest';
}
