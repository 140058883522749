import { Link } from '@mantine/tiptap';
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import { Extensions } from '@tiptap/core/';

const editableLink = Link.configure({
  protocols: ['http', 'https'],
  autolink: true,
  openOnClick: false,
  HTMLAttributes: {
    // Change rel to different value
    // Allow search engines to follow links(remove nofollow)
    rel: 'nofollow',
    // Remove target entirely so links open in current tab
    target: '_blank',
  },
});

interface EditorExtensionProps {
  contentEditable?: boolean;
  placeholder?: string;
  maxTextLen?: number;
}

export function editorExtensions({
  contentEditable = false,
  placeholder,
  maxTextLen,
}: EditorExtensionProps) {
  const extensions: Extensions = [StarterKit, Highlight];
  if (contentEditable) {
    if (placeholder) {
      extensions.push(Placeholder.configure({ placeholder }));
    }
    extensions.push(editableLink);
    if (maxTextLen) {
      extensions.push(
        CharacterCount.configure({
          limit: maxTextLen,
        })
      );
    }
  } else {
    extensions.push(Link);
  }
  extensions.push(Underline);
  return extensions;
}
