import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiConstants, ApiEndpoints } from '../constants/api';
import { Participant, User } from '../models/user.interface';
import { PatchEvent, PostEvent, Event } from '../models/event.interface';
import { BiddingItem, PatchBiddingItem, PostBiddingItem } from '../models/bidding-item.interface';
import { Bid, PatchBid, PostBid } from '../models/bid.interface';
import { PatchGroupMember, PostGroupMember, GroupMember } from '../models/groupMember.interface';
import { PostGroup, EventGroup } from '../models/group.interface';
import { PatchEventMember, PostEventMember, EventMember } from '../models/eventMember.interface';
import { useAuthContext } from '../hooks/useAuth';
import { ReactNode, useEffect } from 'react';
import { ItemType, PostItemType } from '../models/itemType.interface';
import { PostValueStream, ValueStream } from '../models/valueStream.interface';
import { PostStrategicTheme, StrategicTheme } from '../models/strategicTheme.interface';
import { useQueryClient } from '@tanstack/react-query';
import {
  EventJoinLink,
  PatchEventJoinLink,
  PostEventJoinLink,
} from '../models/event-join-link.interface';
import { InviteLink } from '../models/event-join-link.interface';
import { useAppRefresh } from '../hooks/useAppRefresh';
import { EventMembership } from '../models/eventMembership.interface';

export const API: AxiosInstance = axios.create({
  baseURL: ApiConstants.BASE_URL,
  timeout: 15000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const { token, removeToken, setToken } = useAuthContext();
  const { needsAppRefresh, setNeedsAppRefresh } = useAppRefresh();

  const queryClient = useQueryClient();

  useEffect(() => {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    const requestOnFulfilled = (config: InternalAxiosRequestConfig) => {
      return config;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const requestOnRejected = (error: any) => {
      return Promise.reject(error);
    };

    const responseOnFulfilled = (response: AxiosResponse) => {
      // don't repeat the check if they are known to be different
      if (!needsAppRefresh) {
        const clientVersion = import.meta.env.VITE_BUILD_VERSION;
        const serverVersion = response?.headers?.['x-build-version'];

        if (!clientVersion) {
          console.warn('clientVersion is not defined, please check environment config');
        } else if (!serverVersion) {
          console.warn('serverVersion is not defined, please check environment config');
        } else if (serverVersion !== clientVersion) {
          console.log(
            `client and server version mismatch detected. trigger reload for later. client=${clientVersion}, server=${serverVersion}`
          );
          setNeedsAppRefresh(true);
        }
      }
      return response; // continue with response
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const responseOnRejected = (error: any) => {
      const originalRequest = error.config;

      if (error.response.status === 401) {
        if (
          !originalRequest._isRetry &&
          originalRequest.url !== `${ApiEndpoints.SESSIONS}/refresh`
        ) {
          originalRequest._isRetry = true;
          return SessionsApi.refresh().then((data) => {
            setToken(data.token);
            originalRequest.headers['Authorization'] = `Bearer ${data.token}`;

            return API(originalRequest);
          });
        } else {
          removeToken();
          queryClient.removeQueries();
        }
      }
      return Promise.reject(error);
    };

    const responseInterceptor = API.interceptors.response.use(
      responseOnFulfilled,
      responseOnRejected
    );
    const requestInterceptor = API.interceptors.request.use(requestOnFulfilled, requestOnRejected);
    return () => {
      API.interceptors.response.eject(responseInterceptor);
      API.interceptors.request.eject(requestInterceptor);
    };
  }, [setToken, removeToken, token, queryClient, needsAppRefresh, setNeedsAppRefresh]);

  return <>{children}</>;
};

export const ParticipantApi: {
  getParticipants: () => Promise<Participant[]>;
} = {
  getParticipants: (): Promise<Participant[]> =>
    API.get(ApiEndpoints.PARTICIPANTS).then((response) => response.data),
};

export const EventApi: {
  getAllForUser: () => Promise<Event[]>;
  getJoinedForUser: () => Promise<Event[]>;
  getDetails: (eventId: string) => Promise<Event>;
  createEvent: (event: PostEvent) => Promise<Event>;
  updateEvent: (event: PatchEvent) => Promise<Event>;
  deleteEvent: (eventId: string) => Promise<null>;
} = {
  getAllForUser: () =>
    API.get<Event[]>(`${ApiEndpoints.EVENTS}`, {}).then((response) => response.data),
  getJoinedForUser: () =>
    API.get<Event[]>(`${ApiEndpoints.EVENTS}?joined=true`, {}).then((response) => response.data),
  getDetails: (eventId): Promise<Event> =>
    API.get<Event>(`${ApiEndpoints.EVENTS}/${eventId}`).then((response) => response.data),
  createEvent: (event: PostEvent): Promise<Event> =>
    API.post<Event>(ApiEndpoints.EVENTS, event).then((response) => response.data),
  updateEvent: (event) =>
    API.patch<Event>(`${ApiEndpoints.EVENTS}/${event.id}`, event).then((response) => response.data),
  deleteEvent: (eventId) => API.delete(`${ApiEndpoints.EVENTS}/${eventId}`),
};

export const GroupApi: {
  getParticipantGroup: (eventId?: string) => Promise<EventGroup[]>;
  getAll: (params: { eventId: string }) => Promise<EventGroup[]>;
  getDetails: (groupId: string) => Promise<EventGroup>;
  createGroup: (group: PostGroup) => Promise<EventGroup>;
  updateGroup: (group: EventGroup) => Promise<EventGroup>;
  deleteGroup: (groupId: string) => Promise<null>;
} = {
  getParticipantGroup: (eventId) =>
    API.get<EventGroup[]>(`${ApiEndpoints.MY_GROUP}`, { params: { eventId } }).then(
      (response) => response.data
    ),
  getAll: ({ eventId }) =>
    API.get<EventGroup[]>(`${ApiEndpoints.GROUPS}`, { params: { eventId } }).then(
      (response) => response.data
    ),
  getDetails: (groupId) =>
    API.get<EventGroup>(`${ApiEndpoints.GROUPS}/${groupId}`).then((response) => response.data),
  createGroup: (group) =>
    API.post<PostGroup, AxiosResponse<EventGroup>>(`${ApiEndpoints.GROUPS}`, group).then(
      (response) => response.data
    ),
  updateGroup: (group) =>
    API.patch<EventGroup>(`${ApiEndpoints.GROUPS}/${group.id}`, group).then(
      (response) => response.data
    ),
  deleteGroup: (groupId) => API.delete(`${ApiEndpoints.GROUPS}/${groupId}`),
};

export const BiddingItemApi: {
  getAll: (params: { eventId: string }) => Promise<BiddingItem[]>;
  getDetails: (biddingItemId: string) => Promise<BiddingItem>;
  createBiddingItem: (biddingItem: PostBiddingItem) => Promise<BiddingItem>;
  updateBiddingItem: (biddingItem: PatchBiddingItem) => Promise<BiddingItem>;
  deleteBiddingItem: (biddingItemId: string) => Promise<null>;
} = {
  getAll: ({ eventId }): Promise<BiddingItem[]> =>
    API.get(`${ApiEndpoints.BIDDING_ITEMS}`, { params: { eventId } }).then(
      (response) => response.data
    ),
  getDetails: (biddingItemId: string): Promise<BiddingItem> =>
    API.get(`${ApiEndpoints.BIDDING_ITEMS}/${biddingItemId}`).then((response) => response.data),
  createBiddingItem: (biddingItem: PostBiddingItem): Promise<BiddingItem> =>
    API.post(ApiEndpoints.BIDDING_ITEMS, biddingItem).then((response) => response.data),
  updateBiddingItem: (biddingItem: PatchBiddingItem) =>
    API.patch<BiddingItem>(`${ApiEndpoints.BIDDING_ITEMS}/${biddingItem.id}`, biddingItem).then(
      (response) => response.data
    ),
  deleteBiddingItem: (biddingItemId: string) =>
    API.delete(`${ApiEndpoints.BIDDING_ITEMS}/${biddingItemId}`),
};

export const BidApi: {
  getAll: (params: {
    eventId?: string;
    biddingItemId?: string;
    groupId?: string;
    createdByUserId?: string;
  }) => Promise<Bid[]>;
  getDetails: (bidId: string) => Promise<Bid>;
  createBid: (bid: PostBid) => Promise<Bid>;
  updateBid: (bid: PatchBid) => Promise<Bid>;
  deleteBid: (bidId: string) => Promise<null>;
} = {
  getAll: ({ biddingItemId, groupId, createdByUserId, eventId }): Promise<Bid[]> => {
    return API.get(`${ApiEndpoints.BIDS}`, {
      params: {
        eventId,
        createdByUserId,
        biddingItemId,
        groupId,
      },
    }).then((response) => response.data);
  },
  getDetails: (bidId: string): Promise<Bid> =>
    API.get(`${ApiEndpoints.BIDS}/${bidId}`).then((response) => response.data),
  createBid: (bid: PostBid): Promise<Bid> =>
    API.post(ApiEndpoints.BIDS, bid).then((response) => response.data),
  updateBid: (bid) =>
    API.patch(`${ApiEndpoints.BIDS}/${bid.id}`, bid).then((response) => response.data),
  deleteBid: (bidId) => API.delete(`${ApiEndpoints.BIDS}/${bidId}`),
};

export const EventMembershipApi: {
  get: (eventId: string, inviteId?: string) => Promise<EventMembership>;
} = {
  get: (eventId: string, inviteId?: string) =>
    API.get(
      `${ApiEndpoints.EVENTS}/${eventId}/membership${inviteId ? `?invite=${inviteId}` : ''}`
    ).then((response) => response.data),
};

export const EventMemberApi: {
  getAll: (eventId: string) => Promise<EventMember[]>;
  addMemberToEvent: (
    eventId: string,
    user: PostEventMember,
    inviteId?: string
  ) => Promise<EventMember>;
  updateMemberInEvent: (eventId: string, member: PatchEventMember) => Promise<EventMember>;
  deleteEventMember: (eventId: string, member: PostEventMember) => Promise<null>;
} = {
  getAll: (eventId: string): Promise<EventMember[]> =>
    API.get(`${ApiEndpoints.EVENTS}/${eventId}/members`).then((response) => response.data),
  addMemberToEvent: (
    eventId: string,
    user: PostEventMember,
    inviteId?: string
  ): Promise<EventMember> =>
    API.post(`${ApiEndpoints.EVENTS}/${eventId}/members`, { ...user, inviteId }).then(
      (response) => response.data
    ),
  updateMemberInEvent: (eventId, member) =>
    API.patch(`${ApiEndpoints.EVENTS}/${eventId}/members/${member.userId}`, member).then(
      (response) => response.data
    ),
  deleteEventMember: (eventId, member) =>
    API.delete(`${ApiEndpoints.EVENTS}/${eventId}/members/${member.userId}`),
};

export const GroupMemberApi: {
  getAll: (groupId: string) => Promise<GroupMember[]>;
  addMemberToGroup: (groupId: string, member: PostGroupMember) => Promise<GroupMember>;
  patchMemberToGroup: (groupId: string, member: PatchGroupMember) => Promise<GroupMember>;
  deleteGroupMember: (groupId: string, member: PostGroupMember) => Promise<null>;
} = {
  getAll: (groupId: string) =>
    API.get(`${ApiEndpoints.GROUPS}/${groupId}/members`).then((response) => response.data),
  addMemberToGroup: (groupId, member) =>
    API.post(`${ApiEndpoints.GROUPS}/${groupId}/members`, member).then((response) => response.data),
  patchMemberToGroup: (groupId, member) =>
    API.patch(`${ApiEndpoints.GROUPS}/${groupId}/members/${member.userId}`, member).then(
      (response) => response.data
    ),
  deleteGroupMember: (groupId, member) =>
    API.delete(`${ApiEndpoints.GROUPS}/${groupId}/members/${member.userId}`),
};

export const SessionsApi: {
  login: (user: { userName: string }) => Promise<{ token: string }>;
  oAuthCallback: ({
    code,
    state,
    provider,
  }: {
    code: string;
    state: string;
    provider: string;
  }) => Promise<{ token: string }>;
  logout: () => Promise<{ redirect: string }>;
  verify: () => Promise<null>;
  refresh: () => Promise<{ token: string }>;
} = {
  login: (user) =>
    API.post<{ token: string }>(`${ApiEndpoints.SESSIONS}/login`, user).then(
      (response) => response.data
    ),
  oAuthCallback: ({ code, state, provider }) =>
    API.get(
      `${ApiEndpoints.SESSIONS}/oauth2/callback?code=${code}&state=${state}&provider=${provider}`,
      { withCredentials: true }
    ).then((response) => response.data),
  logout: () => API.post(`${ApiEndpoints.SESSIONS}/logout`).then((response) => response.data),
  verify: () => API.get(`${ApiEndpoints.SESSIONS}/verify`).then((response) => response.data),
  refresh: () => API.get(`${ApiEndpoints.SESSIONS}/refresh`).then((response) => response.data),
};

export const UsersApi: {
  getAll: (filters: { eventId: string }) => Promise<User[]>;
  getSampleUsers: () => Promise<User[]>;
  getById: (userId: string) => Promise<User>;
  delete: (userId: string) => Promise<boolean>;
} = {
  getAll: (filters) =>
    API.get<User[]>(`${ApiEndpoints.USERS}`, { params: { ...filters } }).then(
      (response) => response.data
    ),
  getSampleUsers: () =>
    API.get<User[]>(`${ApiEndpoints.USERS}/sample-users`).then((response) => response.data),
  getById: (userId) =>
    API.get<User>(`${ApiEndpoints.USERS}/${userId}`).then((response) => response.data),
  delete: (userId) =>
    API.delete(`${ApiEndpoints.USERS}/${userId}`).then((response) => response.data),
};

export const ItemTypeApi: {
  getAll: (eventId: string) => Promise<ItemType[]>;
  getDetails: (eventId: string, itemTypeId: string) => Promise<ItemType>;
  createItemType: (eventId: string, itemType: PostItemType) => Promise<ItemType>;
  updateItemType: (eventId: string, itemType: ItemType) => Promise<ItemType>;
  deleteItemType: (eventId: string, itemTypeId: string) => Promise<null>;
} = {
  getAll: (eventId) =>
    API.get<ItemType[]>(`${ApiEndpoints.EVENTS}/${eventId}/item-types`).then(
      (response) => response.data
    ),
  getDetails: (eventId, itemTypeId) =>
    API.get<ItemType>(`${ApiEndpoints.EVENTS}/${eventId}/item-types/${itemTypeId}`).then(
      (response) => response.data
    ),
  createItemType: (eventId, itemType) =>
    API.post<ItemType>(`${ApiEndpoints.EVENTS}/${eventId}/item-types`, itemType).then(
      (response) => response.data
    ),
  updateItemType: (eventId, itemType) =>
    API.patch<ItemType>(
      `${ApiEndpoints.EVENTS}/${eventId}/item-types/${itemType.id}`,
      itemType
    ).then((response) => response.data),
  deleteItemType: (eventId: string, itemTypeId: string) =>
    API.delete(`${ApiEndpoints.EVENTS}/${eventId}/item-types/${itemTypeId}`),
};

export const ValueStreamApi: {
  getAll: (eventId: string) => Promise<ValueStream[]>;
  getDetails: (eventId: string, valueStreamId: string) => Promise<ValueStream>;
  createValueStream: (eventId: string, valueStream: PostValueStream) => Promise<ValueStream>;
  updateValueStream: (eventId: string, valueStream: ValueStream) => Promise<ValueStream>;
  deleteValueStream: (eventId: string, valueStreamId: string) => Promise<null>;
} = {
  getAll: (eventId) =>
    API.get<ValueStream[]>(`${ApiEndpoints.EVENTS}/${eventId}/value-streams`).then(
      (response) => response.data
    ),
  getDetails: (eventId, valueStreamId) =>
    API.get<ValueStream>(`${ApiEndpoints.EVENTS}/${eventId}/value-streams/${valueStreamId}`).then(
      (response) => response.data
    ),
  createValueStream: (eventId, valueStream) =>
    API.post<ValueStream>(`${ApiEndpoints.EVENTS}/${eventId}/value-streams`, valueStream).then(
      (response) => response.data
    ),
  updateValueStream: (eventId, valueStream) =>
    API.patch<ValueStream>(
      `${ApiEndpoints.EVENTS}/${eventId}/value-streams/${valueStream.id}`,
      valueStream
    ).then((response) => response.data),
  deleteValueStream: (eventId: string, valueStreamId: string) =>
    API.delete(`${ApiEndpoints.EVENTS}/${eventId}/value-streams/${valueStreamId}`),
};

export const StrategicThemeApi: {
  getAll: (eventId: string) => Promise<StrategicTheme[]>;
  getDetails: (eventId: string, strategicThemeId: string) => Promise<StrategicTheme>;
  createStrategicTheme: (
    eventId: string,
    strategicTheme: PostStrategicTheme
  ) => Promise<StrategicTheme>;
  updateStrategicTheme: (
    eventId: string,
    strategicTheme: StrategicTheme
  ) => Promise<StrategicTheme>;
  deleteStrategicTheme: (eventId: string, strategicThemeId: string) => Promise<null>;
} = {
  getAll: (eventId) =>
    API.get<StrategicTheme[]>(`${ApiEndpoints.EVENTS}/${eventId}/strategic-themes`).then(
      (response) => response.data
    ),
  getDetails: (eventId, strategicThemeId) =>
    API.get<StrategicTheme>(
      `${ApiEndpoints.EVENTS}/${eventId}/strategic-themes/${strategicThemeId}`
    ).then((response) => response.data),
  createStrategicTheme: (eventId, strategicTheme) =>
    API.post<StrategicTheme>(
      `${ApiEndpoints.EVENTS}/${eventId}/strategic-themes`,
      strategicTheme
    ).then((response) => response.data),
  updateStrategicTheme: (eventId, strategicTheme) =>
    API.patch<StrategicTheme>(
      `${ApiEndpoints.EVENTS}/${eventId}/strategic-themes/${strategicTheme.id}`,
      strategicTheme
    ).then((response) => response.data),
  deleteStrategicTheme: (eventId: string, strategicThemeId: string) =>
    API.delete(`${ApiEndpoints.EVENTS}/${eventId}/strategic-themes/${strategicThemeId}`),
};

export const EventJoinLinkApi: {
  getAll: (params: { eventId: string }) => Promise<EventJoinLink[]>;
  getDetails: (eventJoinLinkId: string) => Promise<EventJoinLink>;
  createEventJoinLink: (eventJoinLink: PostEventJoinLink) => Promise<EventJoinLink>;
  updateEventJoinLink: (eventJoinLink: PatchEventJoinLink) => Promise<EventJoinLink>;
  deleteEventJoinLink: (eventJoinLinkId: string) => Promise<null>;
  sendInvitationEmail: (eventJoinLinkId: string) => Promise<EventJoinLink>;
  getInviteLink: (eventJoinLinkId: string) => Promise<InviteLink>;
} = {
  getAll: ({ eventId }): Promise<EventJoinLink[]> =>
    API.get(`${ApiEndpoints.EVENT_JOIN_LINKS}`, { params: { eventId } }).then(
      (response) => response.data
    ),
  getDetails: (eventJoinLinkId: string): Promise<EventJoinLink> =>
    API.get(`${ApiEndpoints.EVENT_JOIN_LINKS}/${eventJoinLinkId}`).then(
      (response) => response.data
    ),
  createEventJoinLink: (eventJoinLink: PostEventJoinLink): Promise<EventJoinLink> =>
    API.post(ApiEndpoints.EVENT_JOIN_LINKS, eventJoinLink).then((response) => response.data),
  updateEventJoinLink: (eventJoinLink: PatchEventJoinLink) =>
    API.patch<EventJoinLink>(
      `${ApiEndpoints.EVENT_JOIN_LINKS}/${eventJoinLink.id}`,
      eventJoinLink
    ).then((response) => response.data),
  deleteEventJoinLink: (eventJoinLinkId: string) =>
    API.delete(`${ApiEndpoints.EVENT_JOIN_LINKS}/${eventJoinLinkId}`),
  sendInvitationEmail: (eventJoinLinkId: string) =>
    API.post(`${ApiEndpoints.EVENT_JOIN_LINKS}/${eventJoinLinkId}/send`),
  getInviteLink: (eventJoinLinkId: string): Promise<InviteLink> =>
    API.get(`${ApiEndpoints.EVENT_JOIN_LINKS}/${eventJoinLinkId}/invite-link`).then(
      (response) => response.data
    ),
};
