import { useMemo } from 'react';
import { useMyRoleByEventIdQuery } from '../api/eventMember.queries';
import { EventMemberRole } from '../models/eventMember.interface';

export type UseIsFacilitatorType = {
  isFacilitator: boolean;
  isFetched: boolean;
};

// handy hook to check if the user is a facilitator
// this will return "false" while the data is not available yet (isFetched == false)
export function useIsFacilitator(eventId: string | undefined): UseIsFacilitatorType {
  const { data } = useMyRoleByEventIdQuery(eventId);

  const value = useMemo(() => {
    return {
      isFacilitator: data ? data.role === EventMemberRole.Facilitator : false,
      isFetched: data ? true : false,
    };
  }, [data]);

  return value;
}
