import {
  QueryObserver,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { BidApi } from './api';
import { Bid, PatchBid, PostBid } from '../models/bid.interface';
import { useAuthContext } from '../hooks/useAuth';
import { QueryKeysMap } from '../models/keys.type';
import { useMemo } from 'react';

type Filters = {
  eventId?: string;
  biddingItemId?: string;
  groupId?: string;
  createdByUserId?: string;
};

type GroupBidAmountEntry = {
  groupId: string;
  sum: number;
};

export type GroupBidAmountResult = {
  allFetched: boolean;
  groupResult: GroupBidAmountEntry[];
};

export const bidsKeys: QueryKeysMap<'bids', Filters> = {
  all: ['bids'] as const,
  list: () => [...bidsKeys.all, 'list'] as const,
  filter: (filters: Filters) => [...bidsKeys.list(), { ...filters }] as const,
  detail: () => [...bidsKeys.all, 'detail'] as const,
  byId: (bidId: string) => [...bidsKeys.detail(), bidId] as const,
};

const bidsWithFilterQuery = (filters: Filters) => ({
  queryKey: bidsKeys.filter({ ...filters }),
  queryFn: () =>
    BidApi.getAll({
      biddingItemId: filters.biddingItemId,
      eventId: filters.eventId,
      createdByUserId: filters.createdByUserId,
      groupId: filters.groupId,
    }),
});

export function useMyBidsInGroupQuery(groupId: string) {
  const { user } = useAuthContext();

  const userId = user?.id;
  const filter = {
    groupId,
  };

  return useQuery({
    ...bidsWithFilterQuery(filter),
    enabled: !!userId,
    select: (data) => data.filter((bid: Bid) => bid.createdByUserId === userId),
  });
}

export function useOthersBidsInGroupQueryForBiddingItem(groupId: string, biddingItemId: string) {
  const { user } = useAuthContext();

  const userId = user?.id;
  const filter = {
    groupId,
  };

  return useQuery({
    ...bidsWithFilterQuery(filter),
    enabled: !!userId,
    select: (data) =>
      data.filter(
        (bid: Bid) => bid.createdByUserId !== userId && bid.biddingItemId === biddingItemId
      ),
  });
}

export function useBidsForBiddingItemInGroup(groupId: string, biddingItemId: string) {
  const filter = {
    groupId,
  };

  return useQuery({
    ...bidsWithFilterQuery(filter),
    select: (data) => data.filter((bid: Bid) => bid.biddingItemId === biddingItemId),
  });
}

export function useOthersBidsInGroupQuery(groupId: string) {
  const { user } = useAuthContext();

  const userId = user?.id;
  const filter = {
    groupId,
  };

  return useQuery({
    ...bidsWithFilterQuery(filter),
    enabled: !!userId,
    select: (data) => data.filter((bid: Bid) => bid.createdByUserId !== userId),
  });
}

export function useMyBidForBiddingItemQuery(biddingItemId: string) {
  const { user } = useAuthContext();

  const userId = user?.id;
  const filter = {
    biddingItemId: biddingItemId,
    createdByUserId: userId,
  };

  return useQuery({
    ...bidsWithFilterQuery(filter),
    enabled: !!userId,
  });
}

export function useMyBidForBiddingItemObserver(biddingItemId: string) {
  const { user } = useAuthContext();

  const userId = user?.id;
  const filter = {
    biddingItemId: biddingItemId,
    createdByUserId: userId,
  };

  return new QueryObserver(useQueryClient(), {
    ...bidsWithFilterQuery(filter),
    enabled: !!userId,
  });
}

export function useBidsWithFilterQuery(filters: Omit<Filters, 'eventId'>) {
  return useQuery({
    ...bidsWithFilterQuery({ createdByUserId: filters.createdByUserId, groupId: filters.groupId }),
    select: (data) =>
      filters.biddingItemId
        ? data.filter((bid: Bid) => bid.biddingItemId === filters.biddingItemId)
        : data,
  });
}

export function useBidsForGroup(groupId: string) {
  return useQuery({ ...bidsWithFilterQuery({ groupId }) });
}

export function useBidsForMember(groupId: string, memberId: string) {
  return useQuery({
    ...bidsWithFilterQuery({ groupId }),
    select: (data) =>
      memberId ? data.filter((bid: Bid) => bid.createdByUserId === memberId) : data,
  });
}

export function useBidsForGroups(groupIds?: string[]) {
  const queriesArr = groupIds?.map((groupId) => ({ ...bidsWithFilterQuery({ groupId }) }));
  return useQueries({
    queries: queriesArr || [],
  });
}

export function useTotalBidsByGroup(groupIds?: string[]) {
  const groupBidsQueries = useBidsForGroups(groupIds);

  const groupBidsResult: GroupBidAmountResult = useMemo(() => {
    let groupResult: GroupBidAmountEntry[] = [];
    let allFetched = true;
    if (groupIds?.length) {
      for (let i = 0; i < groupIds.length; i++) {
        const groupId = groupIds[i];
        const query = groupBidsQueries?.[i];

        groupResult = [
          ...groupResult,
          {
            groupId: groupId,
            sum: query.data ? query.data.reduce((t, { bidAmount }) => t + bidAmount, 0) : 0,
          },
        ];
        allFetched = allFetched && query.isFetched;
      }
    } else {
      allFetched = false;
    }

    return {
      allFetched,
      groupResult,
    };
  }, [groupIds, groupBidsQueries]);
  return groupBidsResult;
}

/**
 * EnrichedBidType enriches the Bid interface with Bidding Progress along with My Bid amount and the Id of My Bid.
 **/
export interface EnrichedBidType extends Bid {
  myBid: number;
  myBidId?: string;
  bidProgress?: number;
  totalBids: number;
}

/**
 * Takes all the bids for a group sums up bidAmount per item, determines if user has a bid on item and assign the bid value and id of the bid
 **/
export function useGroupedBidsForGroup(groupId: string) {
  const { user } = useAuthContext();
  const userId = user?.id;

  return useQuery({
    ...bidsWithFilterQuery({ groupId }),
    select: (bids) => {
      if (!bids) {
        return [];
      }
      return Object.values(
        bids.reduce(
          (
            itemLookup: { [key: string]: EnrichedBidType },
            { id, biddingItemId, bidAmount, createdByUserId }
          ) => {
            itemLookup[biddingItemId] = itemLookup[biddingItemId] || {
              biddingItemId,
              bidAmount: 0,
              myBid: 0,
              myBidId: undefined,
              totalBids: 0,
            };
            itemLookup[biddingItemId].bidAmount += bidAmount;
            itemLookup[biddingItemId].totalBids += 1;
            itemLookup[biddingItemId].myBid =
              createdByUserId === userId
                ? (itemLookup[biddingItemId].myBid += bidAmount)
                : itemLookup[biddingItemId].myBid;
            itemLookup[biddingItemId].myBidId =
              createdByUserId === userId
                ? (itemLookup[biddingItemId].myBidId = id)
                : itemLookup[biddingItemId].myBidId;
            return itemLookup;
          },
          {}
        )
      );
    },
  });
}

/**
 * AnalysisBidType is the bidamount for an item summed into a single value along with the total # of groups that bid and the bidItemId.
 **/
export interface AnalysisBidType {
  biddingItemId: string;
  bidAmount: number;
  totalBids: number;
  groupIds: Set<string>;
}

/**
 * Takes all the bids for an event sums up bidAmount per item, and calculates the # of groups that have bid on a item.
 **/
export function useGroupedBidsForEvent(eventId: string) {
  return useQuery({
    ...bidsWithFilterQuery({ eventId }),
    select: (bids) => {
      if (!bids) {
        return [];
      }
      return Object.values(
        bids.reduce(
          (
            itemLookup: { [key: string]: AnalysisBidType },
            { biddingItemId, bidAmount, groupId }
          ) => {
            itemLookup[biddingItemId] = itemLookup[biddingItemId] || {
              biddingItemId,
              bidAmount: 0,
              groupIds: new Set(),
              totalBids: 0,
            };
            itemLookup[biddingItemId].bidAmount += bidAmount;
            itemLookup[biddingItemId].totalBids += 1;
            itemLookup[biddingItemId].groupIds.add(groupId);
            return itemLookup;
          },
          {}
        )
      );
    },
  });
}

export function useBidsForEvent(eventId: string) {
  return useQuery({ ...bidsWithFilterQuery({ eventId }) });
}

export function useBidsByIdQuery(id: string) {
  return useQuery({
    queryKey: bidsKeys.byId(id),
    queryFn: () => BidApi.getDetails(id),
  });
}

export function useCreateBidMutation() {
  return useMutation({
    mutationFn: (bid: PostBid) => BidApi.createBid(bid),
  });
}

export function useUpdateBidMutation() {
  return useMutation({
    mutationFn: (bid: PatchBid) => BidApi.updateBid(bid),
  });
}

export function useDeleteBidMutation() {
  return useMutation({
    mutationFn: (bidId: string) => BidApi.deleteBid(bidId),
  });
}
