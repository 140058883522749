import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  emptyEvents: {
    background: theme.colors.gray[0],
    padding: `${theme.spacing.xl} 4rem`,
    margin: `6rem 1rem`,
    textAlign: 'center',
    border: `3px solid ${theme.colors.teal[1]}`,
    button: {
      margin: `${theme.spacing.sm} 0`,
      height: 75,
      fontSize: theme.fontSizes.xl,
      color: `${theme.colors.gray[0]} !important`,
      '&:hover': {
        background: `${theme.colors.sky[5]} !important`,
      },
    },
  },
}));
