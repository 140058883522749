import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SessionsApi } from './api';
import { useAuthContext } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const sessionsKeys = {
  user: ['me'] as const,
  jwt: () => [...sessionsKeys.user, 'jwt'] as const,
  token: ['token'] as const,
};

export function useCodeCallback({
  code,
  state,
  provider,
}: {
  code: string;
  state: string;
  provider: string;
}) {
  const navigate = useNavigate();

  const { setToken, token } = useAuthContext();

  const query = useQuery({
    queryKey: [sessionsKeys.token, code, state, provider],
    queryFn: () => SessionsApi.oAuthCallback({ code: code, state: state, provider: provider }),
    throwOnError: false,
  });

  // see: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
  // check for token change to make sure it runs only once.
  useEffect(() => {
    if (query.data && query.data.token !== token) {
      setToken(query.data.token);
      let redirectUrl = window.localStorage.getItem('redirectPath') || '/events';
      if (window.localStorage.getItem('redirectSearch')) {
        redirectUrl = `${redirectUrl}${window.localStorage.getItem('redirectSearch')}`;
      }
      navigate(redirectUrl);
    }
  }, [query.data, setToken, navigate, token]);

  return query;
}

export function useLogin() {
  const { setToken } = useAuthContext();

  return useMutation({
    mutationFn: (user: { userName: string }) => SessionsApi.login(user),
    onSuccess: (data: { token: string }) => {
      setToken(data.token);
    },
    onError: (error) => {
      console.error(error);
    },
  });
}

export function useLogout() {
  const { removeToken } = useAuthContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => SessionsApi.logout(),
    onSuccess: () => {
      // Logging in will clear from the cache all user data
      removeToken();
      queryClient.removeQueries();
    },
  });
}

export function useVerify(jwt: string) {
  return useQuery({
    queryKey: sessionsKeys.jwt(),
    queryFn: () => SessionsApi.verify(),
    enabled: !!jwt,
    throwOnError: false, // overwrite global default, errors are handled explicitly
    retry: false, // disable default retry
  });
}
