import { Text } from '@mantine/core';
import { useStyles } from '../layoutStyles';

interface TextCrumbProps {
  label: string;
}

export function TextCrumb({ label }: TextCrumbProps) {
  const { classes } = useStyles();
  return <Text className={classes.inactiveBreadcrumb}>{label}</Text>;
}
