import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Group,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { Link, useLocation } from 'react-router-dom';
import { useLogin } from '../../../api/sessions.queries';
import { FormAlert } from '../form-alert/FormAlert';
import { envFromHostname, EnvNames } from '../../../utils/env-from-hostname';

export default function LoginForm() {
  const theme = useMantineTheme();
  const form = useForm({
    initialValues: {
      userName: '',
      agreedTC: false,
    },
    validate: {
      userName: (value) =>
        value.length < 2 ? 'Name must be present and at least 2 characters' : null,
      agreedTC: (value) => (!value ? 'You must agree to Terms and Use ' : null),
    },
  });
  const loginMutation = useLogin();

  // store the old path in local storage so we can redirect correctly after auth
  const { state } = useLocation();
  useEffect(() => {
    if (state?.path) {
      window.localStorage.setItem('redirectPath', state.path);
    }
    if (state?.search) {
      window.localStorage.setItem('redirectSearch', state.search);
    }
  }, [state?.path, state?.search]);

  const handleSubmit = (loginInfo: { userName: string }) => {
    loginMutation.mutateAsync(loginInfo);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} onReset={() => loginMutation.reset()}>
      <Card mx="xl" px="xs">
        <Stack>
          <Button
            size="md"
            type="reset"
            variant="filled"
            component={'a'}
            href="/oauth2/auth0/login"
          >
            Log in with SAFe Studio or Single Sign-On
          </Button>
          <Divider size="xs" mx={110} />

          <TextInput
            label={
              <Text size="md" fw={700}>
                No SAFe Studio Account?{' '}
                <Text span size="sm" fw={400}>
                  Enter your name to log in as a guest.
                </Text>
              </Text>
            }
            placeholder="Enter Name"
            {...form.getInputProps('userName')}
          />

          {form.values.userName && (
            <Flex mt={-10}>
              <Checkbox
                mr="xs"
                size="xs"
                {...form.getInputProps('agreedTC', {
                  type: 'checkbox',
                })}
              />
              <Text size="sm" mt={-3}>
                I agree to the{' '}
                <Link
                  to="/guest-terms-use"
                  target="_blank"
                  style={{ color: theme.colors.teal[1], textDecoration: 'none' }}
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Anchor
                  style={{ color: theme.colors.teal[1], textDecoration: 'none' }}
                  href="https://scaledagile.com/privacy-policy/"
                  target="_blank"
                >
                  Privacy Policy
                </Anchor>
              </Text>
            </Flex>
          )}
          <Button variant="outline" disabled={!form.isValid()} size="md" type="submit">
            Continue as Guest
          </Button>
          {envFromHostname(window.location.hostname) != EnvNames.PRODUCTION ? (
            <>
              <Text ta="center" size="md">
                Scaled Agile Employee?{' '}
                <Anchor
                  sx={(theme) => ({ color: theme.colors.teal[1] })}
                  href="/oauth2/jumpcloud/login"
                >
                  Log in here
                </Anchor>
              </Text>
            </>
          ) : null}
          <FormAlert
            title="Login failed"
            error={loginMutation.error}
            message5xx="Server can not be contacted, please try again."
          ></FormAlert>
        </Stack>
      </Card>
    </form>
  );
}
