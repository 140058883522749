import { createContext, ReactNode, useContext, useState } from 'react';

export type SharedStateContextType = {
  isEventLoading: boolean;
  setEventLoading: (f: boolean) => void;
};

const SharedStateContext = createContext<SharedStateContextType | undefined>(undefined);

export function SharedStateProvider({ children }: { children: ReactNode }) {
  const { isEventLoading, setEventLoading } = useProvideSharedState();

  // object to expose to children
  return (
    <SharedStateContext.Provider
      value={{
        isEventLoading,
        setEventLoading,
      }}
    >
      {children}
    </SharedStateContext.Provider>
  );
}

function useProvideSharedState() {
  const [isEventLoading, setEventLoading] = useState<boolean>(false);

  return { isEventLoading, setEventLoading };
}

// Hook that creates shared state object and handles it
export function useSharedStateContext() {
  const sharedStateContext = useContext(SharedStateContext);

  if (sharedStateContext) {
    return sharedStateContext;
  }

  throw new Error(`useSharedStateContext must be used within a SharedStateProvider`);
}
